import {dataCy} from "@buildwithflux/constants";
import AppBar from "@material-ui/core/AppBar";
import {Theme} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import {makeStyles} from "@material-ui/styles";
import React, {useMemo} from "react";

import {useFluxServices} from "../../injection/hooks";
import {lazyRetry} from "../common/components/lazyLoader";

const NavBarDocumentControls = lazyRetry(() => import("./controls/document/NavBarDocumentControls"));
const NavBarProfileControls = lazyRetry(() => import("./controls/profile/NavBarProfileControls"));
const NavBarSearchControls = lazyRetry(() => import("./controls/search/NavBarSearchControls"));
const NavBarDefaultControls = lazyRetry(() => import("./controls/default/NavBarDefaultControls"));
const NavBarBlankControls = lazyRetry(() => import("./controls/blank/NavBarBlankControls"));

// eslint-disable-next-line import/no-unused-modules -- is used, because otherwise "Default export of the module <storybook story> has or is using private name <props interface>"
export interface INavBarProps {
    position?: "fixed" | "relative";
    mode?: "document" | "profile" | "search" | "search_blank" | "blank";
}

const useStyles = makeStyles((theme: Theme) => ({
    offset: theme.mixins.toolbar,
}));

function NavBar(props: INavBarProps) {
    const {useDocumentUiStore} = useFluxServices();
    const classes = useStyles();
    const embedMode = useDocumentUiStore((state) => state.embedMode);

    const toolbarStyle = useMemo(() => {
        return {padding: embedMode ? "0 16px" : "0 8px"};
    }, [embedMode]);

    return (
        <nav data-cy={dataCy.navBar}>
            <AppBar color="inherit" position={props.position || "relative"}>
                <Toolbar
                    variant="dense"
                    // Disabling `forbid-component-props` here because this is a dynamic style
                    // eslint-disable-next-line react/forbid-component-props
                    style={toolbarStyle}
                >
                    {props.mode === "document" && <NavBarDocumentControls />}
                    {props.mode === "profile" && <NavBarProfileControls />}
                    {props.mode === "search" && <NavBarSearchControls />}
                    {props.mode === "search_blank" && <NavBarSearchControls hideSearch={true} />}
                    {props.mode === "blank" && <NavBarBlankControls />}
                    {!props.mode && <NavBarDefaultControls />}
                </Toolbar>
            </AppBar>

            {props.position === "fixed" && <div className={classes.offset} />}
        </nav>
    );
}

export default React.memo(NavBar);
