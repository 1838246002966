/**
 * Computes the time elapsed since a given timestamp.
 *
 * We wrote our own because moment fromNow does not support abbreviations.
 * @see https://github.com/moment/moment/issues/2781#issuecomment-416499369
 *
 * @param ts - The timestamp in milliseconds.
 * @param ago - Whether to append "ago" to the result. Defaults to true.
 * @returns A string representing the elapsed time in the format "Xunit ago".
 */
export function timeSince(ts: number, ago = true) {
    const seconds = (Date.now() - ts) / 1000;
    let intervalType;

    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
        intervalType = "y";
    } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            intervalType = "mo";
        } else {
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
                intervalType = "d";
            } else {
                interval = Math.floor(seconds / 3600);
                if (interval >= 1) {
                    intervalType = "h";
                } else {
                    interval = Math.floor(seconds / 60);
                    if (interval >= 1) {
                        intervalType = "m";
                    } else {
                        return "now";
                    }
                }
            }
        }
    }

    return interval + intervalType + (ago ? " ago" : "");
}

export function getTimeElapsedInSeconds(startTime: number | undefined) {
    return (Date.now() - (startTime ?? 0)) / 1000;
}
