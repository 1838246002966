// list of issues
//		pcbplotparams is not supported. what is this used for?
import {
    IKicadFpText,
    IKicadModuleSubtypes,
    IKicadPcbHeader,
    IKicadPoly,
} from "@buildwithflux/core/src/data_portability/importers/kicad/KicadTypes";
import {isArray, isBoolean, isObject, isString, keys, repeat} from "lodash";

// check if AR contains STR
const arrayContains = (ar: Array<string>, str: string) => {
    return ar.indexOf(str) > -1;
};

// check if STR contains CH (character or substring)
const strContains = (str: string, ch: string) => {
    return str.indexOf(ch) > -1;
};

// indent string by 2 spaces on each level
const indentString = (level: number, str: string) => {
    return `${repeat(" ", level * 2)}${str}`;
};

// these arrays should be displayed on separate lines
const arrayIndivItems: string[] = [
    "add_net",
    "autoplace_cost90",
    "autoplace_cost180",
    "solder_mask_margin",
    "solder_paste_margin",
    "solder_paste_ratio",
    "user_trace_width",
    // 'user_via'
    "pts",
];

// these the properties of these objects should be displayed on a single line, values only
// keys are in the format "parentkey.property"
const objectsOneLine = [
    "module.at",
    "fp_text.at",
    "gr_text.at",
    "pad.at",
    "font.size",
    "pad.size",
    "pad.net",
    "pad.drill",
    "fp_line.start",
    "fp_line.end",
    "fp_circle.center",
    "fp_circle.end",
    "fp_arc.start",
    "fp_arc.end",
    "gr_line.start",
    "gr_line.end",
    "gr_arc.start",
    "gr_arc.end",
    "gr_circle.center",
    "gr_circle.end",
    "segment.start",
    "segment.end",
    "segment.net",
    "via.at",
    "zone.hatch",
    "at.xyz",
    "scale.xyz",
    "rotate.xyz",
    "offset.xyz",
    "pts.xy",
];

// for these booleans,
// output a "yes" or "no"
// the default is to hide boolean values and only display the key if true
const yesNoBoolean = ["zone_45_only", "uvias_allowed", "blind_buried_vias_allowed"];

// for these booleans, instead of outputting "(boolkey true)",
//		just output "boolkey" if true and nothing if false
const booleanNakedProperty = ["hide", "blind", "micro", "locked"];

const booleanNakedYesNo = ["filled"];

// these keys need to be renamed when converting from JSON back to Kicad
const renameKey: {[key: string]: string} = {
    module_attribute: "attr",
};

interface IConvertOptions {
    kicadKey?: string; // the key name used in the kicad file
    topLevelProperties?: string[]; // list of properties to output on the same line as the key
    // keyOnItsOwnLine:
    //		TRUE = display the key on its own line at the top,
    //			    then display the children on subsequent lines without the top key
    //		FALSE = display the key with the children information on the same line
    keyOnItsOwnLine?: boolean;
    displayFullObj?: boolean;
}

// these properties output certain properties on the same level as the keys
// in one case, the name of the key is different from the S-expression key (WTF?)
// properties:
// 		kicadKey:
//		topLevelProperties:
//		keyOnItsOwnLine:
const specialKeys: {[key: string]: IConvertOptions} = {
    host: {topLevelProperties: ["application", "version"]},
    layers: {
        kicadKey: "",
        topLevelProperties: ["index", "name", "layer_type", "hide"],
        keyOnItsOwnLine: true,
    },
    primitives: {
        topLevelProperties: [],
        keyOnItsOwnLine: true,
    },
    net_class: {topLevelProperties: ["name", "description"]},
    model: {topLevelProperties: ["filename"]},
    module: {topLevelProperties: ["name"]},
    fp_text: {topLevelProperties: ["type", "text"]},
    fp_line: {topLevelProperties: []},
    fp_poly: {topLevelProperties: []},
    fp_circle: {topLevelProperties: []},
    fp_arc: {topLevelProperties: []},
    gr_text: {topLevelProperties: ["text"]},
    gr_line: {topLevelProperties: []},
    gr_arc: {topLevelProperties: []},
    zone: {topLevelProperties: []},
    segment: {topLevelProperties: []},
    via: {topLevelProperties: []},
    gr_poly: {topLevelProperties: []},
    gr_circle: {topLevelProperties: []},
    dimension: {topLevelProperties: ["dimension"]},
    offset: {topLevelProperties: ["x", "y"]},
    pad: {topLevelProperties: ["pad_id", "pad_type", "pad_shape", "locked"]},
    // these properties are nested inside other properties, and require somewhat special processing
    connect_pads: {topLevelProperties: ["connection"]},
    fill: {topLevelProperties: ["filled"]},
    polygon: {topLevelProperties: []},
    filled_polygon: {topLevelProperties: []},
    feature1: {topLevelProperties: []},
    feature2: {topLevelProperties: []},
    crossbar: {topLevelProperties: []},
    arrow1a: {topLevelProperties: []},
    arrow1b: {topLevelProperties: []},
    arrow2a: {topLevelProperties: []},
    arrow2b: {topLevelProperties: []},
};

// we make this change so that width/height will be output in the correct order
const fixHeightWidth = (objArg: any) => {
    const {size} = objArg;
    if (size != null && size.width != null && size.height != null) {
        objArg.size = {
            a: size.width,
            b: size.height,
        };
    }
    return objArg;
};

const transforms: {[key: string]: Function} = {
    font: (objArg: any) => {
        return fixHeightWidth(objArg);
    },
    pad: (objArg: any) => {
        return fixHeightWidth(objArg);
    },
    locked: (ar: boolean[]) => {
        // WTF: for some reason this is a BOOLEAN array. Are we going to support true AND false at the same time???
        if (isArray(ar)) {
            return ar[0];
        }
        return ar;
    },
    primitives: (ar: any) => {
        // for some idiotic reason, "gr_poly" and "gr_circle" are missing from the object
        // the type has to be inferred from its properties
        // for (let x = 0, len = ar.length; x < len; x++) {
        //   const item = ar[x];
        let newAr = [];
        if (ar && ar.length) {
            for (const item of ar) {
                if (item.pts) {
                    // this is "gr_poly"
                    const newobj = {
                        gr_poly: item,
                    };
                    newAr.push(newobj);
                } else if (item.center) {
                    // this is "gr_circle"
                    const newobj = {
                        gr_circle: item,
                    };
                    newAr.push(newobj);
                }
            }
        }
        // if no substitutions were made, return the original array
        if (!newAr.length) {
            newAr = ar;
        }
        return newAr;
    },
    fp_text: (ar: IKicadFpText[]) => {
        // for each item in ar, in the "at" object, the "unlocked" property has to be last
        if (ar && ar.length) {
            for (const item of ar) {
                if (item.at && isBoolean(item.at.unlocked)) {
                    const {at} = item;
                    const {unlocked} = at;
                    delete at.unlocked;
                    at.unlocked = unlocked;
                }
            }
        }
        return ar;
    },
};

const outputValue = (inputkey: string, val: string | boolean) => {
    if (isString(val)) {
        if (!val) {
            return `"${val}"`;
        }
        // loop through each character and see if the string contains it
        // if so, wrap it in quotes
        const ar = [" ", ")", "("];
        for (const searchChar of ar) {
            if (strContains(val, searchChar)) {
                return `"${val}"`;
            }
        }
    } else if (isBoolean(val)) {
        if (arrayContains(yesNoBoolean, inputkey)) {
            val = val ? "yes" : "no";
        } else if (arrayContains(booleanNakedYesNo, inputkey)) {
            val = val ? "yes" : "";
        } else {
            val = val ? inputkey : "";
        }
    }
    return val;
};

// recursive function
//	outputAr: array to return, appends text to this
//  parentKey: what is the parent's key -- sometimes we need to know this
//  inputObj: object to serialize
//  level: level of recursion, used to prepend spaces to text
//	options:
//  	displayFullObj: set true to display the whole object rather than a single line for each property
const mainSerializer = (
    outputAr: string[],
    parentKey: string,
    inputObj: IKicadModuleSubtypes,
    level: number,
    options?: IConvertOptions,
): void => {
    // the properties in "listOfProperties" are output at the same level as the key
    // the remaining properties are output like standard children
    const outputTopLevelProperties = (
        topkey: string,
        specialObj: IKicadModuleSubtypes | IKicadModuleSubtypes[],
        options: IConvertOptions,
    ) => {
        let {topLevelProperties, keyOnItsOwnLine} = options;
        topLevelProperties = topLevelProperties || [];
        if (!isArray(specialObj)) {
            specialObj = [specialObj];
        }
        // check to see if "pts" structure exists by checking the first item
        //    (assume all items are the same)
        // if it does, process it specially -- it's weird
        const specialItem = specialObj[0]!;
        if ("pts" in specialItem) {
            for (let x = 0, len = specialObj.length; x < len; x++) {
                const item: IKicadPoly = specialObj[x] as IKicadPoly;
                const ptsAr = item?.pts;

                if (!ptsAr) {
                    continue;
                }

                outputAr.push(indentString(level, `(${topkey}`));
                outputAr.push(indentString(level + 1, "(pts"));
                for (let j = 0, len1 = ptsAr.length; j < len1; j++) {
                    const ptsItem = ptsAr[j]!;
                    outputAr.push(indentString(level + 2, `(xy ${ptsItem.x} ${ptsItem.y})`));
                }
                outputAr.push(indentString(level + 1, ")"));
                delete item.pts;
                mainSerializer(outputAr, `${topkey}_child`, item, level + 1);
                outputAr.push(indentString(level, ")"));
            }
        } else if (keyOnItsOwnLine) {
            outputAr.push(indentString(level - 1, `(${topkey}`));

            for (let k = 0, len2 = specialObj.length; k < len2; k++) {
                const tempobj: {[key: string]: {}} = specialObj[k] as {[key: string]: {}};
                const subAr: string[] = []; // create top line here
                subAr.push(indentString(level, "("));

                for (const topkey of topLevelProperties) {
                    subAr.push(outputValue(topkey, tempobj[topkey] as string | boolean));
                    delete tempobj[topkey];
                }
                if (!keys(tempobj).length) {
                    // nothing more to output, end the line right here
                    subAr.push(")");
                    outputAr.push(subAr.join(" "));
                } else {
                    mainSerializer(outputAr, `${topkey}_child`, tempobj, level);
                }
            }
            outputAr.push(indentString(level - 1, ")"));
        } else {
            for (let m = 0, len4 = specialObj.length; m < len4; m++) {
                const tempobj: {[key: string]: {}} = specialObj[m] as {[key: string]: {}};
                const subAr: string[] = []; // create top line here

                // build the first line
                subAr.push(indentString(level - 1, `(${topkey}`));

                for (const topkey of topLevelProperties) {
                    subAr.push(outputValue(topkey, tempobj[topkey] as string | boolean));
                    delete tempobj[topkey];
                }
                outputAr.push(subAr.join(" "));
                mainSerializer(outputAr, `${topkey}_child`, tempobj, level);
                outputAr.push(indentString(level - 1, ")"));
            }
        }
    };
    if (keys(inputObj).length === 0) {
        return;
    }
    // empty object, return
    const searchKey = parentKey.replace("_child", "");
    const transformFn = transforms[searchKey];
    if (transformFn) {
        inputObj = transformFn(inputObj);
    }
    const specialOptions = specialKeys[parentKey];
    if (specialOptions) {
        outputTopLevelProperties(parentKey, inputObj, specialOptions);
        return;
    }
    if (isArray(inputObj)) {
        const options: IConvertOptions = {
            displayFullObj: true,
        };
        for (let x = 0, len = inputObj.length; x < len; x++) {
            const tempobj = inputObj[x];
            mainSerializer(outputAr, parentKey, tempobj, level - 1, options);
        }
        return;
    }

    const inputObjKeys = keys(inputObj) as (keyof IKicadModuleSubtypes)[];

    // display the full object (don't iterate over the properties)
    // happens for arrays within a key
    if (options && options.displayFullObj) {
        const subAr: string[] = []; // create top line here
        subAr.push(indentString(level, `(${parentKey}`));

        for (const key of inputObjKeys) {
            const val = inputObj[key];
            subAr.push(outputValue(key, val));
        }
        outputAr.push(subAr.join(" ") + ")");
        return;
    }
    for (const key of inputObjKeys) {
        let val: any | {[key: string]: any} = inputObj[key];
        const transformFn = transforms[key];
        if (transformFn) {
            val = transformFn(val);
        }
        if (isArray(val)) {
            if (arrayContains(arrayIndivItems, key)) {
                // output key/val for each value in the array
                for (let j = 0, len1 = val.length; j < len1; j++) {
                    const arval = val[j];
                    const newobj: {[key: string]: any} = {};
                    newobj[key] = arval;
                    mainSerializer(outputAr, key, newobj, level);
                }
                // since an array is both an array and an object, this IF clause has to come first
            } else if (isArray(val[0])) {
                // if elements are arrays, then serialize each of them separately
                for (let k = 0, len2 = val.length; k < len2; k++) {
                    const arval = val[k];
                    const newobj: {[key: string]: any} = {};
                    newobj[key] = arval;
                    mainSerializer(outputAr, key, newobj, level);
                }
            } else if (isObject(val[0])) {
                mainSerializer(outputAr, key, val as IKicadModuleSubtypes, level + 1);
            } else {
                // primitive type, output directly

                // check if key needs to be renamed
                const tempkey = renameKey[key] || key;
                const valstr = val.join(" ");
                outputAr.push(indentString(level, `(${tempkey} ${valstr})`));
            }
        } else if (isObject(val)) {
            // look for special cases here
            const specialOptions = specialKeys[key];
            if (specialOptions) {
                outputTopLevelProperties(key, val, specialOptions);
                continue;
            }
            if (arrayContains(objectsOneLine, `${searchKey}.${key}`)) {
                const subAr: string[] = []; // create top line here
                const newval: {[key: string]: any} = val;
                subAr.push(indentString(level, `(${key}`));
                const tempkeys = keys(val);
                for (const subkey of tempkeys) {
                    // for (const subkey in val) {
                    const subvalue = newval[subkey];
                    if (isObject(subvalue)) {
                        mainSerializer(subAr, subkey, subvalue, 0);
                    } else {
                        subAr.push(outputValue(subkey, subvalue));
                    }
                }
                subAr.push(")");
                outputAr.push(subAr.join(" "));
            } else {
                outputAr.push(indentString(level, `(${key}`));
                mainSerializer(outputAr, key, val, level + 1);
                outputAr.push(indentString(level, `)`));
            }
        } else if (isBoolean(val) && arrayContains(booleanNakedProperty, key)) {
            if (val) {
                // if true, just output the key, otherwise do nothing
                outputAr.push(indentString(level, key));
            }
        } else {
            outputAr.push(indentString(level, `(${key} ${outputValue(key, val)})`));
        }
    }
};

// input: JSON object that describes Kicad objects
// output: string of .kicad_pcb file
const KicadSerializer = (kicadObj: IKicadPcbHeader): string => {
    const outputAr: string[] = [];
    mainSerializer(outputAr, "", kicadObj, 0);
    return outputAr.join("\n");
};

export default KicadSerializer;
