import {RightEditorDrawerTabState} from "@buildwithflux/constants";
import {EditorModes} from "@buildwithflux/core";
import {InternalTabsState} from "@buildwithflux/models";
import {create, StoreApi, UseBoundStore} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

import {LocalStorageKey} from "../../../resources/constants/localStorageKey";

// QUESTION: why use integer enum here? strings are more transparent
export enum LeftEditorDrawerTabState {
    componentLibrary = 0,
    objects = 1,
    rules = 2,
}

export enum ProjectFilter {
    allProjects = "All Projects",
    myProjects = "My Projects",
    myTemplates = "My Templates",
    starredProjects = "Starred Projects",
}

type PersistedDocumentUiState = {
    showPcbGrid: boolean;
    showSchematicGrid: boolean;

    editorMode: EditorModes;

    leftDrawerActiveTab: {
        [editorMode: string]: LeftEditorDrawerTabState;
    };
    rightDrawerActiveTab: RightEditorDrawerTabState;
    rightDrawerChangedSinceSessionStart: boolean;

    showBottomDrawerOpen: boolean;
    leftPaneWidth: number;
    leftPaneCollapsed: boolean;
    rightPaneWidth: number;
    rightPaneCollapsed: boolean;

    // Internal tabs
    internalTabs: {
        enabled: InternalTabsState;
        CAL: string;
    };
};

type PersistedDocumentUiApi = {
    setPcbShowGrid: (show: boolean) => void;
    setSchematicShowGrid: (show: boolean) => void;

    setEditorMode: (mode: EditorModes) => void;

    setLeftDrawerActiveTab: (tabState: LeftEditorDrawerTabState, editorMode: EditorModes) => void;
    setRightDrawerActiveTab: (tabState: RightEditorDrawerTabState) => void;

    setBottomDrawerOpen: (show: boolean) => void;
    setLeftPaneWidth: (width: number) => void;
    setLeftPaneCollapsed: (collapsed: boolean) => void;
    setRightPaneWidth: (width: number) => void;
    setRightPaneCollapsed: (collapsed: boolean) => void;

    // Internal tabs
    setEnabledInternalTabs: (stateOrUpdater: InternalTabsState | ((s: InternalTabsState) => InternalTabsState)) => void;
    setCAL: (script: string) => void;
};

export type PersistedDocumentUiStoreState = PersistedDocumentUiState & PersistedDocumentUiApi;
export type UsePersistedDocumentUiStore = UseBoundStore<StoreApi<PersistedDocumentUiStoreState>>;

const migratePersistedDocumentUiStore = (persistedState: unknown, version: unknown): PersistedDocumentUiStoreState => {
    // TODO: this cast is unsafe: to correctly migrate the state, we should keep previous types, assert on those
    //  when the version matches some previous version, and safely upgrade the data to a PersistedDocumentUiStoreState,
    //  not just cast it.
    const existing = persistedState as PersistedDocumentUiStoreState;

    if (version === 0) {
        // default version, should only be present prior to the change where EditorModes becomes
        // a string enum.
        const editorModeMap: {[key: number]: EditorModes} = {
            0: EditorModes.schematic,
            1: EditorModes.code,
            2: EditorModes.pcb,
        };
        const maybeValidEditorMode = EditorModes[existing.editorMode];
        if (maybeValidEditorMode === undefined) {
            const currentState: unknown = existing.editorMode;
            existing.editorMode = editorModeMap[currentState as number]!;
            existing.leftDrawerActiveTab = {
                [EditorModes.code]: LeftEditorDrawerTabState.componentLibrary,
                [EditorModes.pcb]: LeftEditorDrawerTabState.componentLibrary,
                [EditorModes.schematic]: LeftEditorDrawerTabState.componentLibrary,
            };
        }
    }

    return existing;
};

const makeInitialInternalTabsState = (): InternalTabsState => ({
    CLI: false,
    prompt: false,
    CAL: false,
});

export const initialRightPaneWidth = 276;

export const createUsePersistedDocumentUiStoreHook = () =>
    create<PersistedDocumentUiStoreState>()(
        persist(
            (set) => ({
                showPcbGrid: true,
                setPcbShowGrid: (show: boolean) => {
                    set({showPcbGrid: show});
                },
                showSchematicGrid: true,
                setSchematicShowGrid: (show: boolean) => {
                    set({showSchematicGrid: show});
                },
                editorMode: EditorModes.schematic, // this actually gets set dynamically here frontend/src/components/pages/document/state_management/useDocumentMeta.ts
                setEditorMode: (mode: EditorModes) => {
                    set({editorMode: mode});
                },
                leftDrawerActiveTab: {
                    [EditorModes.code]: LeftEditorDrawerTabState.componentLibrary,
                    [EditorModes.pcb]: LeftEditorDrawerTabState.componentLibrary,
                    [EditorModes.schematic]: LeftEditorDrawerTabState.componentLibrary,
                },
                setLeftDrawerActiveTab: (tabState: LeftEditorDrawerTabState, editorMode: EditorModes) => {
                    set((state) => {
                        return {...state, leftDrawerActiveTab: {...state.leftDrawerActiveTab, [editorMode]: tabState}};
                    });
                },
                rightDrawerActiveTab: RightEditorDrawerTabState.inspector,
                rightDrawerChangedSinceSessionStart: false,
                setRightDrawerActiveTab: (tabState: RightEditorDrawerTabState) => {
                    set((state) => {
                        return {
                            ...state,
                            rightDrawerActiveTab: tabState,
                            rightDrawerChangedSinceSessionStart:
                                state.rightDrawerChangedSinceSessionStart || tabState !== state.rightDrawerActiveTab,
                        };
                    });
                },
                showBottomDrawerOpen: false,
                setBottomDrawerOpen: (show: boolean) => {
                    set({showBottomDrawerOpen: show});
                },
                leftPaneWidth: 276,
                setLeftPaneWidth: (width: number) => {
                    set((state) => {
                        return {...state, leftPaneWidth: width};
                    });
                },
                leftPaneCollapsed: false,
                setLeftPaneCollapsed: (collapsed: boolean) => {
                    set((state) => {
                        return {...state, leftPaneCollapsed: collapsed};
                    });
                },
                rightPaneWidth: initialRightPaneWidth,
                setRightPaneWidth: (width: number) => {
                    set((state) => {
                        return {...state, rightPaneWidth: width};
                    });
                },
                rightPaneCollapsed: false,
                setRightPaneCollapsed: (collapsed: boolean) => {
                    set((state) => {
                        return {...state, rightPaneCollapsed: collapsed};
                    });
                },
                // Internal tabs
                internalTabs: {enabled: makeInitialInternalTabsState(), CAL: ""},
                setEnabledInternalTabs: (stateOrUpdater) =>
                    set((prev) => ({
                        ...prev,
                        internalTabs: {
                            ...prev.internalTabs,
                            enabled:
                                typeof stateOrUpdater === "function"
                                    ? stateOrUpdater(prev.internalTabs.enabled)
                                    : stateOrUpdater,
                        },
                    })),
                setCAL: (script) => set((prev) => ({...prev, internalTabs: {...prev.internalTabs, CAL: script}})),
            }),
            {
                name: "usePersistedEditorUiStore",
                storage: createJSONStorage(() => window.sessionStorage),
                version: 1,
                migrate: migratePersistedDocumentUiStore,
            },
        ),
    );

interface UserPreferences {
    templateFilter: ProjectFilter;
    setTemplateFilter: (to: ProjectFilter) => void;

    // Copilot Nux Tooltip State
    copilotNuxTooltipState: {
        [key: string]: {
            showTooltip: boolean;
            numTimesTooltipShown: number;
        };
    };
    trackCopilotNuxTooltipViewed: (key: string, impressions: number) => void;
    setCopilotNuxTooltipSuccess: (key: string) => void;
    resetCopilotNuxTooltip: (key: string) => void;
}

/**
 * This hook provides client-side storage of user preferences across browser
 * sessions (but *not* across browsers / devices).
 *
 * @remarks
 *
 * DO NOT expose private user data in this store. This should be treated as effectively public data,
 * since it lives in localStorage attached to the browser+domain, *not* the user (e.g. two users logging
 * into the same browser).
 */
export const useLocalUserPublicPreferences = create(
    persist<UserPreferences>(
        (set) => ({
            templateFilter: ProjectFilter.allProjects,
            setTemplateFilter: (filter) => set({templateFilter: filter}),

            // Copilot Nux Tooltip State
            copilotNuxTooltipState: {},
            trackCopilotNuxTooltipViewed: (key, impressions) =>
                set((state) => {
                    const copilotNuxTooltipState = state.copilotNuxTooltipState[key];

                    if (copilotNuxTooltipState) {

                        if (copilotNuxTooltipState.showTooltip) {
                            const numTimesTooltipShown = (copilotNuxTooltipState.numTimesTooltipShown ?? 0) + 1;

                            return {
                                ...state,
                                copilotNuxTooltipState: {
                                    ...state.copilotNuxTooltipState,
                                    [key]: {
                                        ...copilotNuxTooltipState,
                                        numTimesTooltipShown: numTimesTooltipShown,
                                        showTooltip: numTimesTooltipShown <= impressions,
                                    },
                                },
                            };
                        } else {
                            return state;
                        }

                    } else {
                        return {
                            ...state,
                            copilotNuxTooltipState: {
                                ...state.copilotNuxTooltipState,
                                [key]: {
                                    showTooltip: true,
                                    numTimesTooltipShown: 1,
                                },
                            },
                        };
                    }
                }),
            setCopilotNuxTooltipSuccess: (key) =>
                set((state) => {
                    const copilotNuxTooltipState = state.copilotNuxTooltipState[key];

                    if (copilotNuxTooltipState) {
                        return {
                            ...state,
                            copilotNuxTooltipState: {
                                ...state.copilotNuxTooltipState,
                                [key]: {
                                    ...copilotNuxTooltipState,
                                    showTooltip: false,
                                },
                            },
                        };
                    }

                    return state;
                }),
            resetCopilotNuxTooltip: (key) =>
                set((state) => {
                    const copilotNuxTooltipState = state.copilotNuxTooltipState[key];

                    if (copilotNuxTooltipState) {
                        return {
                            ...state,
                            copilotNuxTooltipState: {
                                ...state.copilotNuxTooltipState,
                                [key]: {
                                    showTooltip: true,
                                    numTimesTooltipShown: 0,
                                },
                            },
                        };
                    }

                    return state;
                }),
        }),
        {
            name: LocalStorageKey.userPrefs, // allows for a default store (for unknown user)
            storage: createJSONStorage(() => localStorage), // this is the default
        },
    ),
);
