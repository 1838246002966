import {
    PcbBakedNode,
    PcbNode,
    PcbNodeTypes,
    PcbTreeManager,
    PcbLayoutRuntimeRulesManager,
    PcbNodeBaker,
} from "@buildwithflux/core";
import {AnyPcbBakedNode, AnyPcbNode, IDocumentData, PcbNodesMap} from "@buildwithflux/models";
import {DocumentService} from "@buildwithflux/solder-core";

import {FluxLogger} from "../../../../../../../../../../../../modules/storage_engine/connectors/LogConnector";
import {getAllBakedNodes} from "../../../../../../../../../../../../modules/stores/pcb/utils";

/**
 * The purpose of this function to is to answer the question: IF we were to add this node given the current state
 * of the layout engine, what would the resulting bakedRules look like for that node?
 *
 * @returns a copy of the 'newNode' with bakedRules included
 */
export default function createPseudoNode<T extends PcbNodeTypes>(
    newNode: PcbNode<T>,
    documentState: IDocumentData | null,
    documentService: DocumentService,
    additionalNodesToAddToTree: AnyPcbBakedNode[] = [],
): PcbBakedNode<T> | undefined {
    if (!documentState) {
        return;
    }

    const allBakedNodes = getAllBakedNodes(documentService);
    const allBakedNodesWithAdditionalNodes: PcbNodesMap<AnyPcbNode> = {
        ...allBakedNodes,
        [newNode.uid]: newNode,
    };
    additionalNodesToAddToTree.forEach((node) => {
        allBakedNodesWithAdditionalNodes[node.uid] = node;
    });
    const treeManager = new PcbTreeManager(allBakedNodesWithAdditionalNodes, console, true);
    const pcbNodeBaker = new PcbNodeBaker(
        documentState,
        documentState.pcbLayoutRuleSets,
        new PcbLayoutRuntimeRulesManager(),
        treeManager,
        FluxLogger,
    );

    // TODO: it'd be cool if we had an API to the baking engine, that allowed us to query a baked rule value(s) for a node, without
    // the need for creating/passing a pseudo node to the baking service
    const {flattenedRules, bakedRules} = pcbNodeBaker.bakeNode(newNode);
    Object.assign(newNode, {
        bakedRules,
        flattenedRules,
        childUids: [],
    });
    return newNode as PcbBakedNode<T>;
}
