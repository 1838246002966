// eslint-disable-next-line no-restricted-imports
import {CloseReason, OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar} from "notistack";
import React, {useCallback} from "react";

import {useFluxServices} from "../../../injection/hooks";
import {SurfaceBasedTrackingEvents} from "../../../modules/storage_engine/common/SurfaceBasedTrackingEvents";

function reactNodeToString(node: React.ReactNode): string {
    if (typeof node === "string") {
        return node;
    } else if (typeof node === "number") {
        return node.toString();
    } else if (Array.isArray(node)) {
        return node.map(reactNodeToString).join("");
    } else {
        return "";
    }
}

export function useFluxSnackbar() {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {analyticsStorage} = useFluxServices();

    const enqueueFluxSnackbar = useCallback(
        (
            message: SnackbarMessage,
            // Provide the surface or object that is firing the snackbar.
            // Ideally re-use existing values
            surfaceOrObject: "project" | "component" | "part" | "asset" | string,
            options?: OptionsObject,
        ) => {
            const key = enqueueSnackbar(message, options);

            analyticsStorage.logEvent(SurfaceBasedTrackingEvents.snackbar, {
                action: "enqueueSnackbar",
                key: key,
                surfaceOrObject: surfaceOrObject,
                variant: options?.variant,
                position: `${options?.anchorOrigin?.vertical}-${options?.anchorOrigin?.horizontal}`,
                content: reactNodeToString(message),
            });

            if (options) {
                const originalOnClose = options.onClose;

                options.onClose = (event: React.SyntheticEvent<any> | null, reason: CloseReason, key?: SnackbarKey) => {
                    // Call the original onClose if it exists
                    if (originalOnClose) {
                        originalOnClose(event, reason, key);
                    }

                    analyticsStorage.logEvent(SurfaceBasedTrackingEvents.snackbar, {
                        action: "onClose",
                        key: key,
                        surfaceOrObject: surfaceOrObject,
                        reason: reason,
                        variant: options?.variant,
                        position: `${options?.anchorOrigin?.vertical}-${options?.anchorOrigin?.horizontal}`,
                        content: reactNodeToString(message),
                    });
                };
            }

            return key;
        },
        [enqueueSnackbar, analyticsStorage],
    );

    const closeFluxSnackbar = useCallback(
        (key?: SnackbarKey | undefined) => {
            closeSnackbar(key);

            analyticsStorage.logEvent(SurfaceBasedTrackingEvents.snackbar, {
                action: "closeSnackbar",
                key: key,
            });
        },
        [closeSnackbar, analyticsStorage],
    );

    return {enqueueSnackbar: enqueueFluxSnackbar, closeSnackbar: closeFluxSnackbar};
}
