/**
 * @deprecated DO NOT USE THESE AND NEITHER USE THESE PATTERNS!
 * DO NOT ADD SHIT HERE! THESE ARE FROZEN!!!!!
 */
export const DeprecatedTrackingEvents = {
    // SLOW DOWN, DO NOT ADD ANYTHING HERE PLEASE!

    // Bad patterns we need to stop and migrate
    exportFlx: "export_flx",
    exportKicad: "export_kicad",
    exportKicadPcb: "export_kicad_pcb",
    exportPng: "export_png",
    exportStl: "export_stl",
    exportBinaryStl: "export_binary_stl",
    exportCollada: "export_collada",
    exportBom: "export_bom",
    exportPickAndPlace: "export_pick_and_place",
    exportGerber: "export_gerber",
    exportGerberNative: "export_gerber_native",
    exportNetlist: "export_netlist",
    exportJEP30: "export_JEP30",
    exportDeepPcbJson: "export_deep_pcb_json",

    // SLOW DOWN, DO NOT ADD ANYTHING HERE PLEASE!

    createDocument: "create_document",
    createBlankDocument: "create_blank_document",
    createDocumentFromTemplate: "create_document_from_template",

    // SLOW DOWN, DO NOT ADD ANYTHING HERE PLEASE!

    cloneDocument: "clone_document",
    forkDocument: "fork_document",

    // these are just so bad...should have been "performance" and then a action field
    perfAddElements: "addElements",
    perfAddLocalRule: "addLocalRule",
    perfAddPropertiesDocument: "addProperties__document",
    perfAddPropertiesSubject: "addProperties__subject",
    perfAddSubjectCommentThread: "addSubject__commentThread",
    perfAddSubjectUserPresence: "addSubject__userPresence",
    perfDeletePcbLayoutNodes: "deletePcbLayoutNodes",
    perfDeletePcbLayoutRuleSets: "deletePcbLayoutRuleSets",
    perfDeleteSchematicSubjects: "deleteSchematicSubjects",
    perfNetAddRouteSegment: "Net__addRouteSegment",
    perfOnDragEndPcb: "onDragEnd__pcb",
    perfPcbLayoutRuleListChangeGlobal: "PcbLayoutRuleList__change__global",
    perfPcbLayoutRuleListChangeLocal: "PcbLayoutRuleList__change__local",
    perfPerformPlaceVia: "performPlaceVia",
    perfPerformSwitchRoutingLayer: "performSwitchRoutingLayer",
    perfPerformZoomToFitSchematic: "performZoomToFit__schematic",
    perfPerformZoomToFitSchematicCode: "performZoomToFit__code",
    perfPerformZoomToFitSchematicPcb: "performZoomToFit__pcb",
    perfPlaceWire: "placeWire",
    perfRedo: "redo__perf",

    // SLOW DOWN, DO NOT ADD ANYTHING HERE PLEASE!

    perfRemovePropertiesDocument: "removeProperties__document",
    perfRemovePropertiesSubject: "removeProperties__subject",
    perfRotatePcbNode: "rotatePcbNode",
    perfRotateSubjects: "rotateSubjects",
    perfSelectSubject: "selectSubject",
    perfSetAirwireVisibility: "setAirwireVisibility",
    perfSetCopperFillVisibility: "setCopperFillVisibility",
    perfSetEditorModeSchematic: "setEditorMode__schematic",
    perfSetEditorModeCode: "setEditorMode__code",
    perfSetEditorModePcb: "setEditorMode__pcb",
    perfSetFlippedCamera: "setFlippedCamera",
    perfSetLabelsVisibility: "setLabelsVisibility",
    perfSetLayerVisibility: "setLayerVisibility",
    perfSetPcbLayoutRuleSetsMove: "setPcbLayoutRuleSets__move",
    perfSetPcbShowGrid: "setPcbShowGrid",
    perfSetSchematicShowGrid: "setSchematicShowGrid",
    perfSetToggleObjectSpecificPcbRule: "setToggleObjectSpecificPcbRule",
    perfSetTogglePcbRule: "setTogglePcbRule",
    perfSetTraceWidth: "setTraceWidth",
    perfUndo: "undo__perf",
    perfUpdateCameraMode2d: "updateCameraMode__two_d",
    perfUpdateCameraMode3d: "updateCameraMode__three_d",
    perfUpdatePropertiesDocument: "updateProperties__document",
    perfUpdatePropertiesSubject: "updateProperties__subject",

    // SLOW DOWN, DO NOT ADD ANYTHING HERE PLEASE!

    // a better patttern would have been to have a version field on the event
    signIn: "sign_in",
    signInV2: "sign_in_v2",
    signOut: "sign_out",
    signUp: "sign_up",
    signUpV2: "sign_up_v2",
    signUpRedirectToFlux1: "sign_up_redirect_to_flux1",

    // SLOW DOWN, DO NOT ADD ANYTHING HERE PLEASE!

    copyPaste: "copy_paste",
    copyPasteSpecial: "copy_paste_special",

    alignmentPcb: "alignment_pcb",
    alignmentSchematics: "alignment_schematics",

    deleteDocument: "delete_document",
    deletePartAndPartVersions: "delete_part_and_part_versions",

    deleteObjects: "delete_objects",

    fetchPartAvail: "fetch_part_availability",
    filterDocumentContents: "filter_document_contents",
    flipLayer: "flip_layer",
    flipSubjects: "flip_subjects",

    // SLOW DOWN, DO NOT ADD ANYTHING HERE PLEASE!

    importKicadPart: "import_kicad_part",
    inviteAccepted: "invite_accepted",

    manufactureButtonClick: "manufacture_button_click",
    measureTool: "measure_tool",
    mobileVisitor: "mobile_visitor",
    navControlSettings: "navcontrols_settings",

    playNewRemoteUserSound: "play_new_remote_user_sound",
    previewGerber: "preview_gerber",

    redo: "redo",
    replaceElement: "replace_element",
    requestDocument: "request_document",
    resetPassword: "reset_password",
    /**
     * @deprecated see TrackingEventActions.changeHistory.restore
     */
    revertDocumentVersion: "revertDocumentVersion",

    // SLOW DOWN, DO NOT ADD ANYTHING HERE PLEASE!

    rotateSubjects: "rotate_subjects",
    selectEntireWire: "select_entire_wire",
    setPart: "set_part",
    setPartVersion: "set_part_version",
    setUsername: "set_username",
    shadowbanParts: "shadowban_parts", // internal-only

    signupCleanupDeleteUser: "signup_cleanup_delete_user",

    // SLOW DOWN, DO NOT ADD ANYTHING HERE PLEASE!

    spaceEvenly: "space_evenly",
    starDocument: "star_document",
    switchLayerDuringRouting: "switch_layer_during_routing",

    unarchivePart: "unarchive_part",
    unstarDocument: "unstar_document",
    undo: "undo",
    updateDocument: "update_document",
    updatePart: "update_part",

    // SLOW DOWN, DO NOT ADD ANYTHING HERE PLEASE!

    visitorContinueToDesktop: "visitor_continue_to_desktop",
    setLayoutRule: "setLayoutRule",

    addPartToDocument: "add_part_to_document",
    addPcbNode: "addPcbNode",
    addViaDuringRouting: "add_via_during_routing",

    archiveDocument: "archive_document",
    archivePart: "archive_part",
    autoSpaceSubjects: "auto_space_subjects",

    // SLOW DOWN, DO NOT ADD ANYTHING HERE PLEASE!

    loadDocument: "load_document",
    loadedDocument: "loaded_document",
    loadedEditor: "loaded_editor",
    loadUserProfile: "load_user_profile",

    partUpdate: "part_update",

    captureLeadEmail: "capture_lead_email",

    // SLOW DOWN, DO NOT ADD ANYTHING HERE PLEASE!
} as const;

/**
 * @deprecated DO NOT USE THESE AND NEITHER USE THESE PATTERNS!
 * DO NOT ADD SHIT HERE! THESE ARE FROZEN!!!!!
 */
export type DeprecatedTrackingEvent = (typeof DeprecatedTrackingEvents)[keyof typeof DeprecatedTrackingEvents];
