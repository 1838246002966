import {BOMFormat, ColumnDefinition} from "./base";

/**
 * Eurocircuits BOM format
 * https://www.eurocircuits.com/ec-smart-tools-user-guides/upload-bom-data/#BOM_DATA_FILE_Format
 */
export class EurocircuitsFormat extends BOMFormat {
    static override manufacturer = "Eurocircuits";

    static override columns: Record<string, ColumnDefinition> = {
        "Reference designators": (item) => item.designators.join(","),
        Quantity: "quantity",
        MPN: "mpn",
        Manufacturer: "manufacturer",
        "Part description": (item) => item.specification.join(", "),
        Value: "value",
        SPN: "dpn",
        Supplier: "distributorName",
        Package: "package",
    };
}
