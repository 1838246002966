import {BOMFormat, ColumnDefinition} from "./base";

/**
 * JLCPCB BOM format
 * https://jlcpcb.com/help/article/45-Bill-of-Materials(BOM)-File-for-PCB-Assembly
 */
export class JLCPCBBOMFormat extends BOMFormat {
    static override manufacturer = "JLCPCB";
    static override columns: Record<string, ColumnDefinition> = {
        Comment: (item) => item.mpn || item.specification.join(" "),
        Designator: (item) => item.designators.join(", "),
        Footprint: "package",
        "JLCPCB Part #": "dpn",
    };
}
