import {defaultPropertyDefinitions} from "@buildwithflux/constants";
import {
    createSubjectExpressionContext,
    ElementHelper,
    getAbsoluteDocumentUrlFromUid,
    getTopLevelLayouts,
    naturalSortByProperty,
    PcbNodeHelper,
    stringifyPropertyValue,
} from "@buildwithflux/core";
import {IElementData, IElementsMap, IExtractedElementData, IExtractedElementDataMap} from "@buildwithflux/models";
import {ExportToCsv} from "export-to-csv";
import fileDownload from "js-file-download";
import JSZip from "jszip";
import {groupBy, pick, pickBy, uniq} from "lodash";

import {PartStorageHelper} from "../../../storage_engine/helpers/PartStorageHelper";
import {BaseExporter} from "../BaseExporter";

import {
    AdvancedCircuitsFormat,
    AllPCBFormat,
    BOMFormat,
    ElecrowFormat,
    EurocircuitsFormat,
    FluxFormat,
    JLCPCBBOMFormat,
    LineItem,
    PCBWayFormat,
    SeeedFormat,
} from "./formats";

type IDataRow = {
    "Flux.ai Element ID": string;
    Quantity: number;
    Designator: string;
    "Position X": string;
    "Position Y": string;
    Rotation: number;
    Flipped: boolean;
    "Part Name": string;
    "Part ID": string;
    "Part Version": string;
    "Number of Terminals": string;
    Terminals: string;
    "Flux.ai url"?: string;
    "Manufacturer Part Number"?: string;
    "Manufacturer Name"?: string;
    Manufacturer?: string;
    "Mount Type"?: string;
    "Package or Case Code"?: string;
    Package?: string;
    Capacitance?: string;
    Resistance?: string;
    Inductance?: string;
    Notes?: string;
    Description?: string;
    "Part Type"?: string;
    "Distributor Part Number"?: string;
    DPN?: string;
    "Preferred Distributors"?: string;
};

const REQUIRED_DATAROW_FIELDS = [
    "Flux.ai Element ID",
    "Quantity",
    "Designator",
    "Position X",
    "Position Y",
    "Rotation",
    "Flipped",
    "Part Name",
    "Part ID",
    "Part Version",
    "Number of Terminals",
    "Terminals",
];

const exportFormats = [
    AdvancedCircuitsFormat,
    AllPCBFormat,
    EurocircuitsFormat,
    JLCPCBBOMFormat,
    PCBWayFormat,
    FluxFormat,
    SeeedFormat,
    ElecrowFormat,
] as const;

function toLineItem(elementProperties: Record<string, any>): (row: IDataRow) => LineItem {
    return (row: IDataRow) => {
        const valueProp = getValueProperty(row);
        return {
            partUid: row["Part ID"],
            partName: row["Part Name"],
            partDocumentUrl: row["Flux.ai url"] ?? "",
            mpn: row["Manufacturer Part Number"] ?? "",
            quantity: row.Quantity,
            designators: row.Designator.split(","),
            specification: buildSpecification(row),
            manufacturer: row["Manufacturer Name"] ?? row.Manufacturer ?? "",
            mountType: row["Mount Type"] ?? "",
            value: valueProp ? (row[valueProp] as string) : "",
            package: row["Package or Case Code"] ?? row["Package"],
            notes: row.Notes ?? "",
            description: row.Description ?? `${row["Part Type"]} ${row[getValueProperty(row) ?? "Part Name"]}}`,
            elementUids: (row["Flux.ai Element ID"] ?? "").split(","),
            elementProperties: (row["Flux.ai Element ID"] ?? "").split(",").reduce((acc: any, elementUid: string) => {
                acc[elementUid] = elementProperties[elementUid];
                return acc;
            }, {}),
            dpn: row["Distributor Part Number"] ?? row["DPN"] ?? "",
            distributorName: row["Preferred Distributors"] ?? "",
            schematicPositions: {
                [row["Flux.ai Element ID"]]: {
                    x: parseFloat(row["Position X"]),
                    y: parseFloat(row["Position Y"]),
                    rotation: row.Rotation,
                    flipped: row.Flipped,
                },
            },
        };
    };
}

/**
 * Basic function to find the correct value property based on the part type. The matching is dumb
 * but getting it wrong is low impact - elements with missing or weird sets of properties just won't
 * be grouped together.
 *
 * TODO: revisit this once we can model these relationships in property definitions.
 *
 * TODO: I think the functionality of this function is already covered more generally by
 * Element.getDefaultPartProperty() (which admittedly could be more straightforward itself)
 */
function getValueProperty(row: any): "Capacitance" | "Resistance" | "Inductance" | undefined {
    if (row["Part Type"]?.match(/Capacitor/)) {
        return "Capacitance";
    } else if (row["Part Type"]?.match(/Resistor/)) {
        return "Resistance";
    } else if (row["Part Type"]?.match(/Inductor/)) {
        return "Inductance";
    } else {
        return undefined;
    }
}

function buildSpecification(row: IDataRow): string[] {
    const valueProp = getValueProperty(row);

    const attrs = [row["Part Type"] ?? ""];

    if (valueProp && row[valueProp]) {
        attrs.push(row[valueProp] as string);
    }

    const pkg = row["Package or Case Code"] || row["Package"];
    if (pkg) {
        attrs.push(pkg);
    }

    return attrs;
}

function buildGroupString(row: IDataRow): string {
    if (row["Manufacturer Part Number"]) {
        return row["Manufacturer Part Number"].trim();
    }

    const valueProp = getValueProperty(row);

    if (valueProp && row[valueProp]) {
        return `${(row["Part Type"] as string).trim()}-${(row[valueProp] ?? "").trim()}`;
    } else if (row["Part ID"]) {
        return row["Part ID"];
    } else {
        return row["Flux.ai Element ID"];
    }
}

function sortedAppend(base: string, toAppend: string) {
    if (base) {
        return uniq(base.split(",").concat(toAppend.split(",")).sort()).join(",");
    } else {
        return toAppend;
    }
}

function hasLayout(element: IExtractedElementData) {
    const elementPcbNodes = element.part_version_data_cache?.pcbLayoutNodes;
    return elementPcbNodes && getTopLevelLayouts(elementPcbNodes).length > 0;
}

export class BillOfMaterialsExporter extends BaseExporter {
    public static hasThingsToExport(elements: IElementsMap) {
        return Object.keys(BillOfMaterialsExporter.getValidElementNodes(elements)).length > 0;
    }

    private static getValidElementNodes(elements: IExtractedElementDataMap, parentElementUid?: string) {
        const bomElements: {[uid: string]: IElementData | IExtractedElementData} = {};

        for (const [elementUid, element] of Object.entries(elements)) {
            if (!BillOfMaterialsExporter.isValidBomElement(element)) continue;
            const fullElementUid = parentElementUid
                ? PcbNodeHelper.constructNodeUidForSubLayout(parentElementUid, elementUid)
                : elementUid;

            const elementHasNoLayout = !hasLayout(element);
            // If the element has no layout, it should be treated as an opaque part.
            if (elementHasNoLayout) {
                bomElements[fullElementUid] = element;
                continue;
            }

            const subElements = BillOfMaterialsExporter.getValidElementNodes(
                element.part_version_data_cache?.extracted_element_cache ?? {},
                fullElementUid,
            );

            if (Object.keys(subElements).length) {
                Object.assign(bomElements, subElements);
            } else {
                bomElements[fullElementUid] = element;
            }
        }
        return bomElements;
    }

    private static isValidBomElement(element: IElementData | IExtractedElementData) {
        return (
            element &&
            !ElementHelper.isTerminalElement(element) &&
            !ElementHelper.isGroundElement(element) &&
            !PartStorageHelper.isExcludedFromBOM(element) &&
            !ElementHelper.isBranchPointElement(element)
        );
    }

    public download(versionShortCode?: string) {
        const options = {
            fieldSeparator: ",",
            decimalSeparator: ".",
            quoteStrings: '"',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
        };
        const csvExporter = new ExportToCsv(options);
        const spreadsheets = this.createBomData();
        const zip = new JSZip();

        versionShortCode = versionShortCode ?? "0";
        const exportName = `BOM - V${versionShortCode}`;

        const boms = zip.folder(this.createFileName(undefined, exportName))!;

        spreadsheets.forEach((spreadsheet) => {
            boms.file(
                this.createFileName("csv", `${exportName} - ${spreadsheet.name}`),
                csvExporter.generateCsv(spreadsheet.getRowData(), true),
            );
        });

        zip.generateAsync({type: "blob"}).then((content) => {
            fileDownload(content, this.createFileName("zip", exportName));
        });
    }

    public createBomData(): BOMFormat[] {
        const dataRows: IDataRow[] = [];

        const elements = BillOfMaterialsExporter.getValidElementNodes(this.documentData.elements);
        const elementProperties: Record<string, any> = {};

        for (const [elementUid, element] of Object.entries(elements)) {
            const properties = this.getProperties(element, elementUid);
            elementProperties[elementUid] = properties;

            const isExtractedElement = ElementHelper.isExtractedElement(element);
            const dataRow: IDataRow = {
                "Flux.ai Element ID": elementUid,
                Quantity: 1,
                Designator: element.label || "",
                ...properties,
                "Part Name": element.part_version_data_cache?.name ?? "",
                Description: element.part_version_data_cache?.description ?? "",
                "Part ID": element.part_version_data_cache?.part_uid ?? "",
                "Part Version": element.part_version_data_cache?.version ?? "",
                "Number of Terminals":
                    Object.keys(element.part_version_data_cache?.terminals ?? {}).length.toString() ?? "",
                "Flux.ai url": this.getAbsoluteUrl(element),
                Terminals: isExtractedElement ? "" : this.getTerminals(element),
            };

            dataRows.push(dataRow);
        }

        const groups = groupBy(this.removeUnusedColumns(dataRows), buildGroupString);

        const groupedDataRows = Object.values(groups)
            .map((elementRows) => {
                return elementRows.reduce((row, mergedRow) => {
                    mergedRow.Quantity += row.Quantity;
                    mergedRow["Flux.ai Element ID"] = sortedAppend(
                        mergedRow["Flux.ai Element ID"],
                        row["Flux.ai Element ID"],
                    );
                    mergedRow["Flux.ai url"] = row["Flux.ai url"];
                    mergedRow.Designator = sortedAppend(mergedRow.Designator, row.Designator);
                    return mergedRow;
                });
            })
            .sort(naturalSortByProperty("Designator"));

        const lineItems = groupedDataRows.map(toLineItem(elementProperties));
        return exportFormats.map((format) => new format(lineItems));
    }

    private getAbsoluteUrl(element: IElementData | IExtractedElementData) {
        const documentUid = ElementHelper.isExtractedElement(element)
            ? element.document_import_uid
            : element.part_version_data_cache.document_import_uid;
        if (documentUid) {
            return getAbsoluteDocumentUrlFromUid(documentUid);
        } else {
            return undefined;
        }
    }

    private removeUnusedColumns(data: IDataRow[]): IDataRow[] {
        const keysToStay = uniq(data.flatMap((row) => Object.keys(pickBy(row, (v) => !!v))));
        keysToStay.push(...REQUIRED_DATAROW_FIELDS);
        return data.map((row) => pick(row, keysToStay) as IDataRow);
    }

    private getTerminals(element: IElementData) {
        const propertiesStringArray: string[] = [];

        Object.values(element.part_version_data_cache.terminals ?? {}).forEach((terminal) => {
            let terminalString = `${terminal.uid}:${terminal.name || ""}`;
            if (terminal.type) {
                terminalString = terminalString + `(${terminal.type})`;
            }
            propertiesStringArray.push(terminalString);
        });

        return propertiesStringArray.join(" ");
    }

    private getProperties(element: IElementData | IExtractedElementData, elementUid: string) {
        const properties: any = {};
        const elementProperties = Object.values(element.properties ?? {});
        const defaultProperties = Object.values(defaultPropertyDefinitions);

        const bomDefaultProperties = defaultProperties.filter((prop) => prop.exclude_from_bom !== true);

        bomDefaultProperties.forEach((defaultProp) => {
            const property = elementProperties.find((prop) => prop.name === defaultProp.label);

            const linkedPcbLayoutElementNode = this.documentData.pcbLayoutNodes[elementUid];
            const context = createSubjectExpressionContext(element, this.documentData, linkedPcbLayoutElementNode);

            if (property && context) {
                properties[defaultProp.label] = stringifyPropertyValue({unit: defaultProp.unit, ...property}, context);
            } else {
                properties[defaultProp.label] = "";
            }
        });

        const customProperties = elementProperties.filter((prop) => {
            const isDefaultProperty = bomDefaultProperties.find((defaultProp) => defaultProp.label === prop.name);

            return !isDefaultProperty;
        });

        customProperties.forEach((customProp) => {
            if (customProp.name) {
                properties[customProp.name] = customProp.value;
            }
        });

        return properties;
    }
}
