import {PayloadAction, PayloadActionCreator, PrepareAction} from "@reduxjs/toolkit";
import {Action, AnyAction} from "redux";
import {batchActions} from "redux-batched-actions";

import {useFeatureFlags} from "../../modules/common/hooks/featureFlags/useFeatureFlag";
import {snackbarErrorGeneratingHelper} from "../../modules/common/providers/SnackbarCustomProvider";
import {enqueueNotification, toggleEditorBackDrop} from "../../redux/reducers/app/actions";
import {DocumentPayloadProps, IDocumentReduxAction} from "../../redux/reducers/document/actions.types";

export interface IBatchAction {
    payload: IDocumentReduxAction[];
    meta: {batch: true};
}

export const isBatchAction = <T>(action: Action<T> | IBatchAction): action is IBatchAction => {
    return (action as IBatchAction).meta && (action as IBatchAction).meta.batch;
};

type AtLeastOnePayloadCreator<P, T extends string, S extends PrepareAction<P>> = [
    PayloadActionCreator<P, T, S>,
    ...PayloadActionCreator<P, T, S>[],
];

type Matcher<P, T extends string, S extends PrepareAction<P>> = (
    action: AnyAction | IBatchAction,
) => action is ReturnType<PayloadActionCreator<P, T, S>>;
// We really do many any here.  We don't care what the signature of PrepareAction is.
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createActionDiscriminator = <P, T extends string, S extends PrepareAction<any>>(
    ...matchAgainst: AtLeastOnePayloadCreator<P, T, S>
): Matcher<P, T, S> => {
    return (action: AnyAction | IBatchAction): action is ReturnType<PayloadActionCreator<P, T, S>> => {
        if (isBatchAction(action)) {
            for (const a of action.payload) {
                for (const match of matchAgainst) {
                    if (match.type === (a as ReturnType<typeof match>).type) return true;
                }
            }
        } else {
            for (const match of matchAgainst) {
                if (match.type === (action as ReturnType<typeof match>).type) return true;
            }
        }
        return false;
    };
};

/**
 * Use this method to parse payload for an action that _may be_
 * batched. This is useful and needed especially in other redux
 * middleware such as epics
 *
 * @param matchAction action you want to get payload from
 */
export const createActionPayloadParser = <P, T extends string, S extends PrepareAction<any>>(
    matchAction: PayloadActionCreator<P, T, S>,
): ((action: PayloadAction<DocumentPayloadProps> | IBatchAction) => DocumentPayloadProps | undefined) => {
    return (action: PayloadAction<DocumentPayloadProps> | IBatchAction): DocumentPayloadProps | undefined => {
        if (isBatchAction(action)) {
            const matchedAction = action.payload.find((a) => a.type === matchAction.type);

            return matchedAction?.payload;
        } else if (action.type === matchAction.type) {
            return action.payload;
        }

        return undefined;
    };
};

export function enqueueSyncErrorNotification(message: string) {
    const showSyncErrors = useFeatureFlags.getState().showSyncErrors ?? true;
    if (showSyncErrors) {
        return batchActions([
            toggleEditorBackDrop(true),
            enqueueNotification(snackbarErrorGeneratingHelper(message), "project", {
                variant: "error",
                preventDuplicate: true,
                persist: true,
                key: "syncError2",
            }),
        ]);
    }
    return batchActions([]); // no-op
}
