import {trackingEventCopilot, trackingEventCopilotCredits, trackingEventNewProjectDialog} from "@buildwithflux/core";

// READ THIS FIRST BEFORE ADDING THINGS HERE!!!

// We are capped by how many events we can have in Amplitude...so we want to
// keep this list short!
// Additonally its easier to browse these events in analytics tools
// if their are organized by surface or object type and NOT by action/feature/etc
export const SurfaceBasedTrackingEvents = {
    // SLOW DOWN BEFORE YOU ADD STUFF HERE...THINK HARD! DOES IT REALLY, REALLY BELONG HERE?

    // Main Surfaces
    editor: "editor",
    schematicEditor: "schematic_editor",
    pcbEditor: "pcb_editor",
    siteSearch: "site_search",

    // Sub surfaces
    onboardingUx: "onboardingUX",
    snackbar: "snackbar",
    newProjectDialog: trackingEventNewProjectDialog,
    projectTemplate: "ProjectTemplate",
    markdown: "markdown",
    accountMenu: "account_menu",

    // Payments
    showPaywall: "show_paywall",

    // Editor Sub surfaces
    partSearch: "part_search",
    partPublishing: "part_publishing",
    permissionDialog: "permission_dialog",
    shortcutViewer: "shortcut_viewer",
    fileStorage: "file_storage",
    toolbarNodeEdit: "toolbar_node_edit",
    componentLibraryRefinement: "component_library_refinement",
    changeHistory: "change_history",
    designRuleCheck: "design_rule_check",
    export: "export",

    // Profile sub surfaces
    profileDocumentSearch: "profile_document_search",

    // Copilot
    copilot: trackingEventCopilot,
    copilotCredits: trackingEventCopilotCredits,

    // SLOW DOWN BEFORE YOU ADD STUFF HERE...THINK HARD! DOES IT REALLY, REALLY BELONG HERE?
} as const;
export type SurfaceBasedTrackingEvent = (typeof SurfaceBasedTrackingEvents)[keyof typeof SurfaceBasedTrackingEvents];
