import {trackingEventActionsForAccounts} from "@buildwithflux/core";
import {FunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";
import {CurrentUser, UserStateType, UserUid, IUserData, IUserPrivateMetadata} from "@buildwithflux/models";
import {PlanEntitlementService, isEligibleForTrialReactivation} from "@buildwithflux/plans";
import {Logger, wait} from "@buildwithflux/shared";
import type {FirebaseError} from "@firebase/util";

import {AnalyticsStorage} from "../../storage_engine/AnalyticsStorage";
import {CurrentUserService} from "../types";

export class TrialReactivationService {
    private hasAttemptedReactivationForUid: UserUid | undefined = undefined;
    private rolloutFeatureFlagEnabled = false;

    constructor(
        private readonly currentUserService: CurrentUserService,
        private readonly functionsAdapter: FunctionsAdapter,
        private readonly planEntitlement: PlanEntitlementService,
        private readonly analyticsStorage: AnalyticsStorage,
        private readonly logger: Logger,
    ) {
        this.currentUserService.subscribeToUserChanges((user) => this.onUserChanged(user));
    }

    /**
     * Listens for changes to the feature flag state, and attempts to reactivate the trial once a user is loaded and feature flag state is set
     *
     * Due to FLUX-5015, we need to receive this feature flag state from a frontend hook to avoid a circular dependency
     */
    public receiveFeatureFlags(rolloutFeatureFlagEnabled = false): void {
        this.rolloutFeatureFlagEnabled = rolloutFeatureFlagEnabled;

        if (this.rolloutFeatureFlagEnabled) {
            const user = this.currentUserService.getCurrentUser();
            const privateMetadata = this.currentUserService.getCurrentUserPrivateMetadata();

            if (user && privateMetadata) {
                this.reactivateIfNeeded(user, privateMetadata);
            }
        }
    }

    /**
     * Listens for changes to the user, and attempts to reactivate the trial once a user is loaded and feature flag state is set
     */
    private onUserChanged(currentUser: CurrentUser & {suggestedState?: UserStateType}): void {
        const {user, privateMetadata, suggestedState} = currentUser;

        if (!this.rolloutFeatureFlagEnabled) {
            return;
        }

        if (!user || !privateMetadata || (suggestedState && suggestedState !== "authenticated")) {
            return;
        }

        this.reactivateIfNeeded(user, privateMetadata);
    }

    /**
     * Attempt to reactivate the trial if the user is eligible
     */
    private reactivateIfNeeded(user: IUserData, privateMetadata: IUserPrivateMetadata): void {
        if (this.hasAttemptedReactivationForUid === user.uid) {
            return;
        }

        if (!isEligibleForTrialReactivation(user, privateMetadata, this.planEntitlement).isEligible) {
            return;
        }

        this.hasAttemptedReactivationForUid = user.uid;
        this.logger.info("Attempting trial reactivation for current user");
        this.attemptReactivation()
            .then(() => {
                this.logger.info("Trial reactivated");

                void this.analyticsStorage.trackAccountEvent(trackingEventActionsForAccounts.trialReactivated, user, {
                    result: "success",
                });
            })
            .catch((e: unknown) => {
                this.logger.warn("Could not reactivate trial", {error: e});

                void this.analyticsStorage.trackAccountEvent(trackingEventActionsForAccounts.trialReactivated, user, {
                    result: "error",
                });
            });
    }

    /**
     * Inner function that calls the backend function to reactivate the trial
     *
     * Retries up to 3 times if we encounter FLUX-4073
     */
    private async attemptReactivation(attempt = 1): Promise<void> {
        try {
            await this.functionsAdapter.reactivateTrial();
        } catch (error: unknown) {
            if ((error as FirebaseError)?.code === "functions/internal" && attempt < 3) {
                /*
                 * This is some sort of CORS error, perhaps due to a lack of Access-Control-Allow-Credentials
                 * in the CORS response
                 *
                 * But as this is an onCall, Firebase is supposed to be handling the CORS response for us,
                 * and we don't really have control over it.
                 *
                 * All we can do is retry, which actually usually succeeds after some delay. This same handling
                 * has been applied elsewhere in the codebase, e.g. frontend/src/modules/payments/state/credit.ts:86
                 *
                 * TODO: FLUX-4073 upgrading to firebase-functions v2 in backend may help, or upgrading Firebase in general
                 */
                await wait(2_000);
                return await this.attemptReactivation(attempt + 1);
            }

            throw error;
        }
    }
}
