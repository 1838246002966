import {BOMFormat, ColumnDefinition} from "./base";

/**
 * AllPCB BOM format
 * https://www.allpcb.com/components/bill-of-materials.html
 */
export class AllPCBFormat extends BOMFormat {
    static override manufacturer = "AllPCB";
    static override columns: Record<string, ColumnDefinition> = {
        "Item #": (_item, rowNumber) => `${rowNumber}`, // not sure if this should be sequential number or a unique ID
        "Ref Des": (item) => item.designators.join(","),
        Qty: "quantity",
        Manufacturer: "manufacturer",
        "Mfg Part #": "mpn",
        "Description / Value": (item) => item.specification.join(", "),
        Package: "package",
        Type: "mountType",
        "Your Instructions / Notes": "notes",
    };
}
