import {IUserData, IUserPresenceData, IUserPrivateMetadata, UnknownError} from "@buildwithflux/core";
import {ClientFirestoreAdapter, flatObjectToInterface} from "@buildwithflux/firestore-compatibility-layer";
import {ClientIdProvider} from "@buildwithflux/repositories";
import {Logger} from "@buildwithflux/shared";
import type firebase from "firebase/compat/app";
import {chunk} from "lodash";

import {AnalyticsStorage} from "./AnalyticsStorage";
import {BaseStorage} from "./BaseStorage";
import {DeprecatedTrackingEvents} from "./common/DeprecatedTrackingEvents";
import {FluxLogger} from "./connectors/LogConnector";

/**
 * @deprecated Use the UserRepository
 */
export class UserStorage extends BaseStorage {
    constructor(
        firestore: firebase.firestore.Firestore,
        firestoreAdapter: ClientFirestoreAdapter,
        logger: Logger,
        private readonly analyticsStorage: AnalyticsStorage,
        clientIdProvider: ClientIdProvider,
    ) {
        super(firestore, firestoreAdapter, logger, clientIdProvider);
    }

    /**
     * Updates a user with partial data given a uid
     */
    public async updateUser(userUid: string, data: Partial<IUserData>) {
        const updateData = {...data, updated_at: new Date().getTime()};
        const user = await this.userWithUid(userUid);

        if (!user) {
            throw new Error(`Asking to update user but no user found with uid ${userUid}`);
        }

        await user.update(updateData);
    }

    /**
     * Sets a users document and updates the updated_at value while at it. Most importantly
     * this function is called by the Authorization Contet.
     *
     * TODO@philip: This function does a little too much.
     * Setting the username should be explicitly done eleswhere or triggered in a cloud function
     * Setting the AnalyticsStorage user should be explicitly done elsewhere, maybe in the auth redux store?
     */
    public async setUser(user: IUserData) {
        const userUpdated = {...user, updated_at: new Date().getTime()};

        if (!userUpdated.isAnonymous) {
            await this.setUsername(userUpdated);
        }

        this.analyticsStorage.setUser(userUpdated);

        return (
            this.queryUserByHandle(userUpdated.handle)
                ?.set(userUpdated)
                .then(() => {
                    return userUpdated;
                }) || Promise.reject(new Error("Invalid user handle"))
        );
    }

    public async mergeUser(user: AtLeast<IUserData, "handle">) {
        const userUpdated = {...user, updated_at: new Date().getTime()};

        return (
            this.queryUserByHandle(userUpdated.handle)
                ?.set(userUpdated, {merge: true})
                .then(() => {
                    return userUpdated;
                }) || Promise.reject(new Error("Invalid user handle"))
        );
    }

    /**
     * @deprecated use UserPrivateMetadataRepository
     */
    public async setPrivateUserMetadata(privateUserMetadata: AtLeast<IUserPrivateMetadata, "uid">) {
        return (
            this.queryPrivateUserMetadataByUid(privateUserMetadata.uid)
                ?.set(privateUserMetadata, {merge: true})
                .then(() => {
                    return privateUserMetadata;
                }) || Promise.reject(new Error("Invalid uid"))
        );
    }

    /**
     * @deprecated This method causes permission errors to be logged during log out, because it doesn't detect whether the caller is still mounted
     */
    public listenToActiveUsersForDocument(
        documentUid: string,
        recentWindowInMinutes: number,
        callbackSuccess: (userPresences: IUserPresenceData[]) => void,
    ) {
        const baseDocRef = this.firestoreAdapter.document(documentUid);

        const recentWindowInMs = recentWindowInMinutes * 60 * 1000;
        return baseDocRef
            .collection("active_users")
            .where("data.last_seen", ">=", Date.now() - recentWindowInMs)
            .onSnapshot(
                (querySnapshot) => {
                    const result: IUserPresenceData[] = [];

                    querySnapshot.forEach((doc) => {
                        const userPresence = flatObjectToInterface(doc.data().data) as IUserPresenceData;

                        result.push(userPresence);
                    });

                    callbackSuccess(result);
                },
                (error) =>
                    FluxLogger.captureError(new UnknownError(`Error listening to active users: ${error}`, error)),
            );
    }

    public handleExists(handle: string) {
        return this.queryUsernamesByHandle(handle)
            .get()
            .then((result) => {
                // TODO: change to return result.exists; ? Faster than checking for existence based on .data() evaluation?
                return !!result.data();
            });
    }

    // TODO@philip: a version of this function is also defined in src/common/helpers and returns just the IUserData!
    // And this doesn't return the user, which is a document ref, it returns the data and the document ref,
    // which is totally redundant. God help us.
    public getUserByUid(
        userUid: string,
    ): Promise<{userData: IUserData; firebaseDocument: firebase.firestore.DocumentData} | null> {
        return this.queryUserByUid(userUid)
            .get()
            .then((result) => {
                const doc = result.docs[0];
                if (doc) {
                    return {userData: doc.data() as IUserData, firebaseDocument: doc};
                }
                return null;
            });
    }

    public async getUsersByUids(userUids: string[]): Promise<IUserData[]> {
        if (userUids.length === 0)
            return new Promise(() => {
                return [];
            });
        // NOTE: we need to chunk here because firestore only allows 10 in an IN clause
        const snapshots = await Promise.all(
            chunk(userUids, 10).map((uids) => this.users().where("uid", "in", uids).get()),
        );
        return snapshots
            .map((querySnapshot) => querySnapshot.docs.map((doc) => flatObjectToInterface(doc.data()) as IUserData))
            .flat();
    }

    /**
     * @deprecated Use UserRepository.subscribeToUser()
     */
    public listenToUserByUid(
        userUid: string,
        callbackSuccess: (userData: IUserData) => void,
        callbackError?: (error: any) => void,
    ) {
        return this.queryUserByUid(userUid).onSnapshot((querySnapshot) => {
            const user = querySnapshot.docs[0];
            if (user) {
                callbackSuccess(user.data() as IUserData);
            } else {
                callbackError?.("Can't find user");
            }
        });
    }

    public listenToUserByHandle(
        userHandle: string,
        callbackSuccess: (userData: IUserData) => void,
        callbackError?: (error: any) => void,
    ) {
        return (
            this.queryUserByHandle(userHandle)?.onSnapshot((user) => {
                if (user) {
                    callbackSuccess(user.data() as IUserData);
                } else {
                    callbackError?.("Can't find user");
                }
            }) || callbackError?.("Invalid user handle")
        );
    }

    private queryUsernamesByHandle(handle: string) {
        return this.usernames().doc(handle.toLowerCase());
    }

    private queryPrivateUserMetadataByUid(currentUserUid: string) {
        return this.firestoreAdapter.userPrivateMetadata(currentUserUid);
    }

    // TODO@philip: this is called every single time a person logs in! why!? And why do we log it?

    private async setUsername(user: IUserData) {
        void this.analyticsStorage.logEvent(DeprecatedTrackingEvents.setUsername, {
            content_type: "username",
            content_id: user.uid,
            user_name: user.full_name,
            user_handle: user.handle,
            user_isAnonymous: user.isAnonymous,
            user_sign_up_referrer: user.sign_up_referrer,
        });

        return this.queryUsernamesByHandle(user.handle).set({
            user_uid: user.uid,
            userHandle: user.handle,
            organizationUid: undefined,
        });
    }

    // TODO@philip .doc() will not throw an error and it will never return a null result!
    // If the document at that path does not already exist, firebase implicitly creates it!

    private queryUserByHandle(handle: string) {
        let result;
        try {
            result = this.users().doc(handle.toLowerCase());
        } catch (error) {
            FluxLogger.captureError(new UnknownError("Cannot query user by handle (invalid)", error));
        }
        return result;
    }

    private queryUserByUid(userUid: string) {
        return this.users().where("uid", "==", userUid);
    }

    // Private Access to DB
    private usernames = () => this.firestoreAdapter.handleMappingCollection();
    private users = () => this.firestoreAdapter.userCollection();

    private async userWithUid(uid: string) {
        const query = await this.users().where("uid", "==", uid).get();

        if (query.size == 0) {
            return null;
        } else if (query.size == 1) {
            return query.docs[0]!.ref;
        } else {
            FluxLogger.captureError(new Error(`userWithUid found more than one user for uid ${uid}`));
            return query.docs[0]!.ref;
        }
    }
}
