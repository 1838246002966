import {trackingEventOrganizations} from "@buildwithflux/core";

// READ THIS FIRST BEFORE ADDING THINGS HERE!!!

// We are capped by how many events we can have in Amplitude...so we want to
// keep this list short!
// Additonally its easier to browse these events in analytics tools
// if their are organized by surface or object type and NOT by action/feature/etc
export const ObjectTypeTrackingEvents = {
    // SLOW DOWN BEFORE YOU ADD STUFF HERE...THINK HARD! DOES IT REALLY, REALLY BELONG HERE?

    // by object type
    assets: "assets",
    comments: "comments",
    organizations: trackingEventOrganizations,
    users: "users",
    accounts: "accounts",
    projects: "projects",
    components: "components",
    parts: "parts",

    // SLOW DOWN BEFORE YOU ADD STUFF HERE...THINK HARD! DOES IT REALLY, REALLY BELONG HERE?
} as const;
export type ObjectTypeTrackingEvent = (typeof ObjectTypeTrackingEvents)[keyof typeof ObjectTypeTrackingEvents];
