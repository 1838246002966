import {EditorModes} from "@buildwithflux/core";

import {AnalyticsStorage} from "../AnalyticsStorage";
import {DeprecatedTrackingEvent, DeprecatedTrackingEvents} from "../common/DeprecatedTrackingEvents";
import {ObjectTypeTrackingEvents} from "../common/ObjectTypeTrackingEvents";
import {TrackingEvent} from "../common/TrackingEvents";

// Re-type Enum here to help callers minimize imports
export type EditorModeStrings = keyof typeof EditorModes;

export class AnalyticsEventHelper {
    constructor(private readonly analyticsStorage: AnalyticsStorage) {}

    /**
     * @deprecated Use TrackingEventActions.changeHistory.restore instead.
     */
    public revertDocumentVersionEvent() {
        this.analyticsStorage.logEvent(DeprecatedTrackingEvents.revertDocumentVersion, {
            content_type: "document",
        });
    }

    public undoRedoEvent(name: "undo" | "redo") {
        this.analyticsStorage.logEvent(name, {
            content_type: "document",
        });
    }

    public exportEvent(name: TrackingEvent | DeprecatedTrackingEvent) {
        this.analyticsStorage.logEvent(name, {
            content_type: "document",
        });
    }

    public loadedDocumentEvent(documentLoadTime: number) {
        checkTime(documentLoadTime);
        this.analyticsStorage.logEvent(DeprecatedTrackingEvents.loadedDocument, {
            content_type: "document",
            document_load_time: documentLoadTime,
        });
    }

    /**
     * Measures duration from app first render to document content fully
     * rendered in an editor.
     * @see https://analytics.amplitude.com/defygravity/chart/2wujglj
     */
    public loadedEditorEvent(editor: EditorModeStrings, documentLoadTime: number, documentUid: string) {
        checkTime(documentLoadTime);
        this.analyticsStorage.logEvent(DeprecatedTrackingEvents.loadedEditor, {
            editor,
            content_type: "document",
            load_time: documentLoadTime,
            document_uid: documentUid,
        });
    }

    public pinnedCommentThreadEvent(pinned: boolean, threadUid: string) {
        this.analyticsStorage.logEvent(ObjectTypeTrackingEvents.comments, {
            content_type: "comments",
            action: "pin_comment_thread",
            pinned: pinned,
            comment_thread_uid: threadUid,
        });
    }
}

function checkTime(time: number) {
    if (time < 1 || time > 1000 * 60) {
        // eslint-disable-next-line no-console
        console.warn("Performance measurements should be more than zero and less than one minute");
    }
}
