import {compact, get, uniq} from "lodash";

import {BOMFormat, ColumnDefinition} from "./base";

export class FluxFormat extends BOMFormat {
    static override manufacturer = "Flux";

    static override columns: Record<string, ColumnDefinition> = {
        Designator: (item) => item.designators.join(","),
        Quantity: "quantity",
        "Element UIDs": (item) => item.elementUids.join(","),
        "Part UID": "partUid",
        Specification: (item) => item.specification.join(", "),
    };

    public override get columns(): Record<string, ColumnDefinition> {
        const baseColumns = FluxFormat.columns;
        const lineItemColumns = this.lineItems.reduce((cols, lineItem) => {
            Object.values(lineItem.elementProperties).forEach((propertySet) => {
                Object.keys(propertySet).forEach((property) => {
                    cols[property] = (item) => {
                        return uniq(
                            compact(item.elementUids.map((uid) => get(item.elementProperties, [uid, property]))),
                        ).join(",");
                    };
                });
            });

            return cols;
        }, {} as Record<string, ColumnDefinition>);

        return {...baseColumns, ...lineItemColumns};
    }
}
