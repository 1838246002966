export enum DialogType {
    IMPORT_PART = 1,
    PUBLISH_PART,
    PART_UPDATES,
    CREATE_ORGANIZATION,
    ORGANIZATION_ADD_MEMBERS,
    SHADOWBAN,
    USER_PLANS_AND_PAYMENTS,
    FOLLOWERS,
    FOLLOWING,
    IMPORT_DATASHEET_PART,
    NEW_PROJECT,
    CHOOSE_YOUR_PLAN,
    RESTORE_PROJECT,
    JEP30,
    API_KEYS,
    AUTO_LAYOUT_ANNOUNCEMENT,
}
