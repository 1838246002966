import {defaultPropertyDefinitions} from "@buildwithflux/constants";
import {values} from "lodash";

import {BOMFormat, ColumnDefinition} from "./base";

/**
 * Seeed BOM format
 * https://statics3.seeedstudio.com/files/20194/BOM%20Template.xlsx
 */
export class SeeedFormat extends BOMFormat {
    static override manufacturer = "Seeed";

    static override columns: Record<string, ColumnDefinition> = {
        Designator: (item) => item.designators.join(","),
        "Manufacturer Part Number or Seeed SKU": "mpn",
        QTY: "quantity",
        Link: (item) => values(item.elementProperties)[0][defaultPropertyDefinitions.datasheet_url?.label] || "",
    };
}
