/* eslint-disable no-console */
import {
    AnyPcbNode,
    DocumentCollectionChangesByOp,
    getCollectionChangesFromPatchByOp,
    getFirstTopLevelContainerNode,
    PcbBakedNode,
    PcbLayoutRuleCompiler,
    PcbNodesMap,
    PcbNodeTypes,
    PcbRuleSetsMap,
} from "@buildwithflux/core";
import {IDocumentData} from "@buildwithflux/models";
import {areWebWorkersSupported} from "@buildwithflux/shared";
import {DocumentService} from "@buildwithflux/solder-core";
import {AnyAction} from "@reduxjs/toolkit";
import {concat, keyBy, pick, size, values} from "lodash";

// eslint-disable-next-line boundaries/element-types
import {getActiveServicesContainerBadlyAsServiceLocator} from "../../injection/singleton";
import {resetPcbDocumentStateInWebWorker} from "../../modules/pcb_layout_engine/pcbLayoutEngineInMain";
import {FluxLogger} from "../../modules/storage_engine/connectors/LogConnector";
import {usePcbLayerViewStore} from "../../modules/stores/pcb/PcbLayerViewStore";

import {isBatchAction} from "./helpers";

/**
 * Tries to bake the nodes based on the latest document patch. If it fails, it
 * will clear the web worker state and try again with a full reload.
 */
export async function bakeNodesFromLatestPatchWithRetry(action: AnyAction, document: IDocumentData): Promise<void> {
    try {
        await bakeNodesFromLatestPatch(action, document);
    } catch (error) {
        if (error instanceof Error) {
            // See https://buildwithflux.sentry.io/issues/?groupStatsPeriod=auto&project=5669122&query=is%3Aunresolved+issue.priority%3A%5Bhigh%2C+medium%5D+%22Retrying+bake+after+action%22&referrer=issue-list&statsPeriod=90d
            error.message = `Retrying bake after action ${action.type} because of: ${error.message}`;
        }
        FluxLogger.captureError(error);
        const {pcbBakedGoodsManager} = getActiveServicesContainerBadlyAsServiceLocator();
        if (areWebWorkersSupported()) {
            resetPcbDocumentStateInWebWorker();
        }
        await pcbBakedGoodsManager.reload(document, document.pcbLayoutNodes, document.pcbLayoutRuleSets);
    }
}

/**
 * Gets the changed objects out of the latest document patch and calls the
 * appropriate baking method depending on what changed. The baking methods are
 * ordered from most specific to most general, from fast to slow. Only one
 * baking method is called per call of bakeNodesFromLatestPatch to ensure
 * atomicity.
 */
async function bakeNodesFromLatestPatch(action: AnyAction, document: IDocumentData) {
    const latestPatch = document?.latestPatch;
    if (!latestPatch) {
        throw new Error("Missing patch");
    }

    const collectionChanges = getCollectionChangesFromPatchByOp(
        latestPatch,
        "forward",
        // NOTE: Whenever we add or remove a node, the parent node's childUids will be
        // updated, but we do not need to rebake the parent so we ignore those updates.
        ["pcbLayoutNodes", "*", "childUids"],
    );
    const numCollectionChanges = size(collectionChanges);

    if (numCollectionChanges === 0) {
        return;
    }

    const {pcbBakedGoodsManager, documentService} = getActiveServicesContainerBadlyAsServiceLocator();
    // NOTE: these should be all the subcollections that can affect the PCB layout in PcbDocumentState
    const {pcbLayoutNodes, elements, pcbLayoutRuleSets, assets, properties, nets} = collectionChanges;

    // NOTE: need to get any removed nodes from all nodes here because nodes
    // have been deleted from redux TODO: change removeNodes to take IDs
    const allNodes = documentService.snapshot().pcbLayoutNodes;
    const nodesToRemove = pcbLayoutNodes?.remove?.reduce((pcbNodes: AnyPcbNode[], uid: string) => {
        const pcbNode = allNodes[uid];
        if (pcbNode) {
            pcbNodes.push(pcbNode);
        }
        return pcbNodes;
    }, []);

    if (numCollectionChanges === 1 && pcbLayoutRuleSets) {
        // Optimize the common case of rotating sublayout nodes by applying a
        // generated ID rule via keyboard or click. See GestureDragManager.
        const nodesToReplace = getNodesToReplace(document.pcbLayoutRuleSets, allNodes, pcbLayoutRuleSets);
        if (nodesToReplace.length) {
            console.time("FLUX-PERF: baking bakeNodesAndUpdate took");

            await pcbBakedGoodsManager.bakeNodesAndUpdate(
                document,
                document.pcbLayoutNodes,
                document.pcbLayoutRuleSets,
                nodesToReplace,
                // NOTE: assuming the worst here, but in the common case we are
                // rotating a leaf node so it makes no difference
                true,
                true,
                true,
            );
            console.timeEnd("FLUX-PERF: baking bakeNodesAndUpdate took");
            return;
        }
    }

    if (numCollectionChanges === 1 && pcbLayoutNodes) {
        // NOTE: it is important that the add case is before the replace case,
        // because it can handle both. This is common when nets are updated.
        if (pcbLayoutNodes.add) {
            const newNodes = concat(pcbLayoutNodes.add ?? [], pcbLayoutNodes.replace ?? []).reduce(
                (pcbNodes: AnyPcbNode[], uid: string) => {
                    const pcbNode = document.pcbLayoutNodes[uid];
                    if (pcbNode) {
                        pcbNodes.push(pcbNode);
                    }
                    return pcbNodes;
                },
                [],
            );
            console.time("FLUX-PERF: baking bakeNodesAndAdd took");
            await pcbBakedGoodsManager.bakeNodesAndAdd(
                document,
                document.pcbLayoutNodes,
                document.pcbLayoutRuleSets,
                newNodes,
                nodesToRemove,
            );
            // NOTE: needs update in case layout.bakedRules.stackup changes
            maybeUpdateLayerVisibilityMap(newNodes, documentService);
            console.timeEnd("FLUX-PERF: baking bakeNodesAndAdd took");
            return;
        }
        if (pcbLayoutNodes.replace) {
            const {hasInheritedRules, hasInheritedCalculations, requiresDescendantRebake} =
                getBakeNodesAndUpdateFlags(action);
            const updatedNodes = pcbLayoutNodes.replace.reduce((pcbNodes: AnyPcbNode[], uid: string) => {
                const pcbNode = document.pcbLayoutNodes[uid];
                if (pcbNode) {
                    pcbNodes.push(pcbNode);
                }
                return pcbNodes;
            }, []);
            console.time("FLUX-PERF: baking bakeNodesAndUpdate took");

            await pcbBakedGoodsManager.bakeNodesAndUpdate(
                document,
                document.pcbLayoutNodes,
                document.pcbLayoutRuleSets,
                updatedNodes,
                hasInheritedRules,
                hasInheritedCalculations,
                requiresDescendantRebake,
                nodesToRemove,
            );
            // NOTE: needs update in case layout.bakedRules.stackup changes
            maybeUpdateLayerVisibilityMap(updatedNodes, documentService);
            console.timeEnd("FLUX-PERF: baking bakeNodesAndUpdate took");
            return;
        }
        if (pcbLayoutNodes.remove) {
            console.time("FLUX-PERF: baking removeNodes took");
            // NOTE: removeNodes is NOT a promise because it does not rebake, it
            // only removes nodes from the store.
            await pcbBakedGoodsManager.removeNodesAndUpdateFills(
                document,
                document.pcbLayoutNodes,
                document.pcbLayoutRuleSets,
                nodesToRemove ?? [],
            );

            // QUESTION: what to do if layout.bakedRules.stackup is removed?
            console.timeEnd("FLUX-PERF: baking removeNodes took");
            return;
        }
    }

    // NOTE: Adding an element to the schematic will result in pcbLayoutNode
    // and element collection changes. Likewise removing an element.
    if (numCollectionChanges === 2 && elements && pcbLayoutNodes) {
        const newNodes = concat(pcbLayoutNodes.add ?? [], pcbLayoutNodes.replace ?? []).reduce(
            (pcbNodes: AnyPcbNode[], uid: string) => {
                const pcbNode = document.pcbLayoutNodes[uid];
                if (pcbNode) {
                    pcbNodes.push(pcbNode);
                }
                return pcbNodes;
            },
            [],
        );
        if (newNodes.some((node) => node.type === PcbNodeTypes.element)) {
            // NOTE: When we see that any element node is updated, call `bakeNodesAndAdd` to bake and
            // add nodes to document service. This could happen when:
            // - Drag a part onto the schematic board
            // - Remove and re-add layout back while there are parts already in the project
            console.time("FLUX-PERF: baking bakeNodesAndAdd took");
            await pcbBakedGoodsManager.bakeNodesAndAdd(
                document,
                document.pcbLayoutNodes,
                document.pcbLayoutRuleSets,
                newNodes,
                nodesToRemove,
            );
            // NOTE: needs update in case layout.bakedRules.stackup changes
            maybeUpdateLayerVisibilityMap(newNodes, documentService);
            console.timeEnd("FLUX-PERF: baking bakeNodesAndAdd took");
            return;
        }
    }

    // If elements, nodes or rules or other collections have changed in a way
    // that is not handled above, handle it here with a full reload
    if (
        // QUESTION: how can we be more efficient with element changes? they
        // rarely affect the PCB layout... autopositioning, exclude_from_pcb,
        // element properties that are targets of rules...?
        elements ||
        pcbLayoutNodes ||
        pcbLayoutRuleSets ||
        // any property name can be the target of a rule selector, so if any
        // property changes name or value, then we need to rebake all nodes
        // QUESTION: what is an actual use case for document properties
        // affecting the PCB layout?
        properties ||
        // changing an uploaded asset file requires rebaking any node that uses
        // that asset, which means rebaking all nodes
        assets ||
        nets
    ) {
        console.time("FLUX-PERF: baking reload took");
        await pcbBakedGoodsManager.reload(document, document.pcbLayoutNodes, document.pcbLayoutRuleSets);
        // NOTE: needs update in case layout.bakedRules.stackup changes
        maybeUpdateLayerVisibilityMap(document.pcbLayoutNodes, documentService);
        console.timeEnd("FLUX-PERF: baking reload took");
        return;
    }
}

/**
 * Gets the flags needed for efficient baking subtree updates from either normal
 * actions or batch actions that contain those flags. When a batch action has
 * multiple flag values that are in conflict, the true value wins, leading to
 * more conservative baking.
 *
 * IDEA: determine baking optimization flags globally from latestPatch so we
 * don't need the flags in the actions
 */
const getBakeNodesAndUpdateFlags = (
    action: AnyAction,
): {hasInheritedRules: boolean; hasInheritedCalculations: boolean; requiresDescendantRebake: boolean} => {
    const {hasInheritedRules, hasInheritedCalculations, requiresDescendantRebake} = isBatchAction(action)
        ? action.payload.reduce(
              (acc, {payload: cur}) => ({
                  hasInheritedRules: acc.hasInheritedRules || cur.hasInheritedRules,
                  hasInheritedCalculations: acc.hasInheritedCalculations || cur.hasInheritedCalculations,
                  requiresDescendantRebake: acc.requiresDescendantRebake || cur.requiresDescendantRebake,
              }),
              {} as any,
          )
        : action.payload;
    // NOTE: we need a runtime check here because the types are `any`
    if (
        hasInheritedRules === undefined ||
        hasInheritedCalculations === undefined ||
        requiresDescendantRebake === undefined
    ) {
        throw new Error(`Flags must be set for bakeNodesAndUpdate in action ${action.type}`);
    }

    return {hasInheritedRules, hasInheritedCalculations, requiresDescendantRebake};
};

export function getNodeIdsToReplace(ruleSets: PcbRuleSetsMap) {
    return values(ruleSets)
        .map((ruleSet) => PcbLayoutRuleCompiler.getUidAttributeValue(ruleSet.selector))
        .filter((uid): uid is string => !!uid);
}

export function getNodesToReplace(
    pcbLayoutRuleSets: PcbRuleSetsMap,
    pcbLayoutNodes: PcbNodesMap<AnyPcbNode>,
    changedByOp: DocumentCollectionChangesByOp["pcbLayoutRuleSets"],
): AnyPcbNode[] {
    if (!changedByOp) {
        return [];
    }
    const changedRuleSets = pick(
        pcbLayoutRuleSets,
        concat(changedByOp.add ?? [], changedByOp.replace ?? [], changedByOp.remove ?? []),
    );
    const nodeIdsToReplace = getNodeIdsToReplace(changedRuleSets);
    if (size(changedRuleSets) === nodeIdsToReplace.length) {
        return nodeIdsToReplace.reduce((pcbNodes: AnyPcbNode[], uid: string) => {
            const pcbNode = pcbLayoutNodes[uid];
            if (pcbNode) {
                pcbNodes.push(pcbNode);
            }
            return pcbNodes;
        }, []);
    }
    return [];
}

function maybeUpdateLayerVisibilityMap(
    newNodes: AnyPcbNode[] | PcbNodesMap<AnyPcbNode>,
    documentService: DocumentService,
) {
    // NOTE: it is better for perf and UEX that we only check the new or updated nodes
    const activeLayoutNode = getFirstTopLevelContainerNode(Array.isArray(newNodes) ? keyBy(newNodes, "uid") : newNodes);
    if (activeLayoutNode) {
        const activeLayoutBakedNode = documentService.snapshot().pcbLayoutNodes[activeLayoutNode.uid] as
            | PcbBakedNode<PcbNodeTypes.layout>
            | PcbBakedNode<PcbNodeTypes.footprint>;
        if (!activeLayoutBakedNode) {
            throw new Error("updateLayerVisibilityMap should only be called after baking");
        }
        usePcbLayerViewStore.getState().updateLayerVisibilityMap(activeLayoutBakedNode);
    }
}
