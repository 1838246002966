/*
 * Entrypoint for emitting analytics events related to part publishing.
 */

import {AnalyticsStorage} from "../../../../modules/storage_engine/AnalyticsStorage";
import {SurfaceBasedTrackingEvents} from "../../../../modules/storage_engine/common/SurfaceBasedTrackingEvents";

enum PublishingAnalyticsAction {
    partPublished = "part_published",
    partVersionPublished = "part_version_published",
    receivingLatestDrafts = "part_receiving_latest_drafts",
    updatedInProject = "part_updated_in_project",
}

export const emitDocumentPublishedAsPartEvent = (analyticsStorage: AnalyticsStorage) => {
    return analyticsStorage.logEvent(SurfaceBasedTrackingEvents.partPublishing, {
        action: PublishingAnalyticsAction.partPublished,
    });
};

export const emitPartVersionPublishedEvent = (
    versionNotesCharacterCount: number,
    analyticsStorage: AnalyticsStorage,
) => {
    return analyticsStorage.logEvent(SurfaceBasedTrackingEvents.partPublishing, {
        action: PublishingAnalyticsAction.partVersionPublished,
        version_notes_character_count: versionNotesCharacterCount,
    });
};

export const emitReceivingLatestDraftsEvent = (userOwnsPart: boolean, analyticsStorage: AnalyticsStorage) => {
    return analyticsStorage.logEvent(SurfaceBasedTrackingEvents.partPublishing, {
        action: PublishingAnalyticsAction.receivingLatestDrafts,
        user_owns_part: userOwnsPart,
    });
};

export const emitPartUpdatedInProject = (updatedAsPartOfBatchWithSize = 1, analyticsStorage: AnalyticsStorage) => {
    return analyticsStorage.logEvent(SurfaceBasedTrackingEvents.partPublishing, {
        action: PublishingAnalyticsAction.updatedInProject,
        update_was_batch: updatedAsPartOfBatchWithSize > 1,
    });
};
