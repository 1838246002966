import {
    IPcbBoardLayerExtendedArrayPerLayout,
    IPcbBoardLayerExtendedMap,
    IPcbBoardLayerExtendedMapPerLayout,
    LayerOrientation,
    PcbBakedNode,
    PcbBoardLayer,
    PcbBoardLayerMaterials,
    PcbNode,
    PcbNodeTypes,
    devAssert,
} from "@buildwithflux/core";
import {isCopperLayer} from "@buildwithflux/models";
import {produce} from "immer";
import {isEqual} from "lodash";
import {createWithEqualityFn} from "zustand/traditional";

import {getActiveServicesContainerBadlyAsServiceLocator} from "../../../injection/singleton";
import {FluxLogger} from "../../storage_engine/connectors/LogConnector";

import {refEqualityWithLogging} from "./helpers";

// Returns two elements because we have two views of data
function getVisibilityMapBoardLayer(state: IPcbLayerViewStore, layoutUid: string, layerUid: string) {
    const fromObj = state.layerViewConfig?.[layoutUid]?.[layerUid] ?? (null as IPcbBoardLayerExtendedMap | null);
    const fromArray =
        state.layerViewConfigArray?.[layoutUid]?.find((l) => l.uid === layerUid) ??
        (null as IPcbBoardLayerExtendedMap | null);
    return {fromObj, fromArray};
}

type LayoutOrFootprintNode = PcbNode<PcbNodeTypes.layout> | PcbNode<PcbNodeTypes.footprint>;
type BakedLayoutOrFootprintNode = PcbBakedNode<PcbNodeTypes.layout> | PcbBakedNode<PcbNodeTypes.footprint>;

export interface IPcbLayerViewStore {
    documentUid?: string;
    activeLayoutUid?: string;
    layerViewConfig: IPcbBoardLayerExtendedMapPerLayout;
    // Duplicated as array for performance
    layerViewConfigArray: IPcbBoardLayerExtendedArrayPerLayout;
    // NOTE: avoid hooking into focussedLayer. It is too slow for mass updates.
    // See FocusedMaterialInstances.
    focussedLayer: PcbBoardLayer | undefined;
    airwireVisible: boolean;
    labelsVisible: boolean;
    zonesVisible: boolean;
    focussedLayerSticky: boolean;
    initLayerVisibilityMaps: (
        layerViewConfig: IPcbBoardLayerExtendedMapPerLayout | undefined,
        activeLayoutNode: LayoutOrFootprintNode | undefined,
        documentUid: string,
    ) => void;
    updateLayerVisibilityMap: (activeLayoutNode: BakedLayoutOrFootprintNode) => void;
    setFocussedLayer: (focussedLayer: PcbBoardLayer | undefined, sticky?: boolean, obeyStickiness?: boolean) => void;
    setFocussedLayerByMaterial: (
        layers: PcbBoardLayer[],
        material: PcbBoardLayerMaterials,
        orientationOrName: string | LayerOrientation | undefined,
        sticky?: boolean,
        obeyStickiness?: boolean,
    ) => void;
    setAirwireVisibility: (visible: boolean) => void;
    setLabelsVisibility: (visible: boolean) => void;
    setZonesVisibility: (visible: boolean) => void;
    setLayerVisibility: (layerUid: string, isHidden: boolean) => void;
    setCopperFillVisibility: (layerUid: string, isCopperFilled: boolean) => void;
}

const _usePcbLayerViewStore = createWithEqualityFn<IPcbLayerViewStore>()((set, get) => ({
    documentUid: undefined,
    activeLayoutUid: undefined,
    layerViewConfig: {},
    layerViewConfigArray: {},
    focussedLayer: undefined,
    flippedCamera: false,
    airwireVisible: true,
    labelsVisible: true,
    zonesVisible: true,
    focussedLayerSticky: false,

    initLayerVisibilityMaps: (
        initialLayerViewConfig: IPcbBoardLayerExtendedMapPerLayout | undefined,
        initialActiveLayoutNode: LayoutOrFootprintNode | undefined,
        documentUid: string,
    ) => {
        set(
            produce((draftState: IPcbLayerViewStore) => {
                draftState.documentUid = documentUid;
                if (initialActiveLayoutNode) {
                    draftState.activeLayoutUid = initialActiveLayoutNode.uid;
                    draftState.layerViewConfig = {
                        [initialActiveLayoutNode.uid]: {},
                        ...initialLayerViewConfig,
                    };
                    draftState.layerViewConfigArray = {
                        [initialActiveLayoutNode.uid]: [],
                        ...Object.fromEntries(
                            Object.entries(initialLayerViewConfig ?? {}).map(([key, value]) => [
                                key,
                                Object.values(value),
                            ]),
                        ),
                    };
                } else if (initialLayerViewConfig) {
                    draftState.layerViewConfig = initialLayerViewConfig;
                    draftState.layerViewConfigArray = Object.fromEntries(
                        Object.entries(initialLayerViewConfig ?? {}).map(([key, value]) => [key, Object.values(value)]),
                    );
                }
            }),
        );
    },

    /**
     * There are two separate places we keep a list of layers (for two separate
     * purposes): 1) the active layout node's stackup, and 2) the LayerViewStore.
     *
     * These two places store different information about the layers:
     * conceptually the stackup is a {[layerUid]: pcbLayerConfig} and the LVS is
     * a {[layerUid]: layerVisibilityInfo} -- the stackup is about building the
     * pcb, whereas the LVS is about UI visibility.
     */
    updateLayerVisibilityMap: (activeLayoutNode: BakedLayoutOrFootprintNode) => {
        let needsSave = false;
        set(
            produce((draftState: IPcbLayerViewStore) => {
                const stackup = activeLayoutNode.bakedRules.stackup;
                devAssert(stackup, "updating layer visibility without a stackup", FluxLogger);
                if (stackup) {
                    // NOTE: designed to tolerate missing layerViewConfig or keys
                    const layerViewConfig = get().layerViewConfig ?? {};
                    const oldLayers = layerViewConfig[activeLayoutNode.uid] ?? {};
                    const newLayers: IPcbBoardLayerExtendedMap = {};
                    Object.values(stackup).forEach((stackupLayer) => {
                        const oldLayer = oldLayers[stackupLayer.uid];
                        // Keep stored values of `hidden` and `copperFilled` and
                        // refresh the rest. Those are the "extended" keys of
                        // IPcbBoardLayerExtended over the stackup keys.
                        newLayers[stackupLayer.uid] = {
                            ...stackupLayer,
                            hidden: oldLayer ? oldLayer.hidden : false,
                            copperFilled: oldLayer ? oldLayer.copperFilled : false,
                        };
                    });
                    // NOTE: Perf optimization. Do a deep equals here because
                    // baking in a web worker returns new copies of
                    // activeLayoutNode that may have the same value and it is
                    // easier to check here than in selectors downstream. This
                    // optimization saved 100ms in every full bake in a 300
                    // component doc.
                    if (
                        get().activeLayoutUid !== activeLayoutNode.uid ||
                        !isEqual(get().layerViewConfig[activeLayoutNode.uid], newLayers)
                    ) {
                        draftState.activeLayoutUid = activeLayoutNode.uid;
                        draftState.layerViewConfig[activeLayoutNode.uid] = newLayers;
                        draftState.layerViewConfigArray[activeLayoutNode.uid] = Object.values(newLayers);
                        needsSave = true;
                    }
                }
            }),
        );
        if (needsSave) {
            saveLayerViewConfig(get().documentUid, get().layerViewConfig);
        }
    },
    setCopperFillVisibility: (layerUid: string, isCopperFilled: boolean) => {
        set(
            produce((state: IPcbLayerViewStore) => {
                if (!state.activeLayoutUid) return;
                const boardLayer = getVisibilityMapBoardLayer(state, state.activeLayoutUid, layerUid);
                if (boardLayer.fromArray && boardLayer.fromObj) {
                    boardLayer.fromObj.copperFilled = isCopperFilled;
                    boardLayer.fromArray.copperFilled = isCopperFilled;
                }
            }),
        );
        saveLayerViewConfig(get().documentUid, get().layerViewConfig);
    },
    setAirwireVisibility: (visible: boolean) => {
        set(
            produce((state: IPcbLayerViewStore) => {
                state.airwireVisible = visible;
            }),
        );
    },
    setLabelsVisibility: (visible: boolean) => {
        set(
            produce((state: IPcbLayerViewStore) => {
                state.labelsVisible = visible;
            }),
        );
    },
    setZonesVisibility: (visible: boolean) => {
        set(
            produce((state: IPcbLayerViewStore) => {
                state.zonesVisible = visible;
            }),
        );
    },
    setLayerVisibility: (layerUid: string, isHidden: boolean) => {
        set(
            produce((state: IPcbLayerViewStore) => {
                const activeLayoutUid = state.activeLayoutUid;
                if (activeLayoutUid) {
                    const boardLayer = getVisibilityMapBoardLayer(state, activeLayoutUid, layerUid);
                    if (boardLayer.fromArray && boardLayer.fromObj) {
                        boardLayer.fromObj.hidden = isHidden;
                        boardLayer.fromArray.hidden = isHidden;
                    }

                    const updatedFocussedLayer =
                        layerUid === state.focussedLayer?.uid ? undefined : state.focussedLayer;
                    state.focussedLayer = updatedFocussedLayer;
                }
            }),
        );
        saveLayerViewConfig(get().documentUid, get().layerViewConfig);
    },
    setFocussedLayer: (focussedLayer: PcbBoardLayer | undefined, sticky?: boolean, obeyStickiness = false) => {
        set(
            produce((state: IPcbLayerViewStore) => {
                const activeLayoutUid = state.activeLayoutUid;
                if (activeLayoutUid) {
                    if (focussedLayer) {
                        const focusBoardLayer = getVisibilityMapBoardLayer(state, activeLayoutUid, focussedLayer.uid);
                        if (focusBoardLayer.fromArray && focusBoardLayer.fromObj) {
                            focusBoardLayer.fromObj.hidden = false;
                            focusBoardLayer.fromArray.hidden = false;
                        }
                    }

                    if (obeyStickiness ? !state.focussedLayerSticky : true) {
                        state.focussedLayer = focussedLayer;
                    }
                    if (sticky !== undefined) {
                        state.focussedLayerSticky = sticky;
                    }
                }
            }),
        );
    },
    setFocussedLayerByMaterial: (
        layers: PcbBoardLayer[],
        material: PcbBoardLayerMaterials,
        orientationOrName: string | LayerOrientation | undefined,
        sticky?: boolean,
        obeyStickiness = false,
    ) => {
        set(
            produce((state: IPcbLayerViewStore) => {
                const activeLayoutUid = state.activeLayoutUid;
                if (!orientationOrName && (obeyStickiness ? !state.focussedLayerSticky : true)) {
                    state.focussedLayer = undefined;
                }
                if (activeLayoutUid) {
                    const focussedLayer = !orientationOrName
                        ? undefined
                        : layers.find((layer) => {
                              if (material === "Copper" && isCopperLayer(layer) && orientationOrName === layer.name) {
                                  return true;
                              }
                              if (
                                  material === "Copper" &&
                                  isCopperLayer(layer) &&
                                  orientationOrName === layer.orientation
                              ) {
                                  return true;
                              }
                              return layer.orientation === orientationOrName && material === layer.material;
                          });
                    if (focussedLayer && state.layerViewConfig[activeLayoutUid]) {
                        const focusBoardLayer = getVisibilityMapBoardLayer(state, activeLayoutUid, focussedLayer.uid);
                        if (focusBoardLayer.fromArray && focusBoardLayer.fromObj) {
                            focusBoardLayer.fromObj.hidden = false;
                            focusBoardLayer.fromArray.hidden = false;
                        }
                        state.focussedLayer = focussedLayer;
                        if (sticky !== undefined) {
                            state.focussedLayerSticky = sticky;
                        }
                    }
                }
            }),
        );
        saveLayerViewConfig(get().documentUid, get().layerViewConfig);
    },
}));

function saveLayerViewConfig(documentUid: string | undefined, layerViewConfig: IPcbBoardLayerExtendedMapPerLayout) {
    if (!documentUid) {
        throw new Error("Missing documentUid");
    }
    getActiveServicesContainerBadlyAsServiceLocator()
        .documentRepository.saveLayerViewConfig(documentUid, layerViewConfig)
        .catch((error: any) => {
            // TODO what's the right equivalent of captureError() when using thunks+services?
            FluxLogger.captureError(new Error(`Failed to write LVC configs: ${JSON.stringify(error)}`), {
                errorKey: "Failed to write LVC configs",
            });
        });
}

/**
 * Make usePcbLayerViewStore use custom comparison
 * @see https://github.com/pmndrs/zustand/issues/685
 */
// @ts-ignore: because of need for Object.assign below
export const usePcbLayerViewStore: typeof _usePcbLayerViewStore = <K>(
    selector: (state: IPcbLayerViewStore) => K,
    equalityFn?: (a: any, b: any) => boolean,
) => _usePcbLayerViewStore(selector, equalityFn || refEqualityWithLogging(selector));
// For getState and any other special zustand methods attached to function object
Object.assign(usePcbLayerViewStore, _usePcbLayerViewStore);
