import {EditorModes} from "@buildwithflux/core";

export enum Platform {
    all,
    mac,
    nonMac,
    windows,
    nonWindows,
    linux,
    nonLinux,
    iOS,
    nonIOS,
    android,
    nonAndroid,
}

// TODO: make this type stricter than [key: string]
// False alarm
// eslint-disable-next-line import/no-unused-modules
export interface IKeyCommands {
    [key: string]: IKeyCommand;
}

const keyCommandsKeys = [
    // General Commands
    "new_document",
    "clone_document",
    "publish_document_as_part",
    "insert_comment",
    "ask_copilot",
    "browser_window_zoom_in",
    "browser_window_zoom_out",
    "cancel",
    "close_comment_thread_view",
    "open_hotkey_viewer",

    // View
    "toggle_ui_visibility",
    "toggle_collaborative_cursors_visibility",
    "toggle_comments_visibility",
    "toggle_pin_label_visibility",
    "toggle_pcb_snapping",
    "toggle_pcb_measurements",
    "measurements_diagonal_rectangular",
    "toggle_problems_comments_drawer",
    "toggle_layer_view_controller",
    "flip_viewport",

    // Edit
    "undo",
    "redo",
    "delete",
    "copy",
    "copy_properties",
    "copy_layout_rules",
    "cut",
    "paste",
    "rename_subject",
    "edit_part",
    "convert_to_part",
    "store_part_hotkey",
    "insert_part_hotkey",
    "disable_pcb_snapping",
    "lock_subject",

    // Navigation
    "navigate_left",
    "navigate_up",
    "navigate_right",
    "navigate_down",
    "pan_canvas",
    "zoom_in",
    "zoom_out",
    "zoomToFit",
    "store_viewport_position",
    "recall_viewport_position",
    "search",

    // Selection
    "select_all",
    "unselect_all",
    "multi_select",
    "deep_select",
    "select_entire_wire",

    // Transform
    "nudge_left",
    "nudge_up",
    "nudge_right",
    "nudge_down",
    "minor_nudge_left",
    "minor_nudge_up",
    "minor_nudge_right",
    "minor_nudge_down",
    "rotate_clockwise",
    "rotate_counter_clockwise",
    "rotate_clockwise_individually",
    "rotate_counter_clockwise_individually",
    "flip",
    "flip_individually",
    "flip_layer",
    "measure_distance",

    // Arrange
    "align_elements_hleft",
    "align_elements_hright",
    "align_elements_hcenter",
    "align_elements_vtop",
    "align_elements_vbottom",
    "align_elements_vcenter",
    "space_elements",
    "space_evenly_h",
    "space_evenly_v",

    // Connect on PCB
    "start_trace",
    "trace_elbow",
    "end_trace",
    "flip_route",
    "cycle_trace_width",
    "counter_cycle_trace_width",
    "toggle_free_routing",
    "toggle_multi_routing",
    "disconnect_drag_trace",
    "add_via",

    // Connect on Schematic
    "draw_wire",
    "wire_elbow",
    "flip_wire",
    "finish_wire",
    // TODO deprecated. May be reinstated when we implement better auto wiring.
    // "solve_routing",

    // Internal
    "context_menu",
    "toggle_perf_stats_viewer",
    "switch_to_prod",
    "switch_to_release",
    "switch_to_localhost",
    "switch_to_main",
    "jump_to_sentry",
    "focus_layer",
    "toggle_vis_layer",
] as const;

export interface IKeyCommand {
    key: string;
    section: string;
    description: string;
    key_name?: string | IHotKeyName[];
    arpeggio_rest?: HotKeyAlternatives;
    keys: HotKeyAlternatives;
    customLabel?: string;
    supportedEditorModes?: EditorModes[];
    internal?: boolean;
}

interface IComplexHotKey {
    platform?: Platform;
    key: string;
    isKeyCodeOnly?: boolean;
}

export type HotKey = IComplexHotKey | string;

export type HotKeyAlternatives = HotKey[] | string;

export interface IHotKeyName {
    platform: Platform;
    name: string;
}

// Flux key command guidelines https://coda.io/d/Keyboard-Shortcuts-Mouse-Trackpad-Input_dh_k1-tXF7O/Fluxs-Shortcuts_suUHp#Flux-Keyboard-Shortcuts_tu8A9/r2
// See Chrome reference: https://support.google.com/chrome/answer/157179
const keyCommands: Record<(typeof keyCommandsKeys)[number], Readonly<IKeyCommand>> = {
    // General Commands
    new_document: {
        key: "new_document",
        section: "General",
        description: "Create New Project",
        keys: [
            {key: "alt+n", platform: Platform.nonMac},
            {key: "alt+n", platform: Platform.mac},
        ],
    },
    clone_document: {
        key: "clone_document",
        section: "General",
        description: "Clone Current Project",
        keys: [
            {key: "alt+shift+n", platform: Platform.nonMac},
            {key: "alt+shift+n", platform: Platform.mac},
        ],
    },
    publish_document_as_part: {
        key: "publish_document_as_part",
        section: "General",
        description: "Publish this Project as a Component",
        keys: [
            {key: "ctrl+p", platform: Platform.nonMac},
            {key: "meta+p", platform: Platform.mac},
        ],
    },
    insert_comment: {
        key: "insert_comment",
        section: "General",
        description: "Insert Comment",
        keys: ["c"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    ask_copilot: {
        key: "ask_copilot",
        section: "General",
        description: "Ask Copilot",
        keys: ["alt+c"],
        supportedEditorModes: [EditorModes.schematic],
    },
    browser_window_zoom_in: {
        key: "browser_window_zoom_in",
        section: "General",
        description: "Browser Zoom In",
        key_name: [
            {name: "CTRL + \\+", platform: Platform.nonMac},
            {name: "⌘ + \\+", platform: Platform.mac},
        ],
        keys: [
            {key: "ctrl+=", platform: Platform.nonMac},
            {key: "meta+=", platform: Platform.mac},
        ],
    },
    browser_window_zoom_out: {
        key: "browser_window_zoom_out",
        section: "General",
        description: "Browser Zoom Out",
        keys: [
            {key: "ctrl+-", platform: Platform.nonMac},
            {key: "meta+-", platform: Platform.mac},
        ],
    },
    cancel: {
        key: "cancel",
        section: "General",
        description: "Cancel Current Action",
        keys: ["Escape"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    close_comment_thread_view: {
        key: "close_comment_thread_view",
        section: "General",
        description: "Close Comments",
        keys: ["escape"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    open_hotkey_viewer: {
        key: "open_hotkey_viewer",
        section: "General",
        description: "Open Keyboard Shortcuts",
        keys: [
            {key: "meta+/", platform: Platform.mac},
            {key: "ctrl+/", platform: Platform.nonMac},
        ],
    },
    // View
    toggle_ui_visibility: {
        key: "toggle_ui_visibility",
        section: "View",
        description: "Show/Hide UI",
        keys: [
            {key: "ctrl+\\", platform: Platform.nonMac},
            {key: "meta+\\", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb, EditorModes.code],
    },
    toggle_collaborative_cursors_visibility: {
        key: "toggle_collaborative_cursors_visibility",
        section: "View",
        description: "Show/Hide Collaborative Cursors",
        keys: [
            {key: "ctrl+shift+\\", platform: Platform.nonMac},
            {key: "meta+shift+\\", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    toggle_comments_visibility: {
        key: "toggle_comments_visibility",
        section: "View",
        description: "Show/Hide Comments",
        keys: ["shift+c"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    toggle_pin_label_visibility: {
        key: "toggle_pin_label_visibility",
        section: "View",
        description: "Show/Hide Pin Labels",
        keys: ["shift+p"],
        supportedEditorModes: [EditorModes.schematic],
    },
    toggle_pcb_measurements: {
        key: "toggle_pcb_measurements",
        section: "View",
        description: "Enable/Disable Measurements on PCB",
        keys: ["shift+m"],
        supportedEditorModes: [EditorModes.pcb],
    },
    measurements_diagonal_rectangular: {
        key: "measurements_diagonal_rectangular",
        section: "View",
        description: "Show Measurements Separately On X and Y Axes",
        keys: [
            {key: "shift", platform: Platform.nonMac, isKeyCodeOnly: true},
            {key: "shift", platform: Platform.mac, isKeyCodeOnly: true},
        ],
        supportedEditorModes: [EditorModes.pcb],
    },
    toggle_pcb_snapping: {
        key: "toggle_pcb_snapping",
        section: "View",
        description: "Enable/Disable Snap to Alignment on PCB",
        keys: ["shift+a"],
        supportedEditorModes: [EditorModes.pcb],
    },
    disable_pcb_snapping: {
        key: "disable_pcb_snapping",
        section: "View",
        description: "Temporarily Disable Snap to Alignment on PCB",
        keys: ["a"],
        supportedEditorModes: [EditorModes.pcb],
    },
    toggle_problems_comments_drawer: {
        key: "toggle_problems_comments_drawer",
        section: "View",
        description: "Open/Close the Feedback Drawer",
        keys: [
            {key: "alt+f", platform: Platform.nonMac},
            {key: "alt+f", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    toggle_layer_view_controller: {
        key: "toggle_layer_view_controller",
        section: "View",
        description: "Open/Close the Layer List",
        keys: [
            {key: "alt+l", platform: Platform.mac},
            {key: "alt+l", platform: Platform.nonMac},
        ],
        supportedEditorModes: [EditorModes.pcb],
    },
    flip_viewport: {
        key: "flip_viewport",
        section: "View",
        description: "Flip Viewport to Top/Bottom",
        keys: [
            {key: "alt+shift+f", platform: Platform.nonMac},
            {key: "alt+shift+f", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.pcb],
    },

    // Edit
    undo: {
        key: "undo",
        section: "Edit",
        description: "Undo",
        keys: [
            {key: "ctrl+z", platform: Platform.nonMac},
            {key: "meta+z", platform: Platform.mac},
        ],
    },
    redo: {
        key: "redo",
        section: "Edit",
        description: "Redo",
        keys: [
            {key: "ctrl+shift+z", platform: Platform.nonMac},
            {key: "meta+shift+z", platform: Platform.mac},
        ],
    },
    delete: {
        key: "delete",
        section: "Edit",
        description: "Delete or Enable/Disable",
        keys: ["Backspace", "Delete"],
    },
    copy: {
        key: "copy",
        section: "Edit",
        description: "Copy",
        keys: [
            {key: "ctrl+c", platform: Platform.nonMac},
            {key: "meta+c", platform: Platform.mac},
        ],
    },
    copy_properties: {
        key: "copy_properties",
        section: "Edit",
        description: "Copy Properties",
        keys: [
            {key: "ctrl+alt+c", platform: Platform.nonMac},
            {key: "alt+meta+c", platform: Platform.mac},
        ],
    },
    copy_layout_rules: {
        key: "copy_layout_rules",
        section: "Edit",
        description: "Copy Layout Rules",
        keys: [
            {key: "ctrl+shift+c", platform: Platform.nonMac},
            {key: "shift+meta+c", platform: Platform.mac},
        ],
    },
    cut: {
        key: "cut",
        section: "Edit",
        description: "Cut",
        keys: [
            {key: "ctrl+x", platform: Platform.nonMac},
            {key: "meta+x", platform: Platform.mac},
        ],
    },
    paste: {
        key: "paste",
        section: "Edit",
        description: "Paste",
        keys: [
            {key: "ctrl+v", platform: Platform.nonMac},
            {key: "meta+v", platform: Platform.mac},
        ],
    },
    rename_subject: {
        key: "rename_subject",
        section: "Edit",
        description: "Rename",
        keys: [
            {key: "ctrl+enter", platform: Platform.nonMac},
            {key: "meta+enter", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    lock_subject: {
        key: "lock_subject",
        section: "Edit",
        description: "Lock or Unlock Selection",
        keys: ["shift+l"],
    },
    edit_part: {
        key: "edit_part",
        section: "Edit",
        description: "Edit Component",
        keys: [
            {key: "ctrl+e", platform: Platform.nonMac},
            {key: "meta+e", platform: Platform.mac},
        ],
    },
    convert_to_part: {
        key: "convert_to_part",
        section: "Edit",
        description: "Publish Selection as Component",
        keys: [
            {key: "ctrl+shift+p", platform: Platform.nonMac},
            {key: "meta+shift+p", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.schematic],
    },
    store_part_hotkey: {
        key: "store_part_hotkey",
        section: "Edit",
        description: "Assign Component to Hotkey",
        key_name: [
            {name: "⇧ + 1 - 5", platform: Platform.mac},
            {name: "SHIFT + 1 - 5", platform: Platform.nonMac},
        ],
        keys: [
            {key: "shift+1", platform: Platform.mac},
            {key: "shift+2", platform: Platform.mac},
            {key: "shift+3", platform: Platform.mac},
            {key: "shift+4", platform: Platform.mac},
            {key: "shift+5", platform: Platform.mac},
            {key: "shift+1", platform: Platform.nonMac},
            {key: "shift+2", platform: Platform.nonMac},
            {key: "shift+3", platform: Platform.nonMac},
            {key: "shift+4", platform: Platform.nonMac},
            {key: "shift+5", platform: Platform.nonMac},
        ],
        supportedEditorModes: [EditorModes.schematic],
    },
    insert_part_hotkey: {
        key: "insert_part_hotkey",
        section: "Edit",
        description: "Insert Component from Hotkey",
        key_name: "1 - 5",
        keys: ["1", "2", "3", "4", "5"],
        supportedEditorModes: [EditorModes.schematic],
    },

    // Navigation
    navigate_left: {
        key: "navigate_left",
        section: "Navigation",
        description: "Navigate Left",
        keys: ["left"],
    },
    navigate_up: {
        key: "navigate_up",
        section: "Navigation",
        description: "Navigate Up",
        keys: ["up"],
    },
    navigate_right: {
        key: "navigate_right",
        section: "Navigation",
        description: "Navigate Right",
        keys: ["right"],
    },
    navigate_down: {
        key: "navigate_down",
        section: "Navigation",
        description: "Navigate Down",
        keys: ["down"],
    },
    pan_canvas: {
        key: "pan_canvas",
        section: "Navigation",
        description: "Pan",
        key_name: "Spacebar + Drag",
        keys: ["space"],
    },
    zoom_in: {
        key: "zoom_in",
        section: "Navigation",
        description: "Zoom In",
        key_name: "\\+",
        keys: [
            {key: "=", platform: Platform.all},
            {key: "NumpadAdd", platform: Platform.all},
        ],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    zoom_out: {
        key: "zoom_out",
        section: "Navigation",
        description: "Zoom Out",
        key_name: "-",
        keys: [
            {key: "Minus", platform: Platform.nonMac},
            {key: "NumpadSubtract", platform: Platform.mac},
            {key: "-", platform: Platform.all},
        ],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    zoomToFit: {
        key: "zoomToFit",
        section: "Navigation",
        description: "Zoom to Fit All or Selection",
        key_name: "0",
        keys: ["0"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    store_viewport_position: {
        key: "store_viewport_position",
        section: "Navigation",
        description: "Assign Current Viewport Position to Hotkey",
        key_name: [
            {name: "⇧ + 6 - 9", platform: Platform.mac},
            {name: "SHIFT + 6 - 9", platform: Platform.nonMac},
        ],
        keys: ["shift+6", "shift+7", "shift+8", "shift+9"],
        supportedEditorModes: [EditorModes.schematic],
    },
    recall_viewport_position: {
        key: "recall_viewport_position",
        section: "Navigation",
        description: "Recall Saved Viewport Position",
        key_name: "6 - 9",
        keys: ["6", "7", "8", "9"],
        supportedEditorModes: [EditorModes.schematic],
    },
    search: {
        key: "search",
        section: "Navigation",
        description: "Find Component",
        keys: [
            {key: "ctrl+f", platform: Platform.nonMac},
            {key: "meta+f", platform: Platform.mac},
        ],
    },

    // Selection
    select_all: {
        key: "select_all",
        section: "Selection",
        description: "Select All",
        keys: [
            {key: "ctrl+a", platform: Platform.nonMac},
            {key: "meta+a", platform: Platform.mac},
        ],
    },
    unselect_all: {
        key: "unselect_all",
        section: "Selection",
        description: "Deselect All",
        keys: [
            {key: "escape", platform: Platform.all},
            {key: "ctrl+shift+a", platform: Platform.nonMac},
            {key: "meta+shift+a", platform: Platform.mac},
        ],
    },
    multi_select: {
        key: "multi_select",
        section: "Selection",
        description: "Multi Select",
        keys: ["shift"],
        supportedEditorModes: [EditorModes.pcb, EditorModes.schematic],
    },
    deep_select: {
        key: "deep_select",
        section: "Selection",
        description: "Deep Select",
        keys: [
            {key: "ctrl", platform: Platform.nonMac},
            {key: "meta", platform: Platform.mac},
            {key: "ctrl", platform: Platform.nonMac, isKeyCodeOnly: true},
            {key: "meta", platform: Platform.mac, isKeyCodeOnly: true},
        ],
        supportedEditorModes: [EditorModes.pcb],
    },
    select_entire_wire: {
        key: "select_entire_wire",
        section: "Selection",
        description: "Select entire wire",
        keys: [
            {key: "ctrl", platform: Platform.nonMac, isKeyCodeOnly: true},
            {key: "meta", platform: Platform.mac, isKeyCodeOnly: true},
        ],
        supportedEditorModes: [EditorModes.schematic],
    },

    // Transform
    nudge_left: {
        key: "nudge_left",
        section: "Transform",
        description: "Nudge Left",
        keys: ["arrowleft"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    nudge_up: {
        key: "nudge_up",
        section: "Transform",
        description: "Nudge Up",
        keys: ["arrowup"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    nudge_right: {
        key: "nudge_right",
        section: "Transform",
        description: "Nudge Right",
        keys: ["arrowright"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    nudge_down: {
        key: "nudge_down",
        section: "Transform",
        description: "Nudge Down",
        keys: ["arrowdown"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    minor_nudge_left: {
        key: "minor_nudge_left",
        section: "Transform",
        description: "Nudge Minor Step Left",
        keys: ["shift+arrowleft"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    minor_nudge_up: {
        key: "minor_nudge_up",
        section: "Transform",
        description: "Nudge Minor Step Up",
        keys: ["shift+arrowup"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    minor_nudge_right: {
        key: "minor_nudge_right",
        section: "Transform",
        description: "Nudge Minor Step Right",
        keys: ["shift+arrowright"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    minor_nudge_down: {
        key: "minor_nudge_down",
        section: "Transform",
        description: "Nudge Minor Step Down",
        keys: ["shift+arrowdown"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    rotate_clockwise: {
        key: "rotate_clockwise",
        section: "Transform",
        description: "Rotate Right",
        keys: ["]"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    rotate_counter_clockwise: {
        key: "rotate_counter_clockwise",
        section: "Transform",
        description: "Rotate Left",
        keys: ["["],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    rotate_clockwise_individually: {
        key: "rotate_clockwise_individually",
        section: "Transform",
        description: "Rotate Right Individually",
        key_name: [
            {name: "SHIFT + ]", platform: Platform.nonMac},
            {name: "⇧ + ]", platform: Platform.mac},
        ],
        keys: ["shift+BracketRight"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    rotate_counter_clockwise_individually: {
        key: "rotate_counter_clockwise_individually",
        section: "Transform",
        description: "Rotate Left Individually",
        key_name: [
            {name: "SHIFT + [", platform: Platform.nonMac},
            {name: "⇧ + [", platform: Platform.mac},
        ],
        keys: ["shift+BracketLeft"],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    flip: {
        key: "flip",
        section: "Transform",
        description: "Flip Horizontally",
        keys: ["f"],
        supportedEditorModes: [EditorModes.schematic],
    },
    flip_individually: {
        key: "flip_individually",
        section: "Transform",
        description: "Flip Horizontally Individually",
        keys: ["shift+f"],
        supportedEditorModes: [EditorModes.schematic],
    },
    flip_layer: {
        key: "flip_layer",
        section: "Transform",
        description: "Flip Layer",
        keys: ["f"],
        supportedEditorModes: [EditorModes.pcb],
    },
    measure_distance: {
        key: "measure_distance",
        section: "Transform",
        description: "Measure Distance",
        keys: ["m"],
        supportedEditorModes: [EditorModes.pcb],
    },

    // Arrange
    align_elements_hleft: {
        key: "align_elements_hleft",
        section: "Arrange",
        description: "Align Left",
        keys: [
            {key: "alt+a", platform: Platform.nonMac},
            {key: "alt+a", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    align_elements_hright: {
        key: "align_elements_hright",
        section: "Arrange",
        description: "Align Right",
        keys: [
            {key: "alt+d", platform: Platform.nonMac},
            {key: "alt+d", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    align_elements_hcenter: {
        key: "align_elements_hcenter",
        section: "Arrange",
        description: "Align Horizontal Center",
        keys: [
            {key: "alt+h", platform: Platform.nonMac},
            {key: "alt+h", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    align_elements_vtop: {
        key: "align_elements_vtop",
        section: "Arrange",
        description: "Align Top",
        keys: [
            {key: "alt+w", platform: Platform.nonMac},
            {key: "alt+w", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    align_elements_vbottom: {
        key: "align_elements_vbottom",
        section: "Arrange",
        description: "Align Bottom",
        keys: [
            {key: "alt+s", platform: Platform.nonMac},
            {key: "alt+s", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    align_elements_vcenter: {
        key: "align_elements_vcenter",
        section: "Arrange",
        description: "Align Vertical Center",
        keys: [
            {key: "alt+v", platform: Platform.nonMac},
            {key: "alt+v", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.schematic, EditorModes.pcb],
    },
    space_elements: {
        key: "space_elements",
        section: "Arrange",
        description: "Space Evenly",
        keys: [
            {key: "s", platform: Platform.nonMac},
            {key: "s", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.schematic],
    },
    space_evenly_h: {
        key: "space_evenly_h",
        section: "Arrange",
        description: "Space Evenly Horizontally",
        keys: [
            {key: "alt+shift+h", platform: Platform.nonMac},
            {key: "alt+shift+h", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.pcb],
    },
    space_evenly_v: {
        key: "space_evenly_v",
        section: "Arrange",
        description: "Space Evenly Vertically",
        keys: [
            {key: "alt+shift+v", platform: Platform.nonMac},
            {key: "alt+shift+v", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.pcb],
    },

    // Connect on PCB
    start_trace: {
        key: "start_trace",
        section: "Connect on PCB",
        description: "Start Drawing a Trace",
        customLabel: "`Click` the White dot on a Pad or the end of a Trace",
        keys: "mouse",
        supportedEditorModes: [EditorModes.pcb],
    },
    trace_elbow: {
        key: "trace_elbow",
        section: "Connect on PCB",
        description: "Create Trace Elbow",
        customLabel: "`Click`",
        keys: "mouse",
        supportedEditorModes: [EditorModes.pcb],
    },
    end_trace: {
        key: "end_trace",
        section: "Connect on PCB",
        description: "End Drawing a Trace",
        customLabel: "`Click` the white dot on a Pad or a Trace or `ESC`",
        keys: "mouse",
        supportedEditorModes: [EditorModes.pcb],
    },
    flip_route: {
        key: "flip_route",
        section: "Connect on PCB",
        description: "Flip Trace Elbow Direction",
        keys: ["f"],
        supportedEditorModes: [EditorModes.pcb],
    },
    cycle_trace_width: {
        key: "cycle_trace_width",
        section: "Connect on PCB",
        description: "Cycle Through Preferred Trace Widths",
        keys: ["w"],
        supportedEditorModes: [EditorModes.pcb],
    },
    counter_cycle_trace_width: {
        key: "counter_cycle_trace_width",
        section: "Connect on PCB",
        description: "Cycle Backwards Through Preferred Trace Widths",
        keys: ["shift+w"],
        supportedEditorModes: [EditorModes.pcb],
    },
    toggle_free_routing: {
        key: "toggle_free_routing",
        section: "Connect on PCB",
        description: "Draw or Drag Traces at Any Angle",
        keys: [
            {key: "shift", platform: Platform.nonMac, isKeyCodeOnly: true},
            {key: "shift", platform: Platform.mac, isKeyCodeOnly: true},
        ],
        supportedEditorModes: [EditorModes.pcb],
    },
    toggle_multi_routing: {
        key: "toggle_multi_routing",
        section: "Connect on PCB",
        description: "Toggle Multi Routing Mode when available",
        keys: [
            {key: "ctrl", platform: Platform.nonMac, isKeyCodeOnly: true},
            {key: "meta", platform: Platform.mac, isKeyCodeOnly: true},
        ],
        supportedEditorModes: [EditorModes.pcb],
    },
    disconnect_drag_trace: {
        key: "disconnect_drag_trace",
        section: "Connect on PCB",
        description: "Drag Traces/Vias Without Keeping Them Connected",
        keys: [
            {key: "ctrl", platform: Platform.nonMac},
            {key: "meta", platform: Platform.mac},
        ],
        supportedEditorModes: [EditorModes.pcb],
    },
    add_via: {
        key: "add_via",
        section: "Connect on PCB",
        description: "Draw Trace on Next Layer with a Via",
        keys: ["v"],
        supportedEditorModes: [EditorModes.pcb],
    },

    // Connect on Schematic
    draw_wire: {
        key: "draw_wire",
        section: "Connect on Schematic",
        description: "Start Drawing a Wire",
        customLabel: "`Double Click` the Canvas or `Click` a Terminal",
        keys: "mouse",
        supportedEditorModes: [EditorModes.schematic],
    },
    wire_elbow: {
        key: "wire_elbow",
        section: "Connect on Schematic",
        description: "Create Wire Elbow",
        customLabel: "`Click` While Drawing",
        keys: "mouse",
        supportedEditorModes: [EditorModes.schematic],
    },
    flip_wire: {
        key: "flip_wire",
        section: "Connect on Schematic",
        description: "Flip Wire Elbow Direction",
        keys: ["f"],
        supportedEditorModes: [EditorModes.schematic],
    },
    finish_wire: {
        key: "finish_wire",
        section: "Connect on Schematic",
        description: "Finish Drawing a Wire",
        customLabel: "`Double Click` the Canvas or `Click` a Teminal or `ESC`",
        keys: "mouse",
        supportedEditorModes: [EditorModes.schematic],
    },
    // TODO deprecated. May be reinstated when we implement better auto wiring.
    // solve_routing: {
    //     key: "solve_routing",
    //     section: "Connect on Schematic",
    //     description: "Auto Connect",
    //     keys: [
    //         {key: "shift+d", platform: Platform.nonMac},
    //         {key: "shift+d", platform: Platform.mac},
    //     ],
    //     supportedEditorModes: [EditorModes.schematic],
    // },

    // Internal
    context_menu: {
        key: "context_menu",
        internal: true,
        section: "",
        description: "Open context menu",
        keys: [
            {key: "ctrl", platform: Platform.mac},
            {key: "ctrl", platform: Platform.mac, isKeyCodeOnly: true},
        ],
        supportedEditorModes: [EditorModes.pcb],
    },
    toggle_perf_stats_viewer: {
        key: "toggle_perf_stats_viewer",
        internal: true,
        section: "", // empty because hidden feature
        description: "Toggle performance stats viewer",
        keys: ["ctrl+alt+s"],
        supportedEditorModes: [EditorModes.pcb],
    },
    switch_to_prod: {
        key: "switch_to_prod",
        internal: true,
        description: "Switch to www.flux.ai",
        keys: ["ctrl+alt+p"],
        section: "",
    },
    switch_to_release: {
        key: "switch_to_release",
        internal: true,
        description: "Switch to rc.flux.ai",
        keys: ["ctrl+alt+r"],
        section: "",
    },
    switch_to_localhost: {
        key: "switch_to_localhost",
        internal: true,
        description: "Switch to localhost:3000",
        keys: ["ctrl+alt+l"],
        section: "",
    },
    switch_to_main: {
        key: "switch_to_main",
        internal: true,
        description: "Switch to edge.flux.ai",
        keys: ["ctrl+alt+m"],
        section: "",
    },
    jump_to_sentry: {
        key: "jump_to_sentry",
        internal: true,
        description: "Jump to the logs for this project in Sentry",
        keys: ["ctrl+alt+j"],
        section: "",
    },
    focus_layer: {
        key: "focus_layer",
        section: "Navigation",
        description: "Focus a PCB layer",
        arpeggio_rest: "(Shift)+D/L/A/O/P/M/Layer Number",
        keys: ["l"],
        supportedEditorModes: [EditorModes.pcb],
    },
    toggle_vis_layer: {
        key: "toggle_vis_layer",
        section: "Navigation",
        description: "Toggle visibility of PCB layer",
        arpeggio_rest: "(Shift)+D/L/A/O/P/M/Layer Number",
        keys: ["h"],
        supportedEditorModes: [EditorModes.pcb],
    },
};

export default keyCommands;

export function generateLayersArpeggios(nMidLayers: number) {
    return [
        {key: "escape", layerName: null},
        {key: "d", layerName: "Metadata"},
        {key: "l", layerName: "Labels"},
        {key: "a", layerName: "Airwires"},
        {key: "z", layerName: "Zones"},
        {key: "o", layerName: "Top Overlay"},
        {key: "p", layerName: "Top Solder Paste"},
        {key: "m", layerName: "Top Solder Mask"},
        {key: "shift+m", layerName: "Bottom Solder Mask"},
        {key: "shift+p", layerName: "Bottom Solder Paste"},
        {key: "shift+o", layerName: "Bottom Overlay"},
        {key: "1", layerName: "Top Copper"},
        {key: (nMidLayers + 2).toString(), layerName: "Bottom Copper"},
        ...new Array(nMidLayers)
            .fill(0)
            .map((_, index) => ({key: (index + 2).toString(), layerName: `Mid-Layer ${index + 1}`})),
    ];
}
