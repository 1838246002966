// Only comment out the below line when you want to monitor re-rendering for
// perf purpose.
// import "./wdyr";

import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/styles";
import ReactDOM from "react-dom/client";
import {Provider} from "react-redux";

import App from "./App";
import "./index.css";
import IntercomProvider from "./components/common/components/intercom/IntercomProvider";
import {FluxServicesContext} from "./injection/hooks";
import {getActiveServicesContainerSafelyFromRoot} from "./injection/singleton";
import {FluxLogger} from "./modules/storage_engine/connectors/LogConnector";
import {getThemeConfig} from "./resources/Theme";
import store from "./store";

// @ts-ignore
window.prerenderReady = false;

// The logger has to be initialized before the app fires up to catch all error
// events and perf traces
FluxLogger.initialize();

// Please keep your code out of here unless it truly need to run on ALL pages!

const theme = createMuiTheme(getThemeConfig(true));

ReactDOM.createRoot(document.getElementById("root")!).render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <FluxServicesContext.Provider value={getActiveServicesContainerSafelyFromRoot()}>
                <IntercomProvider>
                    <App />
                </IntercomProvider>
            </FluxServicesContext.Provider>
        </Provider>
    </ThemeProvider>,
);
export {useStore} from "./redux/util/helpers";
