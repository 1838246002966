import {
    EnterpriseFullyDecoratedOrganizationMember,
    FullyDecoratedOrganizationMember,
    isEnterpriseFullyDecoratedOrganizationMember,
    isNonEnterpriseFullyDecoratedOrganizationMember,
    NonEnterpriseFullyDecoratedOrganizationMember,
    SubscriptionStatus,
} from "@buildwithflux/models";
import {
    EnterpriseEntitlementContext,
    EntitlementContext,
    EntitlementResult,
    OrganizationEntitlementContext,
    PlanConfig,
    StandardEntitlementKey,
    UserEntitlementContext,
} from "@buildwithflux/plans";
import {Logger, silentLogger} from "@buildwithflux/shared";

import {useFluxServices} from "../../../injection/hooks";
import {useCurrentUser, useCurrentUserUid} from "../../auth/state/currentUser";
import {useCurrentPageOrganizationContextMembership} from "../../auth/state/organizationContext";
import {useUserPrivateMetadata} from "../../auth/state/user";

/**
 * Produces an entitlement context dependent on the current page context
 *
 * i.e. if the project being edited is owned by an organization, the entitlement context will be for the current user *and*
 *  that organization
 */
function useEntitlementContext(): EntitlementContext {
    return useSpecificEntitlementContext(useCurrentPageOrganizationContextMembership());
}

/**
 * Produces an entitlement context for the current user alone, or for a specific organization/enterprise membership
 */
function useSpecificEntitlementContext(
    forOrganizationMembership: FullyDecoratedOrganizationMember | undefined,
): EntitlementContext {
    const currentUserContext = useEntitlementContextForCurrentUser();

    if (forOrganizationMembership) {
        return getEntitlementContextForMembership(currentUserContext, forOrganizationMembership);
    }

    return currentUserContext;
}

/**
 * Produces an entitlement context for the current user alone, with respect to none of their organizations or enterprises
 */
function useEntitlementContextForCurrentUser(): UserEntitlementContext {
    const currentUserUid = useCurrentUserUid() ?? "anonymous";
    const currentUser = useCurrentUser() ?? {uid: currentUserUid};
    const currentUserPrivateMetadata = useUserPrivateMetadata() ?? {uid: currentUserUid};

    return {
        type: "user",
        currentUser,
        currentUserPrivateMetadata,
    };
}

/**
 * Produces an entitlement for the current user with respect to one of their organization or enterprise memberships
 */
function getEntitlementContextForMembership(
    currentUserContext: UserEntitlementContext,
    membership: FullyDecoratedOrganizationMember,
): OrganizationEntitlementContext | EnterpriseEntitlementContext {
    if (isEnterpriseFullyDecoratedOrganizationMember(membership)) {
        return getEntitlementContextForEnterpriseMembership(currentUserContext, membership);
    } else if (isNonEnterpriseFullyDecoratedOrganizationMember(membership)) {
        return getEntitlementContextForNonEnterpriseOrganizationMembership(currentUserContext, membership);
    }

    throw new Error("Unknown membership type: expected organization or enterprise membership");
}

/**
 * Produces an entitlement context for the specified organization or enterprise membership
 */
function getEntitlementContextForNonEnterpriseOrganizationMembership(
    currentUserContext: UserEntitlementContext,
    membership: NonEnterpriseFullyDecoratedOrganizationMember,
): OrganizationEntitlementContext {
    return {
        ...currentUserContext,
        type: "organization",
        membership,
    };
}

/**
 * Produces an entitlement context for the specified organization or enterprise membership
 */
function getEntitlementContextForEnterpriseMembership(
    currentUserContext: UserEntitlementContext,
    membership: EnterpriseFullyDecoratedOrganizationMember,
): EnterpriseEntitlementContext {
    return {
        ...currentUserContext,
        type: "enterprise",
        membership,
    };
}

/**
 * The main entrypoint hook for checking whether a user have access to a given entitlement
 *
 * This method will automatically use the relevant plan evaluation context. For example, if the user is viewing a
 * project owned by an organization, the plan context will be with respect to that organization.
 *
 * This function cannot check the "catgegory" or "organization_category" entitlements, as we prefer to do so with
 * explicit reference to the membership in question. See useCategoryEntitlement() for that.
 *
 * We anticipate that resolving entitlements is fast enough that we don't need to memoize the result, until we know better.
 */
export function useEntitlement<K extends StandardEntitlementKey>(
    entitlementKey: K,
    includeInactivePlans = false,
): EntitlementResult<K> {
    const {planEntitlement} = useFluxServices();
    const context = useEntitlementContext();
    return planEntitlement.getEntitlements(context, includeInactivePlans)[entitlementKey];
}

/**
 * Returns the category/organization_category entitlement for either the current user, or a specific
 * organization/enterprise that the user is a member of.
 *
 * Can return the currentUser's plan category, or return an organization or enterprise's plan category if that is
 * specified by passing in a membership. The user must be a member of the organization/enterprise in question,
 * or they cannot discover the plan category of that organization/enterprise.
 */
export function useCategoryEntitlement(
    forOrganizationMembershipOrCurrentUser: FullyDecoratedOrganizationMember | "currentUser",
    includeInactivePlans = false,
): EntitlementResult<"category"> | EntitlementResult<"organization_category"> {
    const {planEntitlement} = useFluxServices();
    const context = useSpecificEntitlementContext(
        forOrganizationMembershipOrCurrentUser === "currentUser" ? undefined : forOrganizationMembershipOrCurrentUser,
    );
    const categoryEntitlement =
        forOrganizationMembershipOrCurrentUser !== "currentUser" ? "organization_category" : "category";
    return planEntitlement.getEntitlements(context, includeInactivePlans)[categoryEntitlement];
}

/**
 * Reports whether the given account has payment issues with their subscription
 */
export function useAccountHasPaymentIssue(
    forOrganizationMembershipOrCurrentUser: FullyDecoratedOrganizationMember | "currentUser",
    logger: Logger = silentLogger,
): boolean {
    const context = useSpecificEntitlementContext(
        forOrganizationMembershipOrCurrentUser === "currentUser" ? undefined : forOrganizationMembershipOrCurrentUser,
    );
    const planCategory = useCategoryEntitlement(forOrganizationMembershipOrCurrentUser, true);

    /*
     * This undefined condition indicates the desired membership has not loaded or is not available - we can't check for
     *  a payment issue, so we fall back to saying there isn't one.
     *
     * This also makes this hook more convenient to use (because hooks can't be called conditionally)
     */
    if (planCategory === undefined || context === undefined) {
        return false;
    }

    const contributingPlans = planCategory.contributingPlans;

    if (contributingPlans.length > 1) {
        logger.warn("Multiple plans contributing to the category entitlement - unexpected configuration");
        // We'll use the last of them
    }

    const contributingPlan: PlanConfig | undefined = contributingPlans.pop();

    if (!contributingPlan) {
        logger.warn("No plans contributing to the category entitlement - unexpected configuration");

        // Nothing much we can do here
        return false;
    }

    const planStatus = contributingPlan.status(context);

    return (
        planStatus === SubscriptionStatus.enum.past_due ||
        planStatus === SubscriptionStatus.enum.unpaid ||
        // NOTE: do not consider "incomplete" an issue because it is a temporary state
        planStatus === SubscriptionStatus.enum.incomplete_expired
    );
}
