import {AnyPcbBakedNode, CLIPPER_INT_SPACE_CONVERSION, ClipperShapeFactory, vec2} from "@buildwithflux/core";
import {DeepPcbPoint, IVector2} from "@buildwithflux/models";
import {convertLength, normalizeDegreeOrientation, orderIndependentHash, radiansToDegrees} from "@buildwithflux/shared";

import {Shape, approxFittingPolygon} from "../../../shapes";

// eslint-disable-next-line boundaries/element-types

export default class DeepPcbHelpers {
    static origin = {x: 0, y: 0};

    static toNm(meters: number | undefined) {
        return Math.round(Number(convertLength(meters ?? 0, "m", "nm").toFixed(14)));
    }

    static getPoint(position: IVector2 | [number, number], offset: IVector2 = this.origin): DeepPcbPoint {
        if (Array.isArray(position)) {
            position = {x: position[0], y: position[1]};
        }
        const {x, y} = vec2.sub(position, offset);
        return [this.toNm(x), this.toNm(y)];
    }

    static getRotation(radians: number): number {
        return normalizeDegreeOrientation(Math.round(radiansToDegrees(radians)));
    }

    static expandShape(clipperShapeFactory: ClipperShapeFactory, shapeFromShapeStore: Shape, expandBy: number) {
        const polygon = approxFittingPolygon(shapeFromShapeStore, 64, clipperShapeFactory);

        // Don't perform expensive operations if the offset is 0
        if (expandBy === 0) {
            return polygon;
        }

        const clipperShape = clipperShapeFactory.shape([polygon]);
        const expandedClipperShape = clipperShape.offset(expandBy * CLIPPER_INT_SPACE_CONVERSION);
        const [expandedPolygon = []] = expandedClipperShape.toPolygons();
        return expandedPolygon;
    }

    static hash(hashData: any[]): string | undefined {
        const stringifiedHashData = hashData.map((data) => JSON.stringify(data));
        return orderIndependentHash(stringifiedHashData);
    }

    static sanitizeName(name: string) {
        return name.replace(/[^a-zA-Z0-9]/g, "_");
    }

    /**
     * Returns a unique name for a node
     *
     * The name is a combination of the node name and the last 12 characters of the UID
     * and sanitized to remove any invalid characters
     *
     * The last 12 characters of the UID are unique enough to avoid collisions
     * Probability of collision is 1 in 36^12,
     * or two people randomly picking the same grain of sand from all the beaches in the world
     */
    static getUniqueNodeName(node: AnyPcbBakedNode) {
        return this.sanitizeName(`${node.name ?? node.type}_${this.hash([node.uid])!}`);
    }
}
