import {IUserData} from "@buildwithflux/core";
import {IDocumentData, IPartVersionData} from "@buildwithflux/models";
import cloneDeep from "lodash/cloneDeep";
import {InstancedMesh, Mesh, Object3D, Scene} from "three";
import {createMeshesFromInstancedMesh} from "three/examples/jsm/utils/SceneUtils";

export abstract class BaseExporter {
    protected readonly documentData: IDocumentData;
    protected readonly documentOwner: IUserData;

    constructor(documentData: IDocumentData, documentOwner: IUserData) {
        // QUESTION: why clone here???
        this.documentOwner = cloneDeep(documentOwner);
        this.documentData = cloneDeep(documentData);
    }

    /**
     * Basic function to find the correct value property based on the part type.
     * The matching is dumb but getting it wrong is low impact - elements with
     * missing or weird sets of properties just won't be grouped together.
     *
     * TODO: revisit this once we can model these relationships in property definitions.
     */
    public static getValueProperty(row: any): "Capacitance" | "Resistance" | "Inductance" | undefined {
        if (row["Part Type"]?.match(/Capacitor/)) {
            return "Capacitance";
        } else if (row["Part Type"]?.match(/Resistor/)) {
            return "Resistance";
        } else if (row["Part Type"]?.match(/Inductor/)) {
            return "Inductance";
        } else {
            return undefined;
        }
    }

    protected createFileName(extension?: string, postfix?: string) {
        let filename = `${this.documentOwner.handle} - ${this.documentData.slug}`;

        if (postfix) {
            filename = `${filename} - ${postfix}`;
        }

        filename = this.createValidFileString(filename);

        if (extension) {
            const validExtension = this.createValidFileString(extension);

            filename = `${filename}.${validExtension}`;
        }

        return filename;
    }

    protected isSymbolElement(part: IPartVersionData) {
        return !!part.symbol_resource_file;
    }

    protected makeMetaDataInvisible(object: Object3D) {
        object.traverse((object) => {
            if (object.userData.metaData) {
                // we don't care about meta data on export so lets make it invisible
                object.visible = false;
            }
        });

        return object;
    }

    protected normalizeInstancedMeshes(input: Object3D) {
        const scene = new Scene();

        input.traverse((object) => {
            if (object instanceof InstancedMesh) {
                const nonMeshInstanceObject = createMeshesFromInstancedMesh(object);

                scene.add(nonMeshInstanceObject);

                object.visible = false;
            }
        });

        scene.add(input);

        return scene;
    }

    protected deepCloneObject3d(input: Object3D) {
        const clonedInput = input.clone(true);

        clonedInput.traverse((object) => {
            if (object instanceof InstancedMesh || object instanceof Mesh) {
                if (object.material) {
                    if (Array.isArray(object.material)) {
                        object.material = object.material.map((material) => material.clone());
                    } else {
                        object.material = object.material.clone();
                    }
                }
            }
        });
        return clonedInput;
    }

    private createValidFileString(fileString: string) {
        // Need to be careful with what characters we use here. PCB manufacturers are from the 70s!
        // we know for sure that they don't allow punctuation marks and &.
        // dash(-) and underscore(_) are ok
        return fileString.replace(/[ &\/\\#,+()$~%.'":*?<>{}]/g, "");
    }

    // TODO: most subclasses implement a download method, so this should be abstract
    // abstract download(): void;
}
