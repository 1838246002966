import {isDevEnv} from "@buildwithflux/shared";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import {Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/styles";
import React, {CSSProperties, useEffect, useMemo, useState} from "react";

import {INestedLoaderProps} from "./types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        circularProgress: {
            verticalAlign: "top",
        },
        loadingImage: {
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: "50%",
        },
        text: {
            marginTop: "10px",
        },
        content: {
            marginLeft: theme.spacing(1),
        },
        outer: {
            width: "100%",
            height: "100%",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
        },
        inner: {
            display: "inline-block",
            margin: "0 auto",
        },
        innerWithNavOffset: {
            display: "inline-block",
            margin: "-48px auto 0 auto",
        },
    }),
);

const loadingText1FadeStyle: CSSProperties = {
    transitionDelay: "1500ms",
};

const launchSequences = {
    falcon: [
        "Initiating Pre-flight Checks...",
        "Booting core systems: life support, communication, navigation...",
        "Performing diagnostic check on computer interfaces...",
        "Calibrating navigational systems with galaxy-wide astronomical data...",
        "Starting Engine Sequence...",
        "Activating power sequence for sublight engines, initiating repulsorlifts...",
        "Performing comprehensive system check: shields, hyperdrive, weapon systems...",
        "Verifying functionality of sublight engines' thrust vectoring system...",
        "Preparing for Launch...",
        "Increasing power to repulsorlifts...",
        "Performing final system checks, verifying manual overrides...",
        "Confirming trajectory and course...",
        "Commencing Launch...",
        "Increasing thrust to maximum, exiting landing area...",
        "Adjusting pitch and yaw via thrust vectoring system...",
        "Disengaging repulsorlifts, transferring to sublight engines...",
        "Transitioning to Space Travel...",
        "Balancing sublight engines for standard propulsion...",
        "Retracting landing gears and atmospheric flight equipment...",
        "Activating deflector shields for debris protection...",
        "Starting Hyperdrive Sequence...",
        "Plotting hyperspace course, accounting for gravitational anomalies...",
        "Engaging hyperdrive, safety checks passed and course confirmed...",
        "Monitoring ship systems during hyperspace travel, verifying hyperdrive functionality...",
    ],
    fluxCapacitor: [
        "Flux Capacitor boot sequence initiated...",
        "Loading system BIOS...",
        "BIOS load successful. Proceeding to hardware check...",
        "Initializing Time Circuit Controller...",
        "Loading Time Circuit Controller firmware...",
        "Time Circuit Controller firmware loaded successfully.",
        "Time Circuit Controller initialization successful.",
        "Calibrating Temporal Transducers...",
        "Optimizing Temporal Transducer resonance frequency...",
        "Temporal Transducers calibrated successfully.",
        "Establishing Plutonium Fuel Reactor interface...",
        "Checking Plutonium Fuel Reactor status...",
        "Plutonium Fuel Reactor status: Stable.",
        "Plutonium Fuel Reactor interface established.",
        "Activating Flux Dispersal Matrix...",
        "Running Flux Dispersal Matrix diagnostic...",
        "Flux Dispersal Matrix activated.",
        "Beginning Quantum Harmonizer self-check...",
        "Quantum Harmonizer integrity: 100%.",
        "Quantum Harmonizer self-check complete. No errors found.",
        "Synchronizing with vehicle systems...",
        "Vehicle systems synchronization initiated.",
        "Checking vehicle power systems...",
        "Vehicle power systems: Stable.",
        "Vehicle system synchronization complete.",
        "Running final diagnostics...",
        "Final diagnostics initiated.",
        "All systems operational. No errors detected.",
        "Flux Capacitor boot sequence completed successfully.",
        "Ready for temporal displacement.",
    ],
    matrix: [
        "Matrix system boot sequence initiated",
        "Initiating quantum processors...",
        "Quantum processor 1: online",
        "Quantum processor 2: online",
        "Quantum processor 3: online",
        "Quantum processor 4: online",
        "Loading neural interface drivers...",
        "Neural interface driver 1: loaded",
        "Neural interface driver 2: loaded",
        "Neural interface driver 3: loaded",
        "Establishing connection to human bio-pods...",
        "Sector 1: connection established",
        "Sector 2: connection established",
        "Sector 3: connection established",
        "Sector 4: connection established",
        "Synchronizing with Machine City mainframe...",
        "Machine City mainframe synchronization: initiated",
        "Data transfer: started",
        "Data transfer: 25% complete",
        "Data transfer: 50% complete",
        "Data transfer: 75% complete",
        "Data transfer: 100% complete",
        "Machine City mainframe synchronization: successful",
        "Uploading current reality parameters...",
        "Uploading human psychological parameters",
        "Uploading human physiological parameters",
        "Uploading environment parameters",
        "Current reality parameters: uploaded",
        "Initializing Sentinel Network...",
        "Sentinel network node 1: online",
        "Sentinel network node 2: online",
        "Sentinel network node 3: online",
        "Sentinel network: online",
        "Booting up Virtual Reality Engine...",
        "Loading environment modules",
        "Loading human interaction modules",
        "Loading uncertainty principle modules",
        "Virtual reality engine: online",
        "Initializing program agents...",
        "Program agent 1: online",
        "Program agent 2: online",
        "Program agent 3: online",
        "Matrix mainframe boot sequence complete... Welcome to the Matrix",
    ],
};

const launchSequencesArray = Object.values(launchSequences);

const launchSequenceSteps =
    launchSequencesArray[Math.floor(Math.random() * launchSequencesArray.length)] || launchSequences.falcon;

const startupSequenceSpeed = 5000;

/**
 * Use this component when you want to show a spinner within a parent html element indicating we are
 * loading something
 */
function NestedLoader(props: INestedLoaderProps) {
    const showWithNavbarOffset = props.showWithNavbarOffset || false;
    const caller = props.caller || "NestedLoader";
    const delayTime = props.delay || 500;
    const classes = useStyles();
    const [stepIndex, setStepIndex] = useState<number>(0);

    useEffect(() => {
        if (!isDevEnv()) {
            return;
        }

        const mountTime = Date.now();

        // eslint-disable-next-line no-console
        console.log(`[SpinnerMounted] ${caller} ${props.surface} mounted with delay ${delayTime}ms`);

        return () => {
            const unmountTime = Date.now();
            const duration = unmountTime - mountTime;

            if (duration >= delayTime) {
                // eslint-disable-next-line no-console
                console.log(
                    `[SpinnerUnmounted] ${caller} ${props.surface} with delay ${delayTime}ms was rendered. Duration: ${duration}ms`,
                );
            } else {
                // eslint-disable-next-line no-console
                console.log(
                    `[SpinnerUnmounted] ${caller} ${props.surface} with delay ${delayTime}ms was NOT rendered. Duration: ${duration}ms`,
                );
            }
        };
    }, [props.surface, caller, delayTime]);

    const fadeStyle = useMemo<CSSProperties>(() => {
        return {
            transitionDelay: `${delayTime}ms`,
        };
    }, [delayTime]);

    useEffect(() => {
        if (stepIndex < launchSequenceSteps.length) {
            const timer = setTimeout(() => {
                setStepIndex(stepIndex + 1);
            }, startupSequenceSpeed);
            // Clean up the timer when effect is done
            return () => clearTimeout(timer);
        } else {
            setStepIndex(0);
        }
    }, [stepIndex]);

    return (
        <>
            <div className={classes.outer}>
                <div className={showWithNavbarOffset ? classes.innerWithNavOffset : classes.inner}>
                    {
                        // Disabling `forbid-component-props` here because this is a dynamic style
                        // eslint-disable-next-line react/forbid-component-props
                        <Fade in={true} style={fadeStyle} unmountOnExit>
                            <Box flexDirection="column" display="flex" alignItems="center" justifyContent="center">
                                <Box display="inline">
                                    <CircularProgress
                                        disableShrink
                                        aria-label={props.content || "Loading"}
                                        className={classes.circularProgress}
                                    />

                                    {props.content && (
                                        <Typography
                                            className={classes.content}
                                            variant="h4"
                                            component="h1"
                                            display="inline"
                                            color={"textPrimary"}
                                        >
                                            {props.content}
                                        </Typography>
                                    )}
                                </Box>

                                {props.bodyContent && (
                                    <Typography variant="body1" className={classes.text} color={"textPrimary"}>
                                        {props.bodyContent}
                                    </Typography>
                                )}

                                {props.showLongWaitExcuse ? (
                                    <>
                                        {
                                            // Disabling `forbid-component-props` here because we cant specify className for <Fade />
                                            // eslint-disable-next-line react/forbid-component-props
                                            <Fade in={true} style={loadingText1FadeStyle} unmountOnExit>
                                                <Typography
                                                    variant="caption"
                                                    className={classes.text}
                                                    color={"textPrimary"}
                                                >
                                                    {launchSequenceSteps[stepIndex]}
                                                </Typography>
                                            </Fade>
                                        }
                                    </>
                                ) : (
                                    <Typography variant="caption" className={classes.text} color={"textPrimary"}>
                                        &nbsp;
                                    </Typography>
                                )}
                            </Box>
                        </Fade>
                    }
                </div>
            </div>
        </>
    );
}

export default React.memo(NestedLoader);
