import type {
    AutoLayoutApiVersion,
    AutoLayoutIteration,
    AutoLayoutJobMetadata,
    AutoLayoutJobUid,
    IUserData,
    OrganizationUid,
    StorageEvent,
    UserJobOfType,
} from "@buildwithflux/models";
import type {SnackbarKey, OptionsObject as SnackbarOptions} from "notistack";
import React from "react";

import type {SnackbarMessageKey} from "../../components/pages/document/components/editor/components/fabs/auto-layout/useAutoLayoutSnackbar";

export enum LocalAutoLayoutState {
    idle = "idle",
    waiting = "waiting",
    pausing = "pausing",
    paused = "paused",
    resuming = "resuming",
    running = "running",
    noCredits = "noCredits",
    complete = "complete",
    canceling = "canceling",
    canceled = "canceled",
    applying = "applying",
    applied = "applied",
    error = "error",
}

export type AutoLayoutStoreState = {
    state: LocalAutoLayoutState;
    jobUid: AutoLayoutJobUid | undefined;
    startTime: number | undefined;
    timeElapsed: string | undefined;
    usedCredits: number | undefined;
    errorMessage?: string;

    // fields to help figure out how much time we've spent in "Initializing" state
    startTimeForInitializingState: number;
    timeoutIntervalForInitializingState: NodeJS.Timeout | undefined;
    detectedSlowness: boolean;
};

export type AutoLayoutStoreApi = {
    queue: () => void;
    pause: () => void;
    paused: () => void;
    resume: () => void;
    resumed: () => void;
    load: (startTime?: number) => void;
    noCredits: () => void;
    apply: () => void;
    applied: () => void;
    cancel: () => void;
    canceled: () => void;
    reset: () => void;
    complete: () => void;
    error: (errorMessage: string) => void;
    tick: (startTime?: number) => void;

    setJobUid: (jobUid: AutoLayoutJobUid | undefined) => void;
    setUsedCredits: (usedCredits: number) => void;
    unsubscribe: () => void;
};

export type OnJobChangeFn = (
    event: StorageEvent<UserJobOfType<"autoLayout">>,
    organizationUid: OrganizationUid | undefined,
    autoLayoutVersion: AutoLayoutApiVersion,
) => Promise<void>;

export type OnJobMetadataChangeFn = (event: StorageEvent<AutoLayoutJobMetadata>) => void;

export interface AutoLayoutService {
    showSnackbar: ShowSnackbarFn | null;
    /**
     * Use this to do any init happens after binding
     *
     * TODO: Most of these are just a hack to update/communicate with the react component,
     * we should think about better way to do this...
     */
    init: (
        showSnackbar: ShowSnackbarFn,
        setSliderPosition: (index: number) => void,
        sliderPositionRef: React.MutableRefObject<number>,
    ) => void;

    /**
     * Communicate with backend to start an auto-layout job
     *
     * @param organizationUid - pass if user belongs to an org
     * @param autoLayoutVersion - use this to control api version when calling server
     */
    startJob: (
        organizationUid: OrganizationUid | undefined,
        autoLayoutVersion: AutoLayoutApiVersion,
    ) => Promise<AutoLayoutJobUid | undefined>;

    /**
     * Find and load any active job associated with a project
     *
     * @param currentUser - handle of user who owns the project, which is the current user
     * @param projectUid - uid of the project
     * @param organizationUid - pass if user belongs to an org
     * @param autoLayoutVersion - use this to control api version when calling server
     */
    findAndLoadActiveJob: (
        currentUser: IUserData,
        projectUid: string,
        organizationUid: OrganizationUid | undefined,
        autoLayoutVersion: AutoLayoutApiVersion,
    ) => Promise<void>;

    /**
     * Cancel current job, unsubscribe job and metadata
     *
     * @param autoLayoutVersion - use this to control api version when calling server
     */
    cancelJob: (autoLayoutVersion: AutoLayoutApiVersion) => Promise<void>;

    /**
     * Pause current job
     *
     * @param organizationUid - pass if user belongs to an org
     * @param autoLayoutVersion - use this to control api version when calling server
     */
    pauseJob: (organizationUid: OrganizationUid | undefined, autoLayoutVersion: AutoLayoutApiVersion) => void;

    /**
     * Resume current job
     *
     * @param organizationUid - pass if user belongs to an org
     * @param autoLayoutVersion - use this to control api version when calling server
     */
    resumeJob: (organizationUid: OrganizationUid | undefined, autoLayoutVersion: AutoLayoutApiVersion) => void;

    /**
     * Apply an iteration from current job, this also stops the job
     *
     * @param autoLayoutVersion - use this to control api version when calling server
     * @param iteration - iteration that will be applied
     */
    applyIteration: (autoLayoutVersion: AutoLayoutApiVersion, iteration: AutoLayoutIteration) => Promise<void>;

    updateIterationIndex: (targetIndex: number, shouldLog: boolean, isCurrentIterationBaked: boolean) => Promise<void>;

    /**
     * Clean up any subscription and state
     */
    shutdown: () => void;

    /**
     * Clean up any subscription
     */
    unsubscribeJob: () => void;
}

export type ShowSnackbarFn = (key: SnackbarMessageKey, options?: SnackbarOptions) => SnackbarKey;
