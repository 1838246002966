import Box, {BoxProps} from "@material-ui/core/Box";
import {createStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as CSS from "csstype";
import {CSSProperties, useMemo} from "react";

interface IStack extends BoxProps {
    children: React.ReactNode;
    direction: "column" | "row" | "column-reverse" | "row-reverse";
    gap?: CSS.Property.Gap;
}

/**
 * Customization of MUI's Box for convenience. It is modeled on Chakra's
 * component of the same name, but uses the new CSS `gap` property.
 *
 * Stack is a layout component that makes it easy to stack elements together and
 * apply a space between them.
 *
 * @see https://chakra-ui.com/docs/layout/stack
 * @see https://coryrylan.com/blog/css-gap-space-with-flexbox
 */
function Stack(props: IStack) {
    const {children, direction, gap} = props;

    const style = useMemo<CSSProperties>(() => {
        return {gap};
    }, [gap]);
    return (
        <Box
            // disabling `forbid-component-props` rule here because this is a dynamic style depending
            // on props
            // eslint-disable-next-line react/forbid-component-props
            style={style}
            display="flex"
            flexDirection={direction}
            // IDEA: add support for MUI's theme spacing values, gap={1}
            alignItems="center"
            {...props}
        >
            {children}
        </Box>
    );
}

export function HStack(props: Omit<IStack, "direction">) {
    return <Stack direction="row" {...props} />;
}

export function VStack(props: Omit<IStack, "direction">) {
    return <Stack direction="column" {...props} />;
}

export function LeftRight(props: Omit<IStack, "direction" | "justifyContent">) {
    return <Stack direction="row" justifyContent="space-between" width="100%" {...props} />;
}

export function RightLeft(props: Omit<IStack, "direction" | "justifyContent">) {
    return <Stack direction="row-reverse" justifyContent="space-between" width="100%" {...props} />;
}

interface ILeftRightWrap extends BoxProps {
    children: React.ReactNode[];
}

const useStylesForLeftRightWrap = makeStyles((_: Theme) =>
    createStyles({
        buttonRightContent: {
            float: "right",
        },
    }),
);
/**
 * Different implementation of LeftRight above that uses float for different
 * wrapping behavior. LeftRightWrap will wrap on two lines instead of staying on
 * one line while maintaining the left right behavior.
 *
 * @see
 * https://www.digitalocean.com/community/tutorials/css-no-more-clearfix-flow-root
 */
export function LeftRightWrap(props: ILeftRightWrap) {
    const classes = useStylesForLeftRightWrap();
    const {children} = props;
    return (
        <Box {...props} display="flow-root">
            <div>{children[0]}</div>
            <div className={classes.buttonRightContent}>{children[1]}</div>
        </Box>
    );
}
