import {AsyncClipperShapeFactory, DeepPcbAutoLayoutIterationImporter} from "@buildwithflux/core";
import type {ClientFunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";
import {
    AutoLayoutApi,
    type AutoLayoutApiVersion,
    type AutoLayoutIteration,
    type AutoLayoutJob,
    AutoLayoutJobMetadata,
    type AutoLayoutJobUid,
    AutoLayoutStatus,
    DeepPcbBoard,
    IUserData,
    type OrganizationUid,
    StartedAutoLayoutJob,
    StorageEvent,
    UserJobOfType,
} from "@buildwithflux/models";
import type {
    AutoLayoutJobMetadataRepository,
    AutoLayoutJobRepository,
    UserJobRepositoryReader,
} from "@buildwithflux/repositories";
import {Logger, PromiseQueue, Unsubscriber} from "@buildwithflux/shared";
import type {DocumentService} from "@buildwithflux/solder-core";
import axios from "axios";

import {getTimeElapsedInSeconds} from "../../../helpers/dateAndTime";
import {applyAutoLayoutIterationThunk} from "../../../redux/reducers/document/pcbLayoutNodes/actions";
import type {ReduxStoreService} from "../../../redux/util/service";
import type {CurrentUserService} from "../../auth";
import {DeepPcbExporter} from "../../data_portability/exporters/DeepPcbExporter/DeepPcbExporter";
import type {PcbShapesStore} from "../../shapes";
import type {AnalyticsStorage} from "../../storage_engine/AnalyticsStorage";
import {SurfaceBasedTrackingEvents} from "../../storage_engine/common/SurfaceBasedTrackingEvents";
import {PcbBakedGoodsManager} from "../../stores/pcb/PcbBakedGoodsManager";
import {usePcbEditorUiStore} from "../../stores/pcb/PcbEditorUiStore";
import {UseAutoLayoutStore} from "../state";
import {type AutoLayoutService, LocalAutoLayoutState, type ShowSnackbarFn} from "../types";

export class DeepPcbAutoLayoutService implements AutoLayoutService {
    public showSnackbar: ShowSnackbarFn | null = null;
    private setSliderPosition: ((index: number) => void) | undefined;
    private sliderPositionRef: React.MutableRefObject<number> | undefined;

    private updateAutoLayoutDataQueue: PromiseQueue | undefined;
    private updateIterationIndexQueue: PromiseQueue | undefined;

    private jobUnsub: Unsubscriber | undefined;
    private jobMeatadataUnsub: Unsubscriber | undefined;

    constructor(
        private readonly reduxStoreService: ReduxStoreService,
        private readonly functionsAdapter: ClientFunctionsAdapter,
        private readonly currentUserService: CurrentUserService,
        private readonly pcbShapesStore: PcbShapesStore,
        private readonly userJobRepository: UserJobRepositoryReader,
        private readonly autoLayoutJobRepository: AutoLayoutJobRepository,
        private readonly autoLayoutJobMetadataRepository: AutoLayoutJobMetadataRepository,
        private readonly documentService: DocumentService,
        private readonly pcbBakedGoodsManager: PcbBakedGoodsManager,
        private readonly useAutoLayoutStore: UseAutoLayoutStore,
        private readonly analyticsStorage: AnalyticsStorage,
        private readonly logger: Logger,
    ) {}

    /* @inheritDoc */
    public init(
        showSnackbar: ShowSnackbarFn,
        setSliderPosition: (index: number) => void,
        sliderPositionRef: React.MutableRefObject<number>,
    ) {
        this.updateAutoLayoutDataQueue = new PromiseQueue();
        this.updateIterationIndexQueue = new PromiseQueue();
        this.showSnackbar = showSnackbar;
        this.setSliderPosition = setSliderPosition;
        this.sliderPositionRef = sliderPositionRef;
    }

    /* @inheritDoc */
    public async startJob(
        organizationUid: OrganizationUid | undefined,
        autoLayoutVersion: AutoLayoutApiVersion,
    ): Promise<AutoLayoutJobUid | undefined> {
        const {
            reduxStoreService,
            functionsAdapter,
            currentUserService,
            pcbShapesStore,
            logger,
            userJobRepository,
            analyticsStorage,
            documentService,
            autoLayoutJobMetadataRepository,
            useAutoLayoutStore,
            showSnackbar,
        } = this;

        // Optimistic update local store
        useAutoLayoutStore.getState().queue();

        const projectUid = reduxStoreService.getStore().getState().document?.uid;
        if (!projectUid) return;

        // Create the job, and get the uploadUrl from backend
        const result = await functionsAdapter.createAutoLayoutJob({
            projectUid,
            organizationUid: organizationUid,
        });

        const {data} = result;
        if (data.type === "success") {
            const {uploadUrl, jobUid} = data;
            const {
                document,
                documentMeta: {documentOwner},
            } = reduxStoreService.getStore().getState();
            const currentUser = currentUserService.getCurrentUser();

            if (!document) {
                showSnackbar?.("genericError");
                logger.error("Document not found in store");
                return;
            }

            if (!documentOwner) {
                showSnackbar?.("genericError");
                logger.error("Document owner not found in store");
                return;
            }

            if (!currentUser) {
                // Should be impossible, but just a null check
                showSnackbar?.("genericError");
                logger.error("Current user not found in store");
                return;
            }

            // Update the jobUid
            useAutoLayoutStore.getState().setJobUid(jobUid);

            const clipperShapeFactory = await new AsyncClipperShapeFactory().load();
            const deepPcbJsonExporter = new DeepPcbExporter(
                autoLayoutVersion,
                document,
                documentOwner,
                pcbShapesStore,
                reduxStoreService,
                documentService,
                clipperShapeFactory,
            );
            const deepPcbBoard = deepPcbJsonExporter.generateBoard();

            try {
                // TODO: Need to check with Nick to see what config we need to do in order for cdn2.flux.ai domain to work
                await axios.put(
                    uploadUrl.replace("cdn2.flux.ai", "storage.googleapis.com"),
                    JSON.stringify(deepPcbBoard),
                    {
                        headers: {
                            // Need to unset Content-Type to make it work...
                            "Content-Type": "",
                        },
                    },
                );
            } catch (error) {
                showSnackbar?.("genericError");
                logger.error("Failed to upload board to GCS", error);
                return;
            }

            // Subscription to job - monitoring status and iterations
            this.jobUnsub = userJobRepository.subscribeToJob(currentUser, "autoLayout", jobUid, (event) => {
                this.onJobChange(event, organizationUid, autoLayoutVersion);
            });

            // Subscription to more-frequent metadata responses
            this.jobMeatadataUnsub = autoLayoutJobMetadataRepository.subscribe(currentUser, jobUid, (event) => {
                this.onJobMetadataChange(event);
            });

            analyticsStorage.logEvent(SurfaceBasedTrackingEvents.pcbEditor, {surface: "AutoLayout", action: "start"});

            return jobUid;
        } else {
            // Failure, show reason in snackbar
            showSnackbar?.("genericError");

            logger.error("Failed to create auto layout job", data.reason);

            analyticsStorage.logEvent(SurfaceBasedTrackingEvents.pcbEditor, {
                surface: "AutoLayout",
                action: "error",
                reason: data.reason,
                timeElapsedInSeconds: getTimeElapsedInSeconds(new Date().getTime()),
            });
        }
    }

    /* @inheritDoc */
    public async findAndLoadActiveJob(
        currentUser: IUserData,
        projectUid: string,
        organizationUid: OrganizationUid | undefined,
        autoLayoutVersion: AutoLayoutApiVersion,
    ): Promise<void> {
        const {autoLayoutJobRepository, useAutoLayoutStore, userJobRepository, autoLayoutJobMetadataRepository} = this;

        return autoLayoutJobRepository
            .getActiveJobForProject(currentUser.handle, currentUser.uid, projectUid)
            .then((activeJob) => {
                // Prevent duplicate subscriptions
                const activeJobUid = activeJob?.jobUid;
                if (activeJobUid === useAutoLayoutStore.getState().jobUid) {
                    return;
                }

                if (activeJobUid) {
                    // TODO: Logic here should be combined with `handleStartAutoLayout`, and maybe in a service
                    useAutoLayoutStore.getState().load("startTime" in activeJob ? activeJob.startTime : undefined);

                    // Update the jobUid
                    useAutoLayoutStore.getState().setJobUid(activeJobUid);

                    // Subscription to iterations
                    this.jobUnsub = userJobRepository.subscribeToJob(
                        currentUser,
                        "autoLayout",
                        activeJobUid,
                        (event) => {
                            this.onJobChange(event, organizationUid, autoLayoutVersion);
                        },
                    );

                    // Subscription to more-frequent metadata responses
                    this.jobMeatadataUnsub = autoLayoutJobMetadataRepository.subscribe(
                        currentUser,
                        activeJobUid,
                        (event) => {
                            this.onJobMetadataChange(event);
                        },
                    );
                }
            });
    }

    /* @inheritDoc */
    public async cancelJob(autoLayoutVersion: AutoLayoutApiVersion): Promise<void> {
        const {useAutoLayoutStore} = this;
        // Optimistic cancel job
        useAutoLayoutStore.getState().cancel();

        await this.stopJob("cancel", autoLayoutVersion);
        usePcbEditorUiStore.getState().clearAutoLayoutData();
        this.forceRebake();
    }

    /* @inheritDoc */
    public pauseJob(organizationUid: OrganizationUid | undefined, autoLayoutVersion: AutoLayoutApiVersion): void {
        const {useAutoLayoutStore, currentUserService, showSnackbar, logger} = this;
        // Optimistic pause
        useAutoLayoutStore.getState().pause();

        const currentUser = currentUserService.getCurrentUser();

        if (!currentUser) return;
        axios
            .post(AutoLayoutApi.urlFor(AutoLayoutApi.action.pause, autoLayoutVersion), {
                userUid: currentUser.uid,
                jobUid: useAutoLayoutStore.getState().jobUid,
                organizationUid,
            })
            .catch((error) => {
                showSnackbar?.("genericError");
                useAutoLayoutStore.getState().resume();
                logger.error("Failed to pause auto layout job", error);
            });
    }

    /* @inheritDoc */
    public resumeJob(organizationUid: OrganizationUid | undefined, autoLayoutVersion: AutoLayoutApiVersion): void {
        const {useAutoLayoutStore, currentUserService, showSnackbar, logger} = this;
        // Optimistic resume
        useAutoLayoutStore.getState().resume();

        const currentUser = currentUserService.getCurrentUser();

        if (!currentUser) return;
        axios
            .post(AutoLayoutApi.urlFor(AutoLayoutApi.action.resume, autoLayoutVersion), {
                userUid: currentUser.uid,
                jobUid: useAutoLayoutStore.getState().jobUid,
                organizationUid,
            })
            .catch((error) => {
                showSnackbar?.("genericError");
                useAutoLayoutStore.getState().pause();
                logger.error("Failed to resume auto layout job", error);
            });
    }

    /* @inheritDoc */
    public async applyIteration(
        autoLayoutVersion: AutoLayoutApiVersion,
        iteration: AutoLayoutIteration,
    ): Promise<void> {
        // Optimistic apply
        this.useAutoLayoutStore.getState().apply();

        // Clear the auto router state
        usePcbEditorUiStore.getState().clearAutoLayoutData();

        // Apply the iteration
        // We don't need to call `forceRebake` here, as the redux change thru
        // `applyAutoLayoutIterationThunk` will trigger a re-bake
        const allNodes = this.documentService.snapshot().pcbLayoutNodes;
        this.reduxStoreService.getStore().dispatch(applyAutoLayoutIterationThunk(allNodes, iteration));
        this.forceRebake();

        // Update the job state to "applied" in firestore
        await this.stopJob("apply", autoLayoutVersion);
    }

    public async updateIterationIndex(targetIndex: number, shouldLog = false, isCurrentIterationBaked = true) {
        const {updateIterationIndexQueue, analyticsStorage, sliderPositionRef, useAutoLayoutStore} = this;
        const task = async () => {
            const totalIterations = usePcbEditorUiStore.getState().autoLayoutIterationData?.iterations.length ?? 0;
            if (targetIndex < 0 || targetIndex >= totalIterations) return;

            // The slider is no longer on this index, so we don't need to update
            if (targetIndex !== sliderPositionRef?.current) return;

            // Whether the target index is already stored in the local state to be consumed when baking
            const isTargetIndexCurrentIterationIndex =
                targetIndex === usePcbEditorUiStore.getState().autoLayoutIterationData?.currentIterationIndex;
            if (isTargetIndexCurrentIterationIndex && isCurrentIterationBaked) return;

            // Finally, in this state, we have the targetIndex matching the slider position,
            // and the target index is either:
            // - already stored in pcbEditorUiStore, but still hasn't been baked
            // - not stored/updated in pcbEditorUiStore

            // Update the current iteration index in the local store
            usePcbEditorUiStore.getState().setAutoLayoutCurrentIterationIndex(targetIndex);

            const totalIterationsCount = usePcbEditorUiStore.getState().autoLayoutIterationData?.iterations.length ?? 0;

            try {
                await this.forceRebake();

                if (shouldLog) {
                    analyticsStorage.logEvent(SurfaceBasedTrackingEvents.pcbEditor, {
                        surface: "AutoLayout",
                        action: "changeIteration",
                        targetIteration: targetIndex,
                        totalIterations,
                        isLatestIteration: targetIndex === totalIterationsCount,
                        timeElapsedInSeconds: getTimeElapsedInSeconds(useAutoLayoutStore.getState().startTime),
                    });
                }
            } catch (error) {
                // We don't want to show a snackbar here
                // The baking-process can produce errors many times that are not always
                // critical to the user or the auto-layout functionality

                this.logger.error("Error while force rebaking", error);

                if (shouldLog) {
                    analyticsStorage.logEvent(SurfaceBasedTrackingEvents.pcbEditor, {
                        surface: "AutoLayout",
                        action: "changeIterationError",
                        targetIteration: targetIndex,
                        totalIterations,
                        isLatestIteration: targetIndex === totalIterationsCount,
                        reason: error?.toString(),
                        timeElapsedInSeconds: getTimeElapsedInSeconds(useAutoLayoutStore.getState().startTime),
                    });
                }
            }
        };

        await updateIterationIndexQueue?.add(task);
    }

    /* @inheritDoc */
    public shutdown() {
        this.unsubscribeJob();
    }

    public unsubscribeJob() {
        this.jobMeatadataUnsub?.();
        this.jobUnsub?.();
        this.jobMeatadataUnsub = undefined;
        this.jobUnsub = undefined;
    }

    /**
     * Update the job state to "applied"/"cancelled" in firestore thru cloudrun
     */
    private async stopJob(reason: "cancel" | "apply", autoLayoutVersion: AutoLayoutApiVersion): Promise<void> {
        const {useAutoLayoutStore, currentUserService, showSnackbar, logger} = this;
        const jobUid = useAutoLayoutStore.getState().jobUid;
        if (!jobUid) return;

        const currentUser = currentUserService.getCurrentUser();
        if (!currentUser) return;

        try {
            await axios.post(
                AutoLayoutApi.urlFor(
                    reason === "cancel" ? AutoLayoutApi.action.discard : AutoLayoutApi.action.apply,
                    autoLayoutVersion,
                ),
                {
                    userUid: currentUser.uid,
                    jobUid,
                    reason,
                },
            );
        } catch (error) {
            showSnackbar?.("genericError");
            logger.error("Failed to stop auto layout job", error);
        }
    }

    private onJobConverged(job: AutoLayoutJob) {
        if (job.jobStatus !== AutoLayoutStatus.converged) return;

        this.analyticsStorage.logEvent(SurfaceBasedTrackingEvents.pcbEditor, {
            surface: "AutoLayout",
            action: "converged",
            currentIteration: this.getCurrentIteration()?.iterationIndex,
            timeElapsedInSeconds: getTimeElapsedInSeconds(job.startTime),
        });
    }

    private async processJobIterations(job: StartedAutoLayoutJob) {
        await this.updateAutoLayoutDataQueue?.add(() => this.updateAutoLayoutData(job));
    }

    /**
     * TODO: Should bind usePcbEditorUiStore...
     */
    private getCurrentIteration(): AutoLayoutIteration | undefined {
        const pcbEditorUiState = usePcbEditorUiStore.getState();

        if (pcbEditorUiState.autoLayoutIterationData?.currentIterationIndex !== undefined) {
            return pcbEditorUiState.autoLayoutIterationData.iterations[
                pcbEditorUiState.autoLayoutIterationData.currentIterationIndex
            ];
        }
    }

    /**
     * Key function to update local `pcbEditorUiStore` with the latest iteration data.
     *
     * The function should ideally be wrapped around a try-catch block upstream.
     *
     * 1. Fetch the board data from GCS
     * 2. Update the local `pcbEditorUiStore` with the latest iteration data
     * 3. (Optional) If the user is viewing the last iteration, update the current iteration index
     * 4. Re-bake the board
     *
     * Note: We locally store N+1 iterations, where N is the number of iterations received from the backend.
     * And the 1 extra is the initial state of the board (the dummy iteration).
     */
    private async updateAutoLayoutData(receivedJob: StartedAutoLayoutJob) {
        const receivedIterations = receivedJob.iterations;
        if (receivedIterations.length === 0) return;

        const {documentService, sliderPositionRef, setSliderPosition} = this;
        const allNodes = documentService.snapshot().pcbLayoutNodes;
        const importer = new DeepPcbAutoLayoutIterationImporter(allNodes);

        // Add a dummy iteration in the first position when we receive the first iteration
        {
            const existingIterationData = usePcbEditorUiStore.getState().autoLayoutIterationData;
            const isFirstIteration =
                existingIterationData === undefined || existingIterationData.iterations.length === 0;
            if (isFirstIteration) {
                usePcbEditorUiStore.getState().addAutoLayoutIterationData({
                    hash: `auto-layout-step-sha256-FIRST_ITERATION`,
                    iterationIndex: 0,
                    nodes: {},
                });
                usePcbEditorUiStore.getState().setAutoLayoutCurrentIterationIndex(0);
                if (sliderPositionRef) sliderPositionRef.current = 0;
                setSliderPosition?.(0);
            }
        }

        // Assuming they are ordered
        for (let index = 0; index < receivedIterations.length; index++) {
            // Using structuredClone to avoid reading mutated data
            const existingIterationData = structuredClone(usePcbEditorUiStore.getState().autoLayoutIterationData);
            const existingIterations = existingIterationData?.iterations ?? [];

            const localIterationIndex = index + 1; // +1 because of the dummy iteration
            const localIterationExists = !!existingIterations[localIterationIndex];

            const receivedIteration = receivedIterations[index]; // will ideally be defined
            if (!receivedIteration || localIterationExists) {
                continue;
            }

            const response = await fetch(receivedIteration.boardLocation);
            const board = (await response.json()) as DeepPcbBoard;
            const newIteration = importer.generateAutoLayoutIteration(
                receivedIteration.hash,
                localIterationIndex,
                board,
            );

            // Add the iteration data to the local store, and optionally stick to the end
            usePcbEditorUiStore.getState().addAutoLayoutIterationData(newIteration);
            if (sliderPositionRef?.current === existingIterations.length - 1) {
                sliderPositionRef.current = localIterationIndex;
                setSliderPosition?.(localIterationIndex);
                this.updateIterationIndex(localIterationIndex, false, false);
            }
        }
    }

    private async forceRebake() {
        const {reduxStoreService, pcbBakedGoodsManager} = this;
        const document = reduxStoreService.getStore().getState().document;
        if (document) {
            try {
                await pcbBakedGoodsManager.reload(document, document.pcbLayoutNodes, document.pcbLayoutRuleSets);
            } catch (error) {
                this.logger.error("Error in force rebake", error);
            }
        }
    }

    private onJobMetadataChange(event: StorageEvent<AutoLayoutJobMetadata>): void {
        if (event.type === "updated") {
            this.useAutoLayoutStore.getState().setUsedCredits(event.data.creditsUsed);
        }
    }

    private async onJobChange(
        event: StorageEvent<UserJobOfType<"autoLayout">>,
        organizationUid: OrganizationUid | undefined,
        autoLayoutVersion: AutoLayoutApiVersion,
    ): Promise<void> {
        const {currentUserService, useAutoLayoutStore, showSnackbar, logger} = this;
        const {
            state,
            queue,
            pause,
            paused,
            resume,
            resumed,
            applied,
            canceled,
            reset,
            complete,
            tick,
            noCredits,
            error,
            load,
        } = useAutoLayoutStore.getState();

        /**
         * If local state is idle, we are not running any job and hence
         * should ignore any incoming events
         */
        if (state === LocalAutoLayoutState.idle) return;

        if (event.type === "updated") {
            const currentUser = currentUserService.getCurrentUser();
            const job = event.data;

            // Dont do anything with canceled/canceling jobs, except discarded
            if (
                (state === LocalAutoLayoutState.canceling || state === LocalAutoLayoutState.canceled) &&
                job.jobStatus !== AutoLayoutStatus.discarded
            )
                return;

            if (
                job.jobStatus === AutoLayoutStatus.allocated ||
                job.jobStatus === AutoLayoutStatus.populated ||
                job.jobStatus === AutoLayoutStatus.submitted ||
                job.jobStatus === AutoLayoutStatus.ready ||
                job.jobStatus === AutoLayoutStatus.booting
            ) {
                // Maps to local "waiting" state
                queue();

                // When job is in "ready" state, we send to "/confirm" to transition it to "booting"
                if (job.jobStatus === AutoLayoutStatus.ready && currentUser) {
                    try {
                        await axios.post(AutoLayoutApi.urlFor(AutoLayoutApi.action.confirm, autoLayoutVersion), {
                            userUid: currentUser.uid,
                            jobUid: job.jobUid,
                            organizationUid,
                        });
                    } catch (err) {
                        const errorMessage = "Failed to stop auto layout job";
                        error(errorMessage);
                        logger.error(errorMessage, err);
                    }
                }
                return;
            }

            if (job.jobStatus === AutoLayoutStatus.applied) {
                applied();

                // Unsubscribe from the job and metadata
                this.unsubscribeJob();

                showSnackbar?.("applied", {});
                return;
                return;
            }

            if (job.jobStatus === AutoLayoutStatus.failed) {
                // Unsubscribe from the job and metadata
                this.unsubscribeJob();

                const totalIterationsCount =
                    usePcbEditorUiStore.getState().autoLayoutIterationData?.iterations.length ?? 0;
                if (totalIterationsCount === 0 || totalIterationsCount === 1) {
                    showSnackbar?.("genericError");
                    // When no iterations, we cannot resume the job, and there's no
                    // iterations for users to apply, so we just show error in snackbar
                    // and put back to idle state
                    reset();
                } else {
                    // We don't need to call showSnackbar in this case because we have a setup in an useEffect above
                    // to show snackbar based on local state
                    // TODO: We should prob clean this up a bit... right now we are essentially subscribing to 2 sources of truth:
                    // one is here - data direct from firestore
                    // the other is the local state
                    // Probably we should change here to just update the job in local store, and UI should ALWAYS respond to local store?
                    // Error state
                    error(job.reason);
                }
                return;
            }

            // Map intermediate states for pause/resume
            if (job.jobStatus === AutoLayoutStatus.pausing) {
                pause();
            }
            if (job.jobStatus === AutoLayoutStatus.resuming) {
                resume();
            }

            // Map paused and restarted state
            if (job.jobStatus === AutoLayoutStatus.restarted && state !== LocalAutoLayoutState.running) {
                resumed();
            }

            if (job.jobStatus === AutoLayoutStatus.paused && state !== LocalAutoLayoutState.paused) {
                paused();
            }

            if (job.jobStatus === AutoLayoutStatus.discarded) {
                // Stop the auto layout state
                canceled();

                // Unsubscribe from the job and metadata
                this.unsubscribeJob();

                showSnackbar?.("cancel");
                return;
            }

            if (job.jobStatus === AutoLayoutStatus.converged) {
                // NOTE: We dont unsubscribe to job/metadata when system-paused
                // At this stage, our backend already sent "stopJob" request to DeepPcb, and should ignore any iterations/steps
                // because the job is no longer marked as "running" on our end.
                // But we still want to receive status changes of the job in our end
                complete();
                tick(job.startTime);

                this.onJobConverged(job);
                // We don't want to early return here, since we still want to update the local
                // state with the latest iteration data
            }

            /*
             * TODO: Handle other states:
             *       Paused, Pausing, Resuming, Started, Restarted, etc.
             *       We should probably change this to an compiler-guaranteed
             *       exahaustive switch statement.
             */

            if (job.jobStatus === AutoLayoutStatus.outOfCredit) {
                noCredits();
            }

            // `resuming` is an intermediate state that will transition
            // to `restarted` if successfully resume. Here we just reduce that to "Working" state in frontend
            if (job.jobStatus === AutoLayoutStatus.started || job.jobStatus === AutoLayoutStatus.restarted) {
                // Set local state to start, and use the startTime from
                // backend to init the local startTime
                load(job.startTime);
            }

            // Handle started job, update layout with the received iterations
            try {
                // Using a promise queue to avoid race conditions from receiving multiple events at once,
                // causing the currentIterationIndex to unintentionally "un-stick" from the end
                await this.processJobIterations(job);
            } catch (err) {
                const errorMessage = "Failed to update auto layout data from board";
                error(errorMessage);
                logger.error(errorMessage, err);
            }
        }
    }
}
