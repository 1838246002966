import React from "react";
import {IntercomProvider as Intercom} from "react-use-intercom";

import {currentAgentIsBot} from "../../../../helpers/isBot";
import {useFluxServices} from "../../../../injection/hooks";
import {useCurrentUser} from "../../../../modules/auth/state/currentUser";
import {
    useCurrentOrganizationMemberships,
    useCurrentOrganizationMembershipsFinishedLoading,
} from "../../../../modules/auth/state/organization";
import {useUserPrivateMetadata} from "../../../../modules/auth/state/user";

export function useIntercomConfig() {
    const currentUser = useCurrentUser();
    const {userStorageHelper} = useFluxServices();
    const privateMetadata = useUserPrivateMetadata();
    const memberships = useCurrentOrganizationMemberships();
    const finishedLoadingMemberships = useCurrentOrganizationMembershipsFinishedLoading();

    return React.useMemo(() => {
        if (
            !currentUser ||
            currentUser.isAnonymous ||
            !currentUser?.email?.length ||
            currentAgentIsBot ||
            !finishedLoadingMemberships
        ) {
            return {};
        }
        const companies =
            memberships?.map((membership) => ({
                companyId: membership.organizationUid,
                name: membership.organization.handle,
            })) ?? [];
        return {
            userId: currentUser.uid,
            name: currentUser.handle,
            email: currentUser.email,
            hideDefaultLauncher: true,
            createdAt: Math.floor(currentUser.created_at / 1000).toString(), // Convert from milliseconds
            avatar: {type: "avatar", imageUrl: userStorageHelper.getUserPicture(currentUser)},
            companies,
            customAttributes: {
                fullName: currentUser.full_name,
                privateProjectCount: privateMetadata?.projects?.privateProjectCount,
                activeProductUid: currentUser.activeProductUid,
                role: privateMetadata?.personal?.role,
                primaryUse: privateMetadata?.personal?.primaryUse,
                readyToStart: privateMetadata?.personal?.hearAboutFlux,
                invitedEmailsCount: privateMetadata?.onboarding?.invitedEmails?.length,
                organizationMembershipCount: memberships?.length ?? 0,
            },
        };
    }, [
        currentUser,
        finishedLoadingMemberships,
        memberships,
        privateMetadata?.onboarding?.invitedEmails?.length,
        privateMetadata?.personal?.hearAboutFlux,
        privateMetadata?.personal?.primaryUse,
        privateMetadata?.personal?.role,
        privateMetadata?.projects?.privateProjectCount,
        userStorageHelper,
    ]);
}

export default function IntercomProvider({children}: {children: React.ReactNode}) {
    const appId = process.env.REACT_APP_INTERCOM_API_KEY ?? "";
    return (
        <Intercom appId={appId} apiBase={process.env.REACT_APP_INTERCOM_API_BASE}>
            {children}
        </Intercom>
    );
}
