/* eslint-disable @buildwithflux/no-hardcoded-urls */

const externalUrls = {
    documentation: {
        docs: "https://docs.flux.ai",
        tutorials: "https://docs.flux.ai/tutorials",
        projects: "https://www.flux.ai/p/projects",
        create_part_tutorial: "https://docs.flux.ai/tutorials/tutorial-add-part-library",
        expression_docs: "https://docs.flux.ai/reference/expressions-overview",
        selector_docs: "https://docs.flux.ai/reference/pcb-layout-rule-selectors",
        simulation_docs: "https://docs.flux.ai/reference/troubleshooting-the-simulator",
        write_code_docs: "https://docs.flux.ai/reference/api-overview",
        autolayout_docs: "https://docs.flux.ai/tutorials/auto-layout",
        dxf_files: "https://docs.flux.ai/reference/reference-inspector-assets#dxf",
        svg_files: "https://docs.flux.ai/reference/reference-inspector-assets#svg",
        svg_path: "https://docs.flux.ai/reference/reference-inspector-assets#svg",
        custom_layout_docs: "https://docs.flux.ai/tutorials/tutorial-board-outline-shape#advanced-custom-shapes",
        generics_docs: "https://docs.flux.ai/tutorials/tutorial-generic-part",
        setup_browser: "https://docs.flux.ai/Introduction/set-up-your-browser",
        copilot_docs: "https://docs.flux.ai/reference/copilot",
        copilot_credit_docs: "https://docs.flux.ai/reference/copilot#credits",
        organization_sso_docs: "https://docs.flux.ai/Introduction/flux-for-organizations#sso",
        organization_upgrade_docs: "https://docs.flux.ai/Introduction/flux-for-organizations#upgrade",
        copilot_import: "https://docs.flux.ai/tutorials/ai-generated-component-libraries",
        contact_sales: "https://www.flux.ai/p/contact-sales",
        jep30_missing_properties: "https://docs.flux.ai/reference/reference-inspector-properties",
        auto_layout_tutorial: "https://docs.flux.ai/tutorials/auto-layout",
        auto_layout_tutorial_video: "https://youtu.be/JlNsPRh_x-w",
    },
    legal: {
        terms: "https://docs.flux.ai/legal/terms-of-service",
        privacy: "https://docs.flux.ai/legal/privacy-statement",
    },
    marketing: {
        beta_form: "https://fluxai.typeform.com/to/Fmt7KMtu?typeform-source=www.flux.ai",
        feedback: "https://feedback.flux.ai/bugreports",
        support: "https://docs.flux.ai/Introduction/getting-support",
        // IMPORTANT: If this link is updated, be sure to configure it to never expire.
        // Configuration here: https://fluxcommunity.slack.com/admin/invites
        community_slack: "https://join.slack.com/t/fluxcommunity/shared_invite/zt-2ouyf80w6-7Wp81YlNEsuDFFdED9UQMA",
        community_slack_request_part: "https://fluxcommunity.slack.com/archives/C01VDN16S0M",
        pricing: "https://www.flux.ai/p/pricing",
        pricing_trial_faq: "https://www.flux.ai/p/pricing?id=can-i-use-flux-for-free#can-i-use-flux-for-free",
    },
};

export default externalUrls;
