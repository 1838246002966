import {BOMFormat, ColumnDefinition} from "./base";

/**
 * PCBWay BOM format
 * https://www.pcbway.com/assembly-file-requirements.html
 */
export class PCBWayFormat extends BOMFormat {
    static override manufacturer = "PCBWay";
    static override columns: Record<string, ColumnDefinition> = {
        "Item #": (_item, rowNumber) => `${rowNumber}`, // not sure if this should be sequential number or a unique ID
        Designator: (item) => item.designators.join(","),
        Qty: "quantity",
        Manufacturer: "manufacturer",
        "Mfg Part #": "mpn",
        "Description / Value": (item) => item.specification.join(", "),
        "Package/Footprint": "package",
        Type: "mountType",
        "Your Instructions / Notes": "notes",
    };
}
