import {DocumentSubcollectionField, FluxErrorCodes, headVersionName} from "@buildwithflux/constants";
/* eslint-disable id-blacklist */
/* eslint-disable import/no-unused-modules */
import {
    FluxBaseError,
    getCollectionChangesFromPatchByOp,
    hasBaseDocumentFieldsInHeadVersionChanged,
    IDocumentData,
    IElementsMap,
    isSystemPart,
    nestedNodeDelimiter,
    SnapshotLibrary,
} from "@buildwithflux/core";
import {ClientFunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";
import {ClientFirestoreAdapter, deepCopy} from "@buildwithflux/firestore-compatibility-layer";
import {
    DocumentUid,
    IBaseMixin,
    intoBaseDocument,
    IPartData,
    ISubDocumentData,
    IVertexMap,
    UserUid,
} from "@buildwithflux/models";
import {ClientIdProvider, DocumentRepository} from "@buildwithflux/repositories";
import {isDevEnv, Logger} from "@buildwithflux/shared";
import {diff} from "deep-diff";
import type firebase from "firebase/compat/app";
import {chain, intersection, isEqual, keyBy, mapKeys} from "lodash";
import omit from "lodash/omit";

import {useFeatureFlags} from "../common/hooks/featureFlags/useFeatureFlag";

import {AnalyticsStorage} from "./AnalyticsStorage";
import {BaseStorage} from "./BaseStorage";
import {DeprecatedTrackingEvents} from "./common/DeprecatedTrackingEvents";
import {FluxLogger} from "./connectors/LogConnector";
import {PartStorage} from "./PartStorage";

export interface DocumentStorage {
    deleteDocument(documentUid: DocumentUid): Promise<void>;

    /**
     * Creates new document data
     */
    createDocument(rawDocumentData: Readonly<IDocumentData>): Promise<void>;

    /**
     * Saves mutated document data that was previously loaded with loadDocument
     */
    saveDocumentData(rawDocumentData: Readonly<IDocumentData>): Promise<void>;

    /**
     * Loads document data from Firestore, mutates it with various ad-hoc migrations (slow, because it calls a backend
     * function), saves a deep clone of the data to an internal cache, and returns it.
     */
    loadDocument(documentUid: DocumentUid, currentUserUid: UserUid | undefined): Promise<IDocumentData>;

    setArchived(documentIdentity: IBaseMixin): Promise<void>;

    getDocumentCountForOwner(ownerUid: UserUid): Promise<number>;

    saveConfigs(documentData: IDocumentData): Promise<void>;

    getAllSubDocumentData(document: IDocumentData, initialPrefix?: string): Promise<ISubDocumentData>;
}

export class FirestoreDocumentStorage extends BaseStorage implements DocumentStorage {
    /**
     * The document cache: a set of deeply-cloned full documents stored in
     * memory, and later used to compare against the document being saved to
     * find a set of differences to actually persist
     *
     * QUESTION: is this cache ever cleared? what is the memory cost? why not
     * use a WeakMap?
     */
    private documentsCache = new Map<DocumentUid, IDocumentData>();

    private getFallbackSnapshotDuration = 0;
    private getPartCache = new Map<string, IPartData | undefined>();

    constructor(
        private documentRepository: DocumentRepository,
        logger: Logger,
        private functionsAdapter: ClientFunctionsAdapter,
        private analyticsStorage: AnalyticsStorage,
        firestore: firebase.firestore.Firestore,
        firestoreAdapter: ClientFirestoreAdapter,
        clientIdProvider: ClientIdProvider,
        private snapshotLibrary: SnapshotLibrary,
        private partStorage: PartStorage,
    ) {
        super(firestore, firestoreAdapter, logger, clientIdProvider);
        this.documentsCache = new Map<string, IDocumentData>();
    }

    public async saveDocumentData(rawDocumentData: Readonly<IDocumentData>) {
        // QUESTION: why isn't this ommited in intoBaseDocument like other fields?
        const documentData = omit(rawDocumentData, ["selectedObjectUids"]);

        // To be extra safe, freeze documentData so it cannot be mutated during
        // save or when in the last saved doc cache
        if (isDevEnv()) {
            this.shallowFreeze(documentData);
        }

        const previouslySavedDataFromCache = this.documentsCache.get(documentData.uid);

        if (!previouslySavedDataFromCache) {
            throw new Error(
                "Previous document data not found in cache - this implies a save of data via DocumentStorage that was not loaded using DocumentStorage.loadDocument",
            );
        }

        await this.documentRepository.save(documentData, undefined, previouslySavedDataFromCache);
        this.updateLocalLastSavedDocumentCache(documentData);
        await this.syncHeadVersion(documentData);

        if (useFeatureFlags.getState().createSnapshotOnSave ?? false) {
            try {
                await this.functionsAdapter.saveDocumentSnapshot({documentUid: documentData.uid});
            } catch (error) {
                FluxLogger.captureError(new Error(`Error saving snapshot for document ${documentData.uid}: ${error}`), {
                    errorKey: `error-save-snapshot`,
                });
            }
        }
    }

    public async createDocument(rawDocumentData: Readonly<IDocumentData>): Promise<void> {
        const documentData = omit(rawDocumentData, ["selectedObjectUids"]);

        // To be extra safe, freeze documentData so it cannot be mutated during
        // save or when in the last saved doc cache
        if (isDevEnv()) {
            this.shallowFreeze(documentData);
        }

        await this.documentRepository.save(documentData);
        this.updateLocalLastSavedDocumentCache(documentData);
        await this.syncHeadVersion(documentData);

        if (useFeatureFlags.getState().createSnapshotOnSave ?? false) {
            try {
                await this.functionsAdapter.saveDocumentSnapshot({documentUid: documentData.uid});
            } catch (error) {
                FluxLogger.captureError(new Error(`Error saving snapshot for document ${documentData.uid}: ${error}`), {
                    errorKey: `error-save-snapshot`,
                });
            }
        }
    }

    public async deleteDocument(documentUid: string) {
        const document = await this.documentRepository.getDataWithoutMigrations(documentUid);
        if (!document) return;
        if (document.belongs_to_part_uid && !document.localToDocumentUid) {
            throw new Error("Documents that are published as library parts cannot be deleted, only archived");
        }

        await Promise.all(
            Object.values(document?.elements ?? {})
                .filter(
                    (element) =>
                        element.part_version_data_cache.localToDocumentUid === documentUid &&
                        element.part_version_data_cache.document_import_uid,
                )
                .map((e) => this.deleteDocument(e.part_version_data_cache.document_import_uid!)),
        );

        // TODO This is buggy since it does not delete the subcollections in this document. (4y old comment, moved from elsewhere; untested, probably still true?)
        await this.firestoreAdapter.document(documentUid).delete();

        if (!document.localToDocumentUid) {
            // Only log deletion of top-level document, otherwise we'd get multiple logs per deletion
            await this.analyticsStorage.logEvent(DeprecatedTrackingEvents.deleteDocument, {
                content_type: "document",
                content_id: documentUid,
            });
        }
    }

    /**
     * @todo No action record creation here, so how does another copy of the
     * state get notified that it's now archived?
     *
     * @deprecated use DocumentRepository.update instead
     */
    public async setArchived(documentIdentity: IBaseMixin) {
        this.analyticsStorage.logEvent(DeprecatedTrackingEvents.archiveDocument, {
            content_type: "document",
            content_id: documentIdentity.uid,
        });

        await this.firestoreAdapter.document(documentIdentity.uid).update({
            "documentData.archived": true,
            "documentData.slug": documentIdentity.uid,
        });

        const cached = this.documentsCache.get(documentIdentity.uid);

        if (cached) {
            cached.archived = true;
        }
    }

    /**
     * @deprecated If you can, directly call .get*() on the document repository
     *             - this is the only place that calls
     *             HACK_patchMissingButRequiredNodeData and gets subDocumentData though
     */
    public async loadDocument(documentUid: string, currentUserUid: UserUid): Promise<IDocumentData> {
        let document: IDocumentData | undefined = undefined;

        const startTime = new Date().getTime();

        // Try to get a pre-cached snapshot from GCS
        if (useFeatureFlags.getState().createSnapshotOnSave ?? false) {
            // NOTE: we could just get the HEAD version snapshot but it could be inaccurate
            const baseDocumentData = await this.documentRepository.getBaseDataWithoutMigrations(documentUid);
            const documentVersion = baseDocumentData?.latestActionRecordUid;
            if (documentVersion) {
                document = await this.snapshotLibrary.get({
                    documentUid,
                    documentVersion,
                    // NOTE: important that this value is synced with
                    // StaticPartVersionSerde or any place that generates snapshots
                    schemaTag: "v0",
                });
                if (document) {
                    const snapshotBaseDocument = intoBaseDocument(document);
                    // NOTE: We also omit layerViewConfig because of false typing. See intoBaseDocument.
                    // QUESTION: should we omit "__metadata" inside snapshotLibrary.get?
                    const firestoreBaseDocument = omit(baseDocumentData, "layerViewConfig", "__metadata");
                    // NOTE: Counts like comment_count are currently updated in
                    // the backend by cloud function, not by the frontend. So if
                    // the snapshot is created before appropriate counts are
                    // recomputed, it could be stale. This has been observed to
                    // happen with some frequency. See for example
                    // https://buildwithflux.sentry.io/issues/5768714977/events/b71ec1abb7974ce0bceb6b10ce7f8a85/?project=5669122
                    if (!isEqual(snapshotBaseDocument, firestoreBaseDocument)) {
                        const jsonDiff = JSON.stringify(diff(snapshotBaseDocument, firestoreBaseDocument));
                        FluxLogger.captureError(
                            new Error(
                                `Snapshot base document data is different from Firestore base document data: ${documentUid}/${documentVersion} by diff ${jsonDiff}. Ignoring snaphot.`,
                            ),
                        );
                        document = undefined;
                    }
                }
            }
        }

        // Fallback on Firestore
        if (!document) {
            document = await this.documentRepository.getDataForCurrentUser(documentUid, currentUserUid);
        }

        // eslint-disable-next-line no-console
        console.info("FLUX-PERF: Load document data took", Math.round(new Date().getTime() - startTime), "ms");

        if (!document) {
            // Previously would have been thrown from FireaseDocumentHelpers.getBaseDocument()
            throw new Error("Failed to get base document");
        }

        document.subDocumentData = await this.getAllSubDocumentData(document);

        await this.HACK_patchMissingButRequiredNodeData(document); // Mutates the document

        this.updateLocalLastSavedDocumentCache(deepCopy(document));

        return document;
    }

    /**
     * This method only returns an accurate count for the signed in user.
     *
     * @deprecated Loads all documents in memory to count them
     */
    public async getDocumentCountForOwner(ownerUid: string) {
        const query = await this.firestoreAdapter
            .documentCollection()
            .where("documentData.owner_uid", "==", ownerUid)
            .get();
        return query.size;
    }

    /**
     * Saves just the configs subcollection of the given hydrated document state
     *
     * Uses the diff-cache as a reference to detect what doesn't need to be written
     */
    public async saveConfigs(documentData: IDocumentData): Promise<void> {
        await this.documentRepository.save(documentData, ["configs"], this.documentsCache.get(documentData.uid), false);
        this.partiallyUpdateDiffCache(documentData, "configs");
    }

    /**
     * Gets all elements and vertices that are included as parts in the given
     * document, recursively, into flat collections of elements and route
     * vertices. The returned collections do NOT include the given document's
     * elements and vertices.
     *
     * The keys of the returned IElementsMap are prefixed with parent element
     * IDs in the standard format. The keys of the returned IVertexMap are
     * likewise prefixed, and the IDs within each vertex are also prefixed.
     *
     * The initialPrefix should be empty when called with the top-level
     * document. It should be the element ID of the containing element when
     * called with a sub-document.
     *
     * NOTE: The returned data is equivalent to the old flattenedSubjects and
     * subDocumentData from getSimInitData, though not identical.
     *
     * NOTE: this function does not validate that:
     *
     *   elements[vertex.terminal.element_uid]
     *
     * actually exists, although that is logged in generateNetInstances.
     */
    public async getAllSubDocumentData(document: IDocumentData, initialPrefix = ""): Promise<ISubDocumentData> {
        const start = Date.now();
        this.getPartCache.clear();

        const elements: IElementsMap = {};
        const routeVertices: IVertexMap = {};
        // QUESTION: would this be any faster using Promise.all?
        // NOTE: I tried Promise.allSettled for perf, BFS instead of DFS, but it was
        // slightly slower on HTTP cached data, not sure why.
        for await (const [prefix, subDoc] of this.yieldAllSubDocuments(document, initialPrefix)) {
            Object.assign(
                elements,
                mapKeys(subDoc.elements, (_e, uid) => (prefix ? `${prefix}${nestedNodeDelimiter}${uid}` : uid)),
            );

            Object.assign(
                routeVertices,
                chain(subDoc.routeVertices)
                    .mapKeys((vertex) => (prefix ? `${prefix}${nestedNodeDelimiter}${vertex.id}` : vertex.id))
                    .mapValues((vertex, id) => {
                        const connectedVertices = mapKeys(vertex.connectedVertices, (_v, k) =>
                            prefix ? `${prefix}${nestedNodeDelimiter}${k}` : k,
                        );

                        const terminal = vertex.terminal
                            ? {
                                  ...vertex.terminal,
                                  element_uid: prefix
                                      ? `${prefix}${nestedNodeDelimiter}${vertex.terminal.element_uid}`
                                      : vertex.terminal.element_uid,
                              }
                            : undefined;

                        return {
                            ...vertex,
                            id,
                            connectedVertices,
                            terminal,
                        };
                    })
                    .value(),
            );
        }
        // eslint-disable-next-line no-console
        console.info("FLUX-PERF: getFallbackSnapshot took", Math.round(this.getFallbackSnapshotDuration), "ms");

        // eslint-disable-next-line no-console
        console.info(
            "FLUX-PERF: yieldAllSubDocuments took",
            Math.round(Date.now() - start - this.getFallbackSnapshotDuration),
            "ms",
        );

        // NOTE: Sanity check for disjoint sets
        if (isDevEnv()) {
            const intersectElements = intersection(Object.keys(elements), Object.keys(document.elements));
            if (intersectElements.length) {
                throw new Error(
                    `Some sub-document prefixed element IDs are in document.elements: ${intersectElements}`,
                );
            }
            const intersectRouteVertices = intersection(
                Object.keys(routeVertices),
                Object.keys(document.routeVertices),
            );
            if (intersectRouteVertices.length) {
                throw new Error(
                    `Some sub-document prefixed routeVertices IDs are in document.routeVertices: ${intersectRouteVertices}`,
                );
            }
        }

        return {elements, routeVertices};
    }

    private shallowFreeze(documentData: IDocumentData) {
        // Freeze properties before freezing self
        for (const value of Object.values(documentData)) {
            if (value && typeof value === "object") {
                Object.freeze(value);
            }
        }

        return Object.freeze(documentData);
    }

    /**
     * QUESTION: is this still used? It's not clear what it's for
     * @deprecated
     */
    private async syncHeadVersion(document: IDocumentData): Promise<void> {
        if (!document.belongs_to_part_uid) return;

        const latestPatch = document?.latestPatch;
        if (!latestPatch) {
            // We cannot process without latestPatch. Silently fail in this case, because this
            // function is called by `saveDocumentData` and `saveDocumentData` can be called in various
            // scenarios such as create/import, where we might not have latest patch
            this.logger.debug("No patch in document data, and hence skipping syncHeadVersion task");
            return;
        }

        const signals: Promise<void>[] = [];

        if (hasBaseDocumentFieldsInHeadVersionChanged(latestPatch)) {
            signals.push(
                this.documentRepository.sendSyncHeadVersionSignal(
                    document.uid,
                    document.belongs_to_part_uid,
                    "baseDocument",
                ),
            );
        }

        const collectionChanges = getCollectionChangesFromPatchByOp(latestPatch, "forward");

        if (collectionChanges.pcbLayoutNodes) {
            signals.push(
                this.documentRepository.sendSyncHeadVersionSignal(
                    document.uid,
                    document.belongs_to_part_uid,
                    "pcbNodes",
                ),
            );
        }
        if (collectionChanges.elements) {
            signals.push(
                this.documentRepository.sendSyncHeadVersionSignal(
                    document.uid,
                    document.belongs_to_part_uid,
                    "elements",
                ),
            );
        }
        if (collectionChanges.properties) {
            signals.push(
                this.documentRepository.sendSyncHeadVersionSignal(
                    document.uid,
                    document.belongs_to_part_uid,
                    "properties",
                ),
            );
        }
        if (collectionChanges.assets) {
            signals.push(
                this.documentRepository.sendSyncHeadVersionSignal(document.uid, document.belongs_to_part_uid, "assets"),
            );
        }
        if (collectionChanges.pcbLayoutRuleSets) {
            signals.push(
                this.documentRepository.sendSyncHeadVersionSignal(
                    document.uid,
                    document.belongs_to_part_uid,
                    "pcbRuleSets",
                ),
            );
        }

        await Promise.all(signals);
    }

    /**
     * Deliberately leaving this method here - out of place, and with HACK
     * prefix as an eye-sore to prompt a review and solution for
     * validation/incomplete document data to be implemented!
     */
    private async HACK_patchMissingButRequiredNodeData(document: IDocumentData) {
        let seenError = false;
        for (const key in document.pcbLayoutNodes) {
            const node = document.pcbLayoutNodes[key]!;

            // TODO: remove this once validation solution in place. This is a top-level data patch
            //       which ensures required data is present in the event that it (unexpectedly and erroneously)
            //       arrives as undefined
            // TODO: data validation review. question: why is node.pcbNodeRuleSet
            //       (somewhat consistently) undefined? it's expected to be present as per our
            //       typing and yet occasionally it's not - which is leading to runtime crashes.
            if (!node.pcbNodeRuleSet) {
                node.pcbNodeRuleSet = {};
                if (seenError === false) {
                    FluxLogger.captureError(
                        new DocumentStorageError(
                            `Invalid document state: pcbNodeRuleSet undefined`,
                            document.uid,
                            node.uid,
                        ),
                    );
                    seenError = true;
                }
            }
        }
    }

    private updateLocalLastSavedDocumentCache(documentData: IDocumentData) {
        this.documentsCache.set(documentData.uid, documentData);
    }

    /**
     * Sets a single field of the diff cache to its value from the provided "new" documentData
     *
     * This is a way to merge data into part of the cached document after submitting a matching partial update mutation
     * to Firestore. Ideally, you'd only submit acknowledged writes to the diff-cache, but because we sometimes rely on
     * its contents to regenerate writes, you may produce less duplicate writes by always optimistically applying updates
     * and removing them if the write is rejected (TODO)
     *
     * Note that this method is synchronous and won't be interrupted: there's no reason to use a mutex
     */
    private partiallyUpdateDiffCache(documentData: IDocumentData, field: keyof IDocumentData) {
        const cached = this.documentsCache.get(documentData.uid);

        if (cached === undefined) {
            // Partial updates of non-existing cache entries are skipped (too much risk of things probing exists on this map, and assuming a full IDocumentData)
            return;
        }

        this.documentsCache.set(documentData.uid, {...cached, [field]: deepCopy(documentData[field])});
    }

    // QUESTION: should we move this method to the snapshot library itself?
    private async *yieldAllSubDocuments(document: IDocumentData, prefix = ""): AsyncGenerator<[string, IDocumentData]> {
        // NOTE: Historically, part documents were created that included
        // themselves for a kind of "preview" of the published part. For
        // snapshots, what we want generally is a DAG, but that is not enforced
        // here, and is likely very rare to have cycles beyond self-inclusion.
        // We do allow the current doc to include itself as a part once, when no
        // prefix. For more discussion, see
        // https://buildwithflux.workplace.com/groups/291253750161697/posts/340449055242166/
        // . We need to filter these out first.
        const filteredElements = Object.values(document.elements).filter((element) => {
            const {document_import_uid, part_uid} = element.part_version_data_cache ?? {};
            if (document_import_uid === document.uid || part_uid === document.belongs_to_part_uid) {
                return false;
            }
            return true;
        });

        if (isDevEnv() && filteredElements.length !== Object.values(document.elements).length) {
            // eslint-disable-next-line no-console
            console.warn(`Filtered out some sub-documents that were self-included`);
        }

        // Yield the filtered document data back to getAllSubDocumentData if it
        // is an actual sub-document
        if (prefix) {
            yield [prefix, {...document, elements: keyBy(filteredElements, "uid")}];
        }

        for (const element of filteredElements) {
            const {document_import_uid, version, name, part_uid} = element.part_version_data_cache ?? {};

            if (!document_import_uid) {
                // TODO: create documents for remanining system parts that don't
                // have them like Terminal 835bf3e1-33dd-41bb-a166-c0a31607d645
                if (isDevEnv() && !isSystemPart(part_uid)) {
                    // eslint-disable-next-line no-console
                    console.warn(
                        `No document_import_uid in ${
                            isSystemPart(part_uid) ? "system" : "non-system"
                        } part ${name} ${part_uid} version ${version}. Skipping recursion.`,
                    );
                }
                continue;
            }

            const descriptor = {
                documentUid: document_import_uid,
                documentVersion: version,
                // NOTE: important that this value is synced with
                // StaticPartVersionSerde or any place that generates snapshots
                schemaTag: "v0",
            };
            let snapshot: IDocumentData | undefined;
            try {
                snapshot = await this.snapshotLibrary.get(descriptor);
                if (!snapshot) {
                    snapshot = await this.getFallbackSnapshot(
                        document_import_uid,
                        version,
                        part_uid,
                        name,
                        this.partStorage,
                    );
                }
            } catch (error) {
                FluxLogger.captureError(
                    new Error(
                        `Error getting snapshot for part version ${name} with descriptor ${JSON.stringify(
                            descriptor,
                        )}: ${error}`,
                    ),
                    {errorKey: `error-subdoc-${document_import_uid}`},
                );
            }

            if (snapshot) {
                yield* this.yieldAllSubDocuments(
                    snapshot,
                    prefix ? `${prefix}${nestedNodeDelimiter}${element.uid}` : element.uid,
                );
            } else {
                FluxLogger.captureError(
                    new Error(
                        `No snapshot found for part version ${name} with descriptor ${JSON.stringify(descriptor)}`,
                    ),
                    // NOTE: dedupe to avoid log noise
                    {errorKey: `missing-subdoc-${document_import_uid}`},
                );
            }
        }
    }

    /**
     * Try to get an alternate version for a snapshot when the original version
     * is the mutable HEAD version.
     *
     * TODO: some kind of data migration so all parts have a immutable
     * latest_version
     */
    private async getFallbackSnapshot(
        documentUid: string,
        version: string,
        part_uid: string,
        name: string,
        partStorage: PartStorage,
    ) {
        const start = Date.now();

        // NOTE: in test projects, this cache reduced time taken from 20s to 0.1s
        let part: IPartData | undefined;
        let cached = false;
        if (this.getPartCache.has(part_uid)) {
            part = this.getPartCache.get(part_uid);
            cached = true;
        } else {
            part = await partStorage.getPartByUid(part_uid);
            this.getPartCache.set(part_uid, part);
        }

        this.getFallbackSnapshotDuration += Date.now() - start;

        if (!part) {
            FluxLogger.captureError(
                new Error(`No part found for ${name} ${part_uid} for determining fallback snapshot`),
                {
                    errorKey: `missing-part-${part_uid}`,
                },
            );
            return undefined;
        }

        const snapshot = await this.snapshotLibrary.get({
            documentUid,
            documentVersion: part.latest_version,
            schemaTag: "v0",
        });
        if (snapshot) {
            if (isDevEnv() && !cached) {
                // eslint-disable-next-line no-console
                console.info(
                    `Fallback snapshot found for part version ${name} ${version} with latest version ${part.latest_version}`,
                );
            }
            return snapshot;
        }

        const headSnapshot = await this.snapshotLibrary.get({
            documentUid,
            documentVersion: headVersionName,
            schemaTag: "v0",
        });
        if (headSnapshot) {
            if (isDevEnv()) {
                // eslint-disable-next-line no-console
                console.info(
                    `Fallback snapshot not found for part version ${name} ${version}, using ${headVersionName} version`,
                );
            }
            return headSnapshot;
        }
    }
}

class DocumentStorageError extends FluxBaseError {
    constructor(message: string, public readonly documentUid: string, public readonly pcbLayoutNodeUid?: string) {
        super(FluxErrorCodes.StorageError, message);
    }
}

export class MockDocumentStorage implements DocumentStorage {
    async deleteDocument(_documentUid: DocumentUid): Promise<void> {
        throw new Error("unimplemented");
    }

    async createDocument(_rawDocumentData: Readonly<IDocumentData>): Promise<void> {
        throw new Error("Method not implemented.");
    }

    async saveDocumentData(
        _rawDocumentData: Readonly<IDocumentData>,
        _collectionsToSave?: Readonly<DocumentSubcollectionField[]> | undefined,
    ): Promise<void> {
        throw new Error("unimplemented");
    }

    async loadDocument(_documentUid: DocumentUid, _currentUserUid?: UserUid): Promise<IDocumentData> {
        throw new Error("unimplemented");
    }

    async setArchived(_documentIdentity: IBaseMixin): Promise<void> {
        throw new Error("unimplemented");
    }

    async getDocumentCountForOwner(_ownerUid: UserUid): Promise<number> {
        throw new Error("unimplemented");
    }

    async saveConfigs(_documentData: IDocumentData): Promise<void> {
        throw new Error("unimplemented");
    }

    async getAllSubDocumentData(_document: IDocumentData): Promise<ISubDocumentData> {
        throw new Error("unimplemented");
    }
}
