import {Routes} from "@buildwithflux/core";
import {areWeInStorybook} from "@buildwithflux/shared";
import React, {Suspense, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

import {closeAllDialogs, DialogType} from "../../../../redux/reducers/dialogs";
import selectors from "../../../../redux/selectors";
import {AutoLayoutAnnouncementDialog} from "../../../announcements/auto_layout_announcement_dialog/AutoLayoutAnnouncementDialog";
import {lazyRetry} from "../lazyLoader";

const ImportPartDialog = lazyRetry(
    () => import("../../../navigation_bar/actions_menu/dialogs/part_import_dialog/ImportPartDialog"),
);
const ImportDatasheetPartDialog = lazyRetry(
    () => import("../../../navigation_bar/actions_menu/dialogs/datasheet_dialog/ImportDatasheetPartDialog"),
);
const PartUpdatesDialog = lazyRetry(
    () => import("../../../navigation_bar/actions_menu/dialogs/part_updates/PartUpdatesDialog"),
);
const PublishPartDialog = lazyRetry(
    () => import("../../../navigation_bar/actions_menu/dialogs/publish_part_dialog/PublishPartDialog"),
);
const OrganizationCreateDialog = lazyRetry(
    () => import("../../../navigation_bar/account_menu/dialogs/OrganizationCreateDialog"),
);
const OrganizationAddMembersDialog = lazyRetry(
    () => import("../../../pages/profile/dialogs/OrganizationAddMembersDialog"),
);
const ShadowbanDialog = lazyRetry(() => import("../../../navigation_bar/actions_menu/dialogs/ShadowbanDialog"));
const JEP30Dialog = lazyRetry(() => import("../../../navigation_bar/actions_menu/dialogs/JEP30Dialog"));
const ApiKeysDialog = lazyRetry(() => import("../../../navigation_bar/account_menu/dialogs/ApiKeysDialog"));
const UserPlansAndPaymentsDialog = lazyRetry(
    () => import("../../../navigation_bar/account_menu/dialogs/UserPlansAndPaymentsDialog"),
);
const FollowersDialog = lazyRetry(() => import("../../../pages/profile/dialogs/FollowersDialog"));
const FollowingDialog = lazyRetry(() => import("../../../pages/profile/dialogs/FollowingDialog"));
const NewProjectDialog = lazyRetry(() => import("../../../new_project_dialog/NewProjectDialog"));
const ChoosePlanDialog = lazyRetry(() => import("./ChoosePlanDialog"));
const RestoreProjectDialog = lazyRetry(() => import("./RestoreProjectDialog"));

const routesThatOpenDialogs = [Routes.CREATE_ORGANIZATION] as string[];

/**
 * If we had scenarios where the same dialogs to be opened more than once, we should use another value
 * for `key`; also if that is the case, we should use an unique identifier to close the specific modal.
 *
 * Please note that the same type of modals CAN be opened more than once with current setup, but that is
 * rarely the case in our app.
 **/
function Dialogs() {
    const dispatch = useDispatch();
    const location = useLocation();

    // Redux states:
    const openedDialogs = useSelector(selectors.dialogs.selectOpenDialog);

    // Close all dialogs on page navigation.
    // Ref: https://reactrouter.com/en/main/hooks/use-location
    useEffect(() => {
        // ignore specific "dialog" routes
        if (routesThatOpenDialogs.includes(location.pathname)) return;

        // ignore during testing
        if (areWeInStorybook()) return;

        dispatch(closeAllDialogs());
    }, [dispatch, location]);

    return (
        <Suspense>
            {openedDialogs.map((dialog) => {
                // only the first add_rule_dialog of a type will be opened.
                switch (dialog.type) {
                    case DialogType.IMPORT_PART:
                        return <ImportPartDialog key={dialog.type} />;
                    case DialogType.IMPORT_DATASHEET_PART:
                        return <ImportDatasheetPartDialog key={dialog.type} />;
                    case DialogType.PUBLISH_PART:
                        return <PublishPartDialog key={dialog.type} />;
                    case DialogType.PART_UPDATES:
                        return <PartUpdatesDialog key={dialog.type} />;
                    case DialogType.CREATE_ORGANIZATION:
                        return <OrganizationCreateDialog key={dialog.type} />;
                    case DialogType.ORGANIZATION_ADD_MEMBERS:
                        return <OrganizationAddMembersDialog key={dialog.type} />;
                    case DialogType.SHADOWBAN:
                        return <ShadowbanDialog key={dialog.type} />;
                    case DialogType.USER_PLANS_AND_PAYMENTS:
                        return <UserPlansAndPaymentsDialog key={dialog.type} />;
                    case DialogType.FOLLOWERS:
                        return <FollowersDialog key={dialog.type} />;
                    case DialogType.FOLLOWING:
                        return <FollowingDialog key={dialog.type} />;
                    case DialogType.NEW_PROJECT:
                        return <NewProjectDialog key={dialog.type} />;
                    case DialogType.CHOOSE_YOUR_PLAN:
                        return <ChoosePlanDialog key={dialog.type} />;
                    case DialogType.RESTORE_PROJECT:
                        return <RestoreProjectDialog key={dialog.type} />;
                    case DialogType.JEP30:
                        return <JEP30Dialog key={dialog.type} />;
                    case DialogType.API_KEYS:
                        return <ApiKeysDialog key={dialog.type} />;
                    case DialogType.AUTO_LAYOUT_ANNOUNCEMENT:
                        return <AutoLayoutAnnouncementDialog key={dialog.type} serveAs="info" />;
                    default:
                        throw new Error("Unknown dialog");
                }
            })}
        </Suspense>
    );
}

export default React.memo(Dialogs);
