import {pcbRuleImportantString} from "@buildwithflux/core";

export const defaultPinType = "Unspecified";

const strings = {
    app: {
        name: "Flux",
        name_with_tld: "Flux.ai",
        tag_line: "Build circuits. Write code.",
        description:
            "We help hardware teams build better products through modern collaboration, live simulation, and a community library of parts.",
        description2:
            "Flux is where engineers shape the future of hardware, together. Contribute to the open source community, manage your projects, review schematics like a pro, track issues and features, power your workflows, and secure work before you commit it.",
        flux_twitter_handle: "buildwithflux",
        network_error_prompt:
            "Flux is having trouble connecting to the internet and some of your last edits weren't saved yet. Flux will attempt to reconnect in",
        software_rendering_prompt: "Flux may be slow because of a graphics hardware acceleration issue.",
        cannot_save_documument_message:
            "Something bad happened and Flux cannot save your work. Flux’s team has been notified and will work on a fix ASAP. Optionally, you can file a support ticket.",
        enterprise_concierge_email: "enterprise-concierge@flux.ai",
    },
    signin: {
        errors: {
            email: {
                invalid: "Email is invalid",
                blank: "Email can't be blank!",
            },
            password: {
                tooShort: "Password is too short",
                blank: "Password can't be blank!",
            },
            submission: {
                incorrectEmailOrPassword: "Incorrect email or password",
                signUpWithOtherCredentials:
                    "It looks like you signed up with a different set of credentials. Try signing in one of the other ways.",
                userSignedIn:
                    "You are already signed in, please try signing out and signing in again, or contact us for help.",
                popupBlocked: "The authentication popop was blocked. Please allow popups for flux.ai and try again.",
                notSignedUp: "It looks like you haven't signed up yet. Please sign up first.",
                generic: "An error occurred during signin, please try again or contact us.",
            },
        },
    },
    signup: {
        title: "Create your Flux account",
        subTitle: "Create a free account to start building hardware together with anyone from anywhere.",
        usernameTitle: "Choose a username",
        usernameSubTitle: "To start using Flux, please choose a username",
        loadingMessage: "Creating your account",
        loadingPopUpMessage: "Waiting for an authentication pop-up",
        errors: {
            username: {
                alreadyTaken: "Username is already taken",
                tooShort: "Username is too short",
                invalidCharacters:
                    "Username may only contain alphanumeric characters or single hyphens, and cannot begin or end with a hyphen.",
                blank: "Username can't be blank!",
            },
            email: {
                invalid: "Email is invalid",
                blank: "Email can't be blank!",
            },
            password: {
                tooShort: "Password is too short",
                blank: "Password can't be blank!",
            },
            submission: {
                emailAlreadyInUse:
                    "There's already a Flux account associated with that email address, using a different sign in method.",
                credentialsAlreadyInUse: "Those credentials are already in use by a Flux account.",
                popupBlocked: "The authentication pop-up was blocked. Please allow pop-ups for flux.ai and try again.",
                popupClosedByUser: "The authentication pop-up was closed. Please try again.",
                authenticationCancelled:
                    "The authentication was cancelled - this usually happens when you don't grant access to the Flux application. Please try again.",
                generic: "An error occurred during signup, please try again or contact us.",
            },
        },
    },
    validation_message: {
        required: "Required",
    },
    inspector: {
        document: {
            info_section: {
                title: "About",
                description: "Description",
                descriptionPlaceholder: "Description",
                created: "Created",
                lastUpdated: "Last updated by ",
                lastPublished: "Last published ",
                forkOf: "Forked from",
                copyOf: "Cloned from",
                contributors: "Contributor(s)",
            },
            simulator_section: {
                title: "Simulation",
            },
            properties_section: {
                title: "Properties",
            },
            pcb_layout_rules_section: {
                title: "Layout Rules",
            },
            pcb_computed_info_section: {
                title: "Computed Info",
            },
        },
        action_panel: {
            rotate: "Rotate Selected Element",
            flip: "Flip Selected Element",
            convert: "Convert Selected Element to Component",
            edit: "Edit Selected Component",
            delete: "Delete Selected Element",
        },
        empty_state: {
            content: "Select an element on the canvas to activate this panel.",
        },
        layout_section: {
            title: "Layout",
            x_schematic_position_label: "X",
            y_schematic_position_label: "Y",
            x_pcb_position_label: "X mm",
            y_pcb_position_label: "Y mm",
        },
        properties_section: {
            title: "Properties",
        },
        pcb_layout_rules_section: {
            title: "Layout Rules",
        },
        info_section: {
            title: "Entity Info",
        },
        part_section: {
            title: "About",
            name_input: "Component Name",
            description_input: "Description",
            created_at_input: "Created at",
            published_at: "Last published by author ",
            node_type: "Object Type",
            flux_object_id: "Object ID",
            connected_components: "Connected Components",
            copy_paste_tooltip: "ID copied to clipboard",
        },
        pcb_layout_node_section: {
            title: "About",
        },
        simulation_panel: {
            title: "Simulation",
        },
        controls_panel: {
            title: "Controls",
            default_control_name: "New control",
            default_position_name: "New position",
        },
        controls_simulation_panel: {
            title: "Controls",
        },
        position_on_symbol: {
            title: "Position on Symbol",
        },
        assets: {
            title: "Assets",
            symbol: "Symbol",
            thumbnail: "Thumbnail",
        },
    },
    parts_browser: {
        empty_state: {
            content: "Sorry, no one has added the item you're searching for yet.",
            create_part_content: "Learn how to add it yourself",
            request_community_part: "Or you could ask the community for help in the",
            request_community_part_channel: "#request-a-part",
            request_community_part_channel_prefix: "channel on Slack.",
        },
    },
    editor: {
        zoom_controls: {
            zoom_in: "Zoom In",
            zoom_out: "Zoom Out",
            zoom_to_fit: "Zoom To Fit",
        },
        mode_controls: {
            two_d: "2D",
            two_d_label: "Switch to 2D",
            three_d: "3D",
            three_d_label: "Switch to 3D",
            show_grid: "Grid",
            show_grid_label: "Grid",
            auto_layout_controls_start_label: "Start Auto-Layouting",
            auto_layout_controls_resume_label: "Resume Auto-Layouting",
            auto_layout_controls_pause_label: "Pause Auto-Layouting",
            auto_layout_controls_apply_label: "Apply Auto-Layouting",
            auto_layout_controls_cancel_label: "Cancel Auto-Layouting",
            auto_layout_controls_feedback_label: "Auto-Layout Feedback",
            front: "Top",
            back: "Bottom",
            flip_to_front: "Flip Viewport To Front",
            flip_to_back: "Flip Viewport To Back",
        },
        nav_bar: {
            document_name_editor: {
                placeholder_content: "Enter Project Name",
            },
            action_menu: {
                new_project: "New Project",
                create_new_project: "New Project",
                create_new_project_from_template: "New Project from Template",
                add_template: "Make This Project a Template",
                remove_template: "Remove this Project from Templates",
                clone_project: "Clone Project",
                fork_project: "Fork Project",
            },
        },
        barista: {
            tooltip_important_rule_message: `set by an ${pcbRuleImportantString} ruleset`,
        },
    },
    hotkey_viewer: {
        title: "Keyboard Shortcuts",
    },
    privacy: {
        roles: {
            anonymous: "anyone on the internet",
            current_user: "you",
            specific_users: "specific users",
            workspace_owners: "owners of {workspace_display_name}",
            workspace_members: "members of {workspace_display_name}",
        },
        actions: {
            view: "can view",
            comment: "can comment",
            edit: "can edit",
        },
    },
    share_sheet: {
        share_button_label: "Share",
        title: "Share with others",
        done_btn_label: "Done",
        roles: {
            off: "PRIVATE - Only you can view and edit",
            edit: "Anyone can edit",
            view: "Anyone can view",
            comment: "Anyone can comment",
        },
        organization_roles: {
            off: "PRIVATE - Only {rel_owner_text} and owners of {org_display_name} can view and edit",
            edit: "Members of {org_display_name} can edit",
            view: "Members of {org_display_name} can view",
            comment: "Members of {org_display_name} can comment",
        },
        enterprise_roles: {
            off: "PRIVATE - Only {rel_owner_text} and owners of {enterprise_display_name} can view and edit",
            edit: "Members of {enterprise_display_name} can edit",
            view: "Members of {enterprise_display_name} can view",
            comment: "Members of {enterprise_display_name} can comment",
        },
        permission_select_input_label: "Permissions",
        copy_paste_tooltip: "Link copied to clipboard",
        copy_paste_embed_tooltip: "Embedded snipped copied to clipboard",
        copy_paste_button_label: "Copy Link",
        copy_paste_embed_snipped_button_label: "Copy Embed",
        advanced_button_label: "Advanced",
        advanced_roles: {
            none: "None",
            edit: "Can edit",
            view: "Can view",
            comment: "Can comment",
        },
        advanced_title: "Who has access",
        advanced_add_btn: "Add",
        advanced_user_input_placeholder: "Enter user names or email addresses",
        advanced_user_input_label: "Invite People",
    },
    profile: {
        inputQueryPlaceholder: "Filter by name...",
        confirmDeletePrompt: "Are you sure you want to permanently delete ",
        confirmArchivePrompt: "Would you like to archive ",
        confirmArchiveContext: "You will not be able to access it again.",
    },
    part_import_dialog: {
        title: "Import Components",
        close_btn_label: "Close",
    },
    part_publish_dialog: {
        title: "Publish to Library",
        title_subsequent_update: "Publish Changes",
        close_btn_label: "Cancel",
        publish_btn_label: "Publish",
        publish_failed_btn_label: "Failed",
        first_publish_message_line1:
            "Publish this project to make it searchable in the Component Library and usable in other projects.",
        first_publish_message_line2: "Use permissions to control who sees it.",
        first_publish_message_line3: "This action cannot be undone.",
        subsequent_publish_message: "Users of this component will receive an alert that changes have been published.",
        change_notes_label: "Notes",
        change_notes_placeholder: "Optionally describe what changed",
        publish_success_message: "Changes published successfully",
        publish_failed_message: "Changes could not be published.  Please retry in a moment.",
    },
    part_updates: {
        part_updates_available: "Updates available for your components",
        part_updates_review: "REVIEW",
        part_update_successful: "Component is up to date",
        part_update_all_successful: "All components are up to date",
        part_update_denied: "Component update failed. Permission denied.",
        update_all: "Update All",
        dialog_title: "Component Updates",
        new_chip: "New",
        all_chip: "All",
        update: "Update",
        up_to_date: "Up to date",
        receive_latest_drafts: "Receive latest drafts",
        receive_latest_drafts_tooltip:
            "Automatically keep this component up to date with the latest unpublished changes",
    },
    randomNamesDictionaries: {
        backToTheFuture: [
            "Heavy",
            "Jigowatt",
            "Pepsi free",
            "Slacker",
            "Daddy O",
            "Dreamboat",
            "Biff",
            "McFly",
            "Emmett",
            "Goldie Willson",
            "Strickland",
            "Clocktower Lady",
        ],
        mandalorian: [
            "Din Djarin",
            "Grogu",
            "Greef Karga",
            "The Client",
            "Dr. Pershing",
            "Kuiil",
            "IG-11",
            "Cara Dune",
            "Moff Gideon",
            "The Armorer",
            "Peli Motto",
            "Frog Lady",
            "Toro Calican",
            "Fennec Shand",
            "Ranzar Malk",
            "Mayfeld",
            "Xi'an",
            "Burg",
            "Q9-0",
            "Qin",
            "Gor Koresh",
            "Cobb Vanth",
            "Koska Reeves",
            "Bo-Katan Kryze",
            "Axe Woves",
            "Mythrol",
            "Lang",
            "Ahsoka Tano",
            "Morgan Elsbeth",
            "Omera",
            "Winta",
            "Caben",
            "Stoke",
            "Riot Mar",
            "Davan",
            "Trapper Wolf",
            "Jib Dodger",
            "Sash Ketter",
            "Carson Teva",
            "Governor Wing",
        ],
        dune: [
            "Arkie",
            "Alia Atreides",
            "Ghanima Atreides",
            "Ikonicre Atreides",
            "Lady Jessica",
            "Duke Leto",
            "Abulurd Harkonnen",
            "Baron Vladimir Harkonnen",
            "Anirul",
            "Norma Cenva",
            "Edric",
        ],
    },
};

export default strings;
