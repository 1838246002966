import {Routes, toAbsoluteUrl} from "@buildwithflux/core";
import {History, LocationState} from "history";

export function signUp(history?: History<LocationState>, utmCampaign?: string, target?: string) {
    if (history) {
        history.push(signUpUrl());
    } else {
        window.open(signUpUrl(), target || "_self");
    }
}

export function signUpUrl() {
    const destinationPath = window.location.pathname;

    return Routes.SIGN_UP + `?destinationPath=${encodeURIComponent(destinationPath)}`;
}

export function signInUrl() {
    const destinationPath = window.location.pathname;

    return Routes.LOG_IN + `?destinationPath=${encodeURIComponent(destinationPath)}`;
}

export function absoluteSignUpUrl() {
    return toAbsoluteUrl(Routes.SIGN_UP);
}

export function absoluteSignInUrl() {
    return toAbsoluteUrl(Routes.LOG_IN);
}
