/* eslint-disable react-hooks/rules-of-hooks */

import {MPNRecord, NullMPNRecord, PricingService, SKUCollection, SKURecord} from "@buildwithflux/core";
import {ClientFunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";
import {MPNPricesSchema, PricingCacheEntry, SKUPrices} from "@buildwithflux/models";
import {Logger, SubscribableCache} from "@buildwithflux/shared";
import {EventEmitter} from "eventemitter3";
import {debounce} from "lodash";
import {useCallback, useEffect, useMemo, useState} from "react";
import {z} from "zod";

import R from "../../resources/Namespace";
import {AnalyticsStorage} from "../storage_engine/AnalyticsStorage";
import {ObjectTypeTrackingEvents} from "../storage_engine/common/ObjectTypeTrackingEvents";

type PricingCacheEntryStatus = "loading" | "hit" | "miss" | "error";

// NOTE: Jenner and Greg judged 3 days to be a reasonable cache expiry time
// based on past experience.
const expiresAfterMs = 1000 * 60 * 60 * 24 * 3;

export type MPNRecordState = "cached" | "loading" | "initial" | "error";

export class ClientPricingService extends PricingService {
    constructor(
        cache: SubscribableCache<PricingCacheEntry>,
        private functionsAdapter: ClientFunctionsAdapter,
        private analyticsStorage: AnalyticsStorage,
        private logger: Logger,
    ) {
        super(cache);
    }

    /**
     * Subscribes to the cache entry for the given key, using an optional
     * schema to narrow the type of the entry.
     *
     * @param cacheKey The key to subscribe to. If null, no subscription will be created.
     * @param schema An optional schema to parse the entry with.
     * @returns A tuple of the cache entry (null if the key is null or the
     *          entry is not present), and the status of the entry.
     */
    usePricingCacheEntry(cacheKey: string | null): [PricingCacheEntry | null, PricingCacheEntryStatus];
    usePricingCacheEntry<T extends z.ZodType<PricingCacheEntry>>(
        cacheKey: string | null,
        schema: T,
    ): [z.infer<T> | null, PricingCacheEntryStatus];
    usePricingCacheEntry<T extends z.ZodType<PricingCacheEntry>>(
        cacheKey: string | null,
        schema?: T,
    ): [PricingCacheEntry | z.infer<T> | null, PricingCacheEntryStatus] {
        const [cacheEntry, setCacheEntry] = useState<PricingCacheEntry | null>(null);
        const [entryStatus, setEntryStatus] = useState<PricingCacheEntryStatus>("loading");

        const onCacheUpdate = useCallback(
            (newEntry: PricingCacheEntry | null) => {
                if (!newEntry) {
                    setCacheEntry(null);
                    setEntryStatus("miss");
                    this.analyticsStorage.logEvent(ObjectTypeTrackingEvents.parts, {
                        action: "pricing_cache",
                        status: "miss",
                        mpn: cacheKey,
                    });
                    return;
                }

                if (schema) {
                    const parseResult = schema.safeParse(newEntry);

                    if (parseResult.success) {
                        setCacheEntry(parseResult.data);
                        setEntryStatus("hit");

                        let params: {[key: string]: string | number | undefined | null} = {
                            action: "pricing_cache",
                            content_type: parseResult.data.type,
                            status: "hit",
                            cacheKey: cacheKey,
                        };

                        if (parseResult.data.type === "mpn") {
                            params = {
                                ...params,
                                skuCount: parseResult.data.skuCount,
                                distributorPricesCount: Object.keys(parseResult.data.distributorPrices).length,
                            };
                        } else if (parseResult.data.type === "sku") {
                            params = {
                                ...params,
                                leadTimeDays: parseResult.data.leadTimeDays,
                                availableQuantity: parseResult.data.availableQuantity,
                                distributor: parseResult.data.distributor,
                                lifecycleStatus: parseResult.data.lifecycleStatus,
                                mpn: parseResult.data.mpn,
                                dpn: parseResult.data.dpn,
                                minimumOrderQuantity: parseResult.data.minimumOrderQuantity,
                            };
                        } else if (parseResult.data.type === "bom") {
                            params = {
                                ...params,
                                maxCost: parseResult.data.maxCost,
                                minCost: parseResult.data.minCost,
                                quantityTarget: parseResult.data.quantityTarget,
                            };
                        }

                        this.analyticsStorage.logEvent(ObjectTypeTrackingEvents.parts, params);
                    } else {
                        this.logger.warn("Invalid cache entry", cacheKey, newEntry, parseResult.error);
                        setEntryStatus("error");
                    }
                }
            },
            [cacheKey, schema, setCacheEntry, setEntryStatus],
        );

        useEffect(() => {
            if (!cacheKey) {
                setCacheEntry(null);
                setEntryStatus("loading");
                return;
            }

            return this.cache.subscribe(cacheKey, onCacheUpdate);
        }, [cacheKey, onCacheUpdate, setCacheEntry, setEntryStatus]);

        return [cacheEntry ?? null, entryStatus];
    }

    useMPNRecord(mpn: string | null, actionEventEmitter?: EventEmitter): [MPNRecord, MPNRecordState] {
        const [entry, entryStatus] = this.usePricingCacheEntry(mpn, MPNPricesSchema);
        const [recordState, setRecordState] = useState<MPNRecordState>("initial");

        const mpnRecord = useMemo(() => {
            if (!mpn || entryStatus === "loading") {
                return new NullMPNRecord(mpn ?? "");
            }

            if (!entry) {
                //  NOTE: important to check for "initial" state here to avoid excessive requests.
                if (entryStatus === "miss" && recordState === "initial") {
                    setRecordState("loading");
                    // NOTE: we want to cache the "not found" state.
                    this.functionsAdapter.requestPricingCacheUpdate({mpns: [mpn]}).catch((error) => {
                        this.logger.error("Failed to request update for MPN", mpn, error);
                        setRecordState("error");
                    });
                }

                return new NullMPNRecord(mpn);
            }

            setRecordState("cached");

            // NOTE: this time-based cache invalidation would arguably be better
            // in the backend where an expired entry would return a miss.
            // NOTE: "not found" cache entries also have a timestamp.
            if (entry.timestamp) {
                const expiresByDate = new Date(Date.now() - expiresAfterMs);
                // NOTE: timestamp type can be either Date or Firestore
                // Timestamp, although when reading, it is always a Timestamp.
                const entryDate = "toDate" in entry.timestamp ? entry.timestamp.toDate() : entry.timestamp;
                if (entryDate < expiresByDate) {
                    // NOTE: don't setRecordState here because we want to update
                    // silently in the background. On most requests the
                    // refreshed data will be the same.
                    this.functionsAdapter.requestPricingDataRefresh({mpns: [mpn]}).catch((error) => {
                        this.logger.error("Failed to request refresh for MPN", mpn, error);
                        setRecordState("error");
                    });
                }
            }

            const record = new MPNRecord(mpn, entry);

            this.analyticsStorage.logEvent(ObjectTypeTrackingEvents.parts, {
                action: "record_fetched",
                content_type: "mpn",
                mpn: mpn,
                maxPrice: record.maxPrice,
                minPrice: record.minPrice,
                averagePrice: record.averagePrice,
                minStock: record.minStock,
                maxStock: record.maxStock,
                lifeCycleState: record.lifeCycleState,
                humanizedLifeCycleState: record.humanizedLifeCycleState,
                skuCount: record.skuCount,
            });

            return record;
        }, [entry, entryStatus, mpn, setRecordState]);

        // Handle clicks of the refresh button. See PanelWithActions component.
        useEffect(() => {
            if (!actionEventEmitter || !mpn) return;

            const refreshListener = debounce(
                () => {
                    // NOTE: loading instead of updating to optimistically hide
                    // any existing content before it is deleted and prevent
                    // jank.
                    setRecordState("loading");

                    this.functionsAdapter
                        .requestPricingDataRefresh({mpns: [mpn]})
                        .then(() => {
                            this.analyticsStorage.logEvent(ObjectTypeTrackingEvents.parts, {
                                action: "refreshed",
                                content_type: "mpn",
                                mpn: mpn,
                            });
                        })
                        .catch((error) => {
                            this.logger.error("Failed to delete cache for MPN", mpn, error);
                            setRecordState("error");

                            this.analyticsStorage.logEvent(ObjectTypeTrackingEvents.parts, {
                                action: "refresh_failed",
                                content_type: "mpn",
                                mpn: mpn,
                            });
                        })
                        .catch((error) => {
                            this.logger.error("Failed to request refresh for MPN", mpn, error);
                            setRecordState("error");

                            this.analyticsStorage.logEvent(ObjectTypeTrackingEvents.parts, {
                                action: "refresh_failed",
                                content_type: "mpn",
                                mpn: mpn,
                            });
                        });
                },
                R.behaviors.part_data.availabilityRefreshInterval,
                {
                    leading: true,
                    trailing: false,
                },
            );

            actionEventEmitter.on("refresh", refreshListener);
            return function () {
                actionEventEmitter.off("refresh", refreshListener);
            };
        }, [actionEventEmitter, mpn]);

        return [mpnRecord, recordState];
    }

    useSKURecords(skuIds: string[] | null): SKUCollection {
        const [skuRecords, setSKURecords] = useState<Map<string, SKURecord> | null>(null);

        useEffect(() => {
            if (!skuIds) {
                setSKURecords(null);
                return;
            }

            const fetchSKUs = async () => {
                const prices = await this.fetchSKUPrices(skuIds);
                setSKURecords(new Map(prices.map((price) => [price.skuId, new SKURecord(price.skuId, price)])));
            };

            fetchSKUs().catch((error) => {
                this.logger.error("Failed to load SKUs", skuIds, error);
                setSKURecords(null);
                this.analyticsStorage.logEvent(ObjectTypeTrackingEvents.parts, {
                    action: "refresh_failed",
                    content_type: "sku",
                    skuIds: skuIds,
                });
            });
        }, [skuIds, setSKURecords]);

        return useMemo(() => new SKUCollection(skuRecords), [skuRecords]);
    }

    fetchSKUPrices(skuIds: string[]) {
        return this.cache.fetchMultiple(skuIds, async (missingKeys) => {
            const skuResult = await this.functionsAdapter.fetchSKUs({skuIds: missingKeys});

            return new Map(
                skuResult.data.map((sku) => {
                    this.analyticsStorage.logEvent(ObjectTypeTrackingEvents.parts, {
                        action: "record_fetched",
                        content_type: "sku",
                        skuId: sku.skuId,
                        dpn: sku.dpn,
                        mpn: sku.mpn,
                        minimumOrderQuantity: sku.minimumOrderQuantity,
                        availableQuantity: sku.availableQuantity,
                        lifecycleStatus: sku.lifecycleStatus,
                        distributor: sku.distributor,
                        leadTimeDays: sku.leadTimeDays,
                    });

                    return [sku.skuId, sku];
                }),
            );
        }) as Promise<SKUPrices[]>;
    }
}
