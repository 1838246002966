import UserCodeRuntime from "./UserCodeRuntime.worker";

export class UserCodeRuntimeWrapper {
    private runtime: Promise<UserCodeRuntime> | undefined;

    public async init(): Promise<UserCodeRuntime> {
        if (!this.runtime) {
            // QUESTION: why await here? the constructor is an empty function!
            // because it is in a web worker?
            // ANSWER: because comiink is wrapping the constructor function
            // into a function that returns a promise of the instantiated object
            this.runtime = new UserCodeRuntime() as unknown as Promise<UserCodeRuntime>;
            await (await this.runtime).initialize();
        }
        return await this.runtime;
    }

    public dispose() {
        if (this.runtime !== undefined) {
            // TODO: actually implement dispose
            // await (await this.runtime).dispose();
            this.runtime = undefined;
        }
    }
}
