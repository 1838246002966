import {OrganizationUid} from "@buildwithflux/core";
import {FullyDecoratedOrganizationMember} from "@buildwithflux/models";
import {useEffect} from "react";
import {useSelector} from "react-redux";

import {useFluxServices} from "../../../injection/hooks";
import baseDocumentSelectors from "../../../redux/selectors/document/baseDocument/selectors";

import {
    useCurrentOrganizationMembershipForOrganization,
    useCurrentOrganizationMemberships,
    useOrganizationStoreApi,
} from "./organization";

/**
 * Gets a reactive slice of the current page's organization context
 *
 * Useful for features such as:
 *  - When we are in an organization context, we want the "new project" hotkey to create a project in that organization
 */
export function useCurrentPageOrganizationContext(): OrganizationUid | undefined {
    const memberships = useCurrentOrganizationMemberships();

    // If there's a document in Redux, use its organization owner as the context
    const documentOrganizationOwnerUidFromRedux = useSelector(baseDocumentSelectors.selectOrganizationOwnerUid);

    // Otherwise fall back to the organization store's state
    const organizationStoreState = useFluxServices().useOrganizationStore(
        (state) => state.currentPageOrganizationContext,
    );

    const candidateOrganizationUid = documentOrganizationOwnerUidFromRedux ?? organizationStoreState;

    // Must be a member of that organization for it to be a valid context
    if (memberships.some((membership) => membership.organizationUid === candidateOrganizationUid)) {
        return candidateOrganizationUid;
    }

    return undefined;
}

export function useCurrentPageOrganizationContextMembership(): FullyDecoratedOrganizationMember | undefined {
    const currentPageOrganizationContext = useCurrentPageOrganizationContext();
    return useCurrentOrganizationMembershipForOrganization(currentPageOrganizationContext);
}

/**
 * Sets the current page's organization context using an effect
 *
 * @usage Use this on any page-level component that defines an organization context: e.g. the organization profile page,
 *        or a document that is owned by an organization
 */
export function useSetCurrentPageOrganizationContextEffect(organizationUid: OrganizationUid | undefined): void {
    const api = useOrganizationStoreApi();

    useEffect(() => {
        api.setCurrentPageOrganizationContext(organizationUid);

        // We'd previously unset the organization context on unmount, but it causes an unsightly flash of no organization
        // context when moving between a profile page, and a document belonging to that context

        // return () => {
        //     api.setCurrentPageOrganizationContext(undefined);
        // };
    }, []);
}
