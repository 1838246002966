import _ from "lodash";

export abstract class BOMFormat {
    static readonly manufacturer: string = "";
    static readonly columns: Record<string, ColumnDefinition> = {};

    constructor(public readonly lineItems: LineItem[]) {}

    public get name(): string {
        return (this.constructor as typeof BOMFormat).manufacturer;
    }

    public get columns(): typeof BOMFormat.columns {
        return (this.constructor as typeof BOMFormat).columns;
    }

    public get columnNames(): string[] {
        return Object.keys(this.columns);
    }

    public getRowData(): RowData[] {
        return this.lineItems.map((lineItem, i) =>
            Object.entries(this.columns).reduce((d, [label, getter]) => {
                if (typeof getter === "string") {
                    d[label] = `${_.get(lineItem, getter) ?? ""}`;
                } else {
                    d[label] = `${getter(lineItem, i + 1)}`;
                }

                return d;
            }, {} as RowData),
        );
    }
}

export type ColumnDefinition = keyof LineItem | ((item: LineItem, rowNumber: number) => string);

export type LineItem = {
    mpn: string;
    dpn: string;
    distributorName: string;
    partUid: string;
    partName: string;
    partDocumentUrl: string;
    quantity: number;
    designators: string[];
    specification: string[];
    value: string;
    package: string | undefined;
    description: string;
    elementUids: string[];
    elementProperties: Record<string, any>;
    manufacturer: string;
    mountType: string;
    notes: string;
    schematicPositions: Record<string, SchematicPosition>;
};

export type RowData = Record<string, string>;

export type SchematicPosition = {
    x: number;
    y: number;
    rotation: number;
    flipped: boolean;
};
