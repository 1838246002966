import {UserStateType} from "@buildwithflux/models";
import {ComponentType, memo, useEffect} from "react";

import FullPageLoader from "../../../components/common/components/loader/FullPageLoader";
import {useFluxServices} from "../../../injection/hooks";
import {useCurrentUserAuthenticationState} from "../state/currentUser";

export function blockUntilAuthReady<Props extends {}>(InnerComponent: ComponentType<Props>) {
    const wrappedComponent = memo((props: Props) => {
        const state = useCurrentUserAuthenticationState();
        const currentUserService = useFluxServices().currentUserService;

        useEffect(() => {
            if (state === UserStateType.enum.loggedOut) {
                void currentUserService.logInAnonymously();
            }
        }, [state]);

        const authSystemIsStarting =
            state === UserStateType.enum.uninitialized ||
            state === UserStateType.enum.initializing ||
            state === UserStateType.enum.loggedOut;

        if (authSystemIsStarting) {
            return <FullPageLoader delay={1000} surface={"block_until_auth_ready"} showNavBar={true} />;
        }

        return <InnerComponent {...props} />;
    });

    return wrappedComponent;
}
