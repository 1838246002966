// eslint-disable-next-line no-restricted-imports
import Dialog, {DialogProps} from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import isChromatic from "chromatic/isChromatic";
import cn from "classnames";
import {CSSProperties} from "react";

export function FluxDialog(props: DialogProps) {
    return <Dialog {...props} />;
}

const styles: (theme: Theme) => ReturnType<typeof createStyles> = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
            float: "right",
        },
        whiteCloseButton: {
            color: theme.palette.common.white,
        },
    });

interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: (() => void) | null;
    align?: "left" | "center" | "right";
    className?: string;
    whiteCloseIcon?: boolean;
    disableTypography?: boolean;
    style?: CSSProperties;
}

export const ClosableDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const {children, classes, onClose, align, disableTypography, whiteCloseIcon, ...other} = props;

    return (
        <MuiDialogTitle disableTypography className={cn(classes.root, other.className)} {...other}>
            {disableTypography ? (
                children
            ) : (
                <Typography variant="h6" align={align}>
                    {children}
                </Typography>
            )}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={cn(classes.closeButton, {
                        [classes.whiteCloseButton as string]: whiteCloseIcon,
                    })}
                    onClick={onClose}
                    disableRipple={isChromatic()}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
