import {BOMFormat, ColumnDefinition} from "./base";

/**
 * Advanced Circuits BOM format
 * https://www.4pcb.com/Assembly%20Requirements%20(3-1-2017).pdf
 */
export class AdvancedCircuitsFormat extends BOMFormat {
    static override manufacturer = "Advanced Circuits";
    static override columns: Record<string, ColumnDefinition> = {
        "Component Description": (item) => item.specification.join(", "),
        QTY: "quantity",
        "Ref Des": (item) => item.designators.join(","),
        "Mfg P/N #": "mpn",
        Manufacturer: "manufacturer",
        "Distributor P/N #": "dpn", // DigiKey P/N
    };
}
