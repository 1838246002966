/* eslint-disable id-blacklist */
import {CaptureIntercomLeadError, Routes, TrackingEventContentType} from "@buildwithflux/core";
import type {ICreateLeadRequestData} from "@buildwithflux/firebase-functions-adapter";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/styles";
import {useCallback, useEffect, useRef, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import createPersistedState from "use-persisted-state";

import {useFluxServices} from "../../../injection/hooks";
import {DeprecatedTrackingEvents} from "../../../modules/storage_engine/common/DeprecatedTrackingEvents";
import {FluxLogger} from "../../../modules/storage_engine/connectors/LogConnector";
import FluxLogo from "../../../resources/assets/flux_logo.svg";
import R from "../../../resources/Namespace";

import DelayedProgressSpinner from "./loader/InlineLoader";

interface IMobileInterstitialProps {
    setForceDesktop: (value: boolean) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        circularProgress: {
            marginTop: "20px",
        },
        form: {
            height: "100%",
        },
        submitButton: {
            marginTop: "8px",
            height: "40px",
        },
    }),
);

export default function MobileInterstitial(props: IMobileInterstitialProps) {
    const {setForceDesktop} = props;
    const classes = useStyles();

    const {register, errors, handleSubmit} = useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const usePersistedState = useRef(createPersistedState("setAppliedBeta")).current;
    const [appliedBeta, setAppliedBeta] = usePersistedState<boolean>(false);
    const {functionsAdapter, analyticsStorage} = useFluxServices();

    const getVisitorIdString = "getVisitorId";

    useEffect(() => {
        // @ts-ignore
        if (window.Intercom) {
            // @ts-ignore
            const id = window.Intercom(getVisitorIdString);
            analyticsStorage.logEvent(DeprecatedTrackingEvents.mobileVisitor, {
                content_type: TrackingEventContentType.MobileInterstitialAction,
                visitor_id: id,
            });
        }
    }, [analyticsStorage]);

    const onSubmit: SubmitHandler<{email: string}> = (data) => {
        // @ts-ignore
        if (window.Intercom) {
            setIsLoading(true);

            // @ts-ignore
            const id = window.Intercom(getVisitorIdString);

            analyticsStorage.logEvent(DeprecatedTrackingEvents.captureLeadEmail, {
                content_type: TrackingEventContentType.MobileInterstitialAction,
                lead_id: id,
                lead_email: data.email,
            });
            const leadPayload: ICreateLeadRequestData = {
                id,
                email: data.email,
            };

            functionsAdapter
                .createLead({...leadPayload})
                .then(() => {
                    setAppliedBeta(true);
                })
                .catch((reason) => {
                    // @todo - should we show anything about the error?
                    FluxLogger.captureError(new CaptureIntercomLeadError("MobileInterstitial.tsx", reason));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const formSubmit = handleSubmit(onSubmit);

    const continueToDesktop = useCallback(() => {
        // @ts-ignore
        if (window.Intercom) {
            // @ts-ignore
            const id = window.Intercom(getVisitorIdString);
            analyticsStorage.logEvent(DeprecatedTrackingEvents.visitorContinueToDesktop, {
                content_type: TrackingEventContentType.MobileInterstitialAction,
                visitor_id: id,
            });
        }
        setForceDesktop(true);
    }, [analyticsStorage, setForceDesktop]);

    const getLandingUrl = () => {
        return Routes.LANDING;
    };

    const footer = (
        <Box display="flex" flexDirection="column" flex="1 1 auto" justifyContent="flex-end" alignItems="center">
            <Box display="flex" flexGrow={0} alignItems="center" justifyContent="center" marginTop="20px">
                <Link color="primary" href={getLandingUrl()} variant="body2" underline="always">
                    Flux Homepage
                </Link>
            </Box>

            <Box display="flex" flexGrow={0} alignItems="bottom" justifyContent="center" marginTop="20px">
                <Link
                    component="button"
                    variant="body2"
                    underline="always"
                    color="textPrimary"
                    onClick={continueToDesktop}
                >
                    Continue to Flux Desktop Version
                </Link>
            </Box>
        </Box>
    );

    return (
        <Box
            width="100%"
            height={window.innerHeight}
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="20px"
        >
            <Box display="flex" alignItems="center" justifyContent="center" marginTop="20px">
                <img src={FluxLogo} alt="Flux logo" />
            </Box>
            {isLoading && (
                <DelayedProgressSpinner
                    className={classes.circularProgress}
                    aria-label={"Loading"}
                    surface={"mobile_interstitial"}
                />
            )}
            {appliedBeta && (
                <Box display="flex" flexDirection="column" flex="1 1 auto" alignItems="center">
                    <Box display="flex" flexGrow={0} alignItems="center" justifyContent="center" marginTop="40px">
                        <Typography align="center" variant="body2" component="h1" gutterBottom display="block">
                            Thank you! Your application to our Private Beta has been recieved.
                        </Typography>
                    </Box>
                    <br />
                    <Box display="flex" flexGrow={0} alignItems="center" justifyContent="center">
                        <Typography align="center" variant="body2" component="h1" gutterBottom display="block">
                            Check your email for next steps.
                        </Typography>
                    </Box>
                    {footer}
                </Box>
            )}
            {!isLoading && !appliedBeta && (
                <Box display="flex" flexDirection="column" flex="1 1 auto" alignItems="center" justifyContent="center">
                    <Box display="flex" flexGrow={0} alignItems="center" justifyContent="center" marginTop="40px">
                        <Typography align="center" variant="body2" component="h1" gutterBottom display="block">
                            We're sorry, but Flux isn't available on mobile devices yet. We look forward to supporting
                            this in the future!
                        </Typography>
                    </Box>
                    <br />
                    <Box display="flex" flexGrow={0} alignItems="center" justifyContent="center">
                        <Typography align="center" variant="body2" component="h1" gutterBottom display="block">
                            Check it out on desktop by applying to the Private Beta
                        </Typography>
                    </Box>
                    <br />
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <form
                            onSubmit={(e) => {
                                formSubmit(e);
                                return false;
                            }}
                            className={classes.form}
                            autoComplete="off"
                            noValidate
                        >
                            <TextField
                                id="email"
                                label="Email"
                                variant="outlined"
                                size="small"
                                inputRef={register({
                                    required: R.strings.validation_message.required,
                                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
                                })}
                                name="email"
                                fullWidth
                                helperText={errors && errors.email ? errors.email.message : ""}
                                error={!!errors.email}
                                autoComplete="off"
                            />
                            <Button
                                type="submit"
                                className={classes.submitButton}
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                APPLY TO BETA
                            </Button>
                        </form>
                    </Box>
                    {footer}
                </Box>
            )}
        </Box>
    );
}
