import {defaultPropertyDefinitions} from "@buildwithflux/constants";
import {booleanClassifier} from "@buildwithflux/core";
import {values} from "lodash";

import {BOMFormat, ColumnDefinition} from "./base";

/**
 * Elecrow BOM format
 * https://www.elecrow.com/media/product/BOM_Template.xlsx
 */
export class ElecrowFormat extends BOMFormat {
    static override manufacturer = "Elecrow";

    static override columns: Record<string, ColumnDefinition> = {
        "*Reference": (item) => item.designators.join(", "),
        "*Manufacturer Part number": "mpn",
        "*QTY": "quantity",
        "*Original": (item) => {
            const rawAllowSubstituteValue = values(item.elementProperties)[0][
                defaultPropertyDefinitions.allow_substitutes?.label
            ];
            const rawSubstituteMpnValue = values(item.elementProperties)[0][
                defaultPropertyDefinitions.substitute_mpn?.label
            ];

            if (!rawAllowSubstituteValue) {
                return "";
            }

            const allowSubstituteValue = booleanClassifier(rawAllowSubstituteValue);

            return allowSubstituteValue || !!rawSubstituteMpnValue ? "N" : "Y";
        },
        Description: (item) => {
            const rawSubstituteMpnValue = values(item.elementProperties)[0][
                defaultPropertyDefinitions.substitute_mpn?.label
            ];

            if (rawSubstituteMpnValue) {
                return `${item.description} \n\n Substitute MPN: ${rawSubstituteMpnValue}`;
            } else {
                return item.description;
            }
        },
        "Purchase Link": (item) => {
            return (
                values(item.elementProperties)[0][defaultPropertyDefinitions.purchase_url?.label] ||
                values(item.elementProperties)[0][defaultPropertyDefinitions.product_info_url?.label] ||
                ""
            );
        },
    };
}
