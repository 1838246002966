import Moment from "moment";
import {StoreApi} from "zustand";
import {createWithEqualityFn, UseBoundStoreWithEqualityFn} from "zustand/traditional";

import {AutoLayoutStoreApi, AutoLayoutStoreState, LocalAutoLayoutState} from "./types";

type AutoLayoutState = AutoLayoutStoreApi & AutoLayoutStoreState;
export type UseAutoLayoutStore = UseBoundStoreWithEqualityFn<StoreApi<AutoLayoutState>>;

const initialState: AutoLayoutStoreState = {
    state: LocalAutoLayoutState.idle,
    jobUid: undefined,

    startTime: undefined,
    timeElapsed: undefined,
    usedCredits: 0,
    errorMessage: undefined,

    startTimeForInitializingState: 0,
    timeoutIntervalForInitializingState: undefined,

    // Flag to show the "High volume" snackbar
    detectedSlowness: false,
};

export const createAutoLayoutStoreHook = (): UseAutoLayoutStore =>
    createWithEqualityFn<AutoLayoutState>()((set, get) => {
        return {
            ...initialState,
            queue: () => {
                const interval = setInterval(() => {
                    const currentState = get();

                    if (
                        currentState.startTimeForInitializingState &&
                        currentState.state === LocalAutoLayoutState.waiting &&
                        Date.now() - currentState.startTimeForInitializingState > 30_000
                    ) {
                        set({
                            detectedSlowness: true,
                        });
                    }
                }, 1000);
                set({
                    state: LocalAutoLayoutState.waiting,
                    startTime: Date.now(),
                    timeElapsed: undefined,
                    usedCredits: 0,
                    errorMessage: undefined,
                    startTimeForInitializingState: Date.now(),
                    timeoutIntervalForInitializingState: interval,
                });
            },

            load: (startTime?: number) => {
                const currentState = get();

                if (currentState.timeoutIntervalForInitializingState) {
                    clearInterval(currentState.timeoutIntervalForInitializingState);
                }
                set({
                    state: LocalAutoLayoutState.running,
                    startTime: startTime ?? Date.now(),
                    timeElapsed: undefined,
                    usedCredits: 0,
                    errorMessage: undefined,

                    startTimeForInitializingState: 0,
                    timeoutIntervalForInitializingState: undefined,

                    detectedSlowness: false,
                });
            },
            pause: () =>
                set({
                    state: LocalAutoLayoutState.pausing,
                }),
            paused: () =>
                set({
                    state: LocalAutoLayoutState.paused,
                }),
            resume: () =>
                set({
                    state: LocalAutoLayoutState.resuming,
                }),
            resumed: () =>
                set({
                    state: LocalAutoLayoutState.running,
                }),

            reset: () =>
                set({
                    ...initialState,
                }),

            noCredits: () =>
                set({
                    state: LocalAutoLayoutState.noCredits,
                }),
            apply: () =>
                set({
                    state: LocalAutoLayoutState.applying,
                }),
            applied: () =>
                set({
                    state: LocalAutoLayoutState.applied,
                    jobUid: undefined,

                    startTime: undefined,
                    timeElapsed: undefined,
                    usedCredits: 0,
                    errorMessage: undefined,
                }),
            cancel: () => {
                const currentState = get();
                if (currentState.timeoutIntervalForInitializingState) {
                    clearInterval(currentState.timeoutIntervalForInitializingState);
                }
                set({
                    state: LocalAutoLayoutState.canceling,

                    detectedSlowness: false,
                    timeoutIntervalForInitializingState: undefined,
                    startTimeForInitializingState: 0,
                });
            },
            canceled: () =>
                set({
                    state: LocalAutoLayoutState.canceled,
                    jobUid: undefined,

                    startTime: undefined,
                    timeElapsed: undefined,
                    usedCredits: 0,
                    errorMessage: undefined,
                }),

            complete: () =>
                set({
                    state: LocalAutoLayoutState.complete,
                    errorMessage: undefined,
                }),

            error: (errorMessage) =>
                set({
                    state: LocalAutoLayoutState.error,
                    errorMessage,
                }),

            tick: (startTime?: number) =>
                set((state) => {
                    startTime ??= state.startTime;
                    if (startTime !== undefined) {
                        return {
                            startTime,
                            timeElapsed: Moment(startTime).fromNow(true),
                        };
                    }
                    return state;
                }),

            setJobUid: (jobUid) => set({jobUid}),

            setUsedCredits: (usedCredits) => set({usedCredits}),

            unsubscribe: () => {},
        };
    });
