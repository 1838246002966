// takes a Flux object
//		(the contents of FluxPCBVirtualDomStore.pcbLayoutNodes)
// and converts it to a a .kicad_pcb file

import {
    AnyPcbBakedNode,
    FootPrintPadHoleType,
    GerberExporterError,
    IKicadFpCircle,
    IKicadFpLine,
    IKicadFpText,
    IKicadGrCircle,
    IKicadGrLine,
    IKicadNet,
    IKicadNetClass,
    IKicadPad,
    IKicadPcbHeader,
    IKicadSegment,
    IKicadVia,
    IPcbLayoutNodeWithChildArray,
    IPcbLayoutSolderMaskExpansionConfig,
    KicadLayerNames,
    KicadPadShape,
    KicadPadType,
    LayerOrientation,
    MissingChildUidError,
    PcbBakedNode,
    PcbLayoutShape,
    PcbNodeTypes,
    PcbNodesMap,
    TextMirror,
    ZeroVector2,
    createKicadHeader,
    createModuleTemplate,
} from "@buildwithflux/core";
import {
    isCopperLayer,
    isMiddleCopperLayer,
    isOverlayLayer,
    isSolderMaskLayer,
    isSolderPasteLayer,
    resetChildUidsAndRootNode,
} from "@buildwithflux/models";
import {areWeTestingWithJest, degreesToRadians, normalizeRadians} from "@buildwithflux/shared";
import {round} from "lodash";
import {Vector2} from "three";

import {FluxLogger} from "../../../../storage_engine/connectors/LogConnector";

import kicadSerializer from "./KicadSerializer";

// eslint-disable-next-line @typescript-eslint/naming-convention
interface adjCoordType {
    adjX: number;
    adjY: number;
    adjRotation: number;
}

const netCrossReference: {[key: string]: IKicadNet} = {};

// we have to move the board somewhere into the middle of the page
// in Flux, (0,0) is at the center of the board
const xAdjust = 100; // how much to adjust the X coords
// NOTE: Kicad Y coords have to be flipped
const yAdjust = 100; // how much to adjust the Y coords

const GLOBAL_TOP_MODULE_INDEX = 0; // index in moduleAr of the top global module
const GLOBAL_BOTTOM_MODULE_INDEX = 1; // index in moduleAr of the bottom global module

// converts the Flux pad type to Kicad pad type
const FluxPadtype2KicadLookup = {
    [FootPrintPadHoleType.platedThroughHole]: KicadPadType.thruHole,
    [FootPrintPadHoleType.SurfaceMountDevice]: KicadPadType.surfaceMountDevice,
    [FootPrintPadHoleType.nonPlatedHole]: KicadPadType.nonPlatedThruHole,
    [FootPrintPadHoleType.testPinOrCardEdgeConnector]: KicadPadType.connect,
};

// FIXME: this is incomplete
const KicadCopperLayerMapping: {[key: string]: KicadLayerNames} = {
    Top: "F.Cu",
    Bottom: "B.Cu",
    "Mid-Layer 1": "In1.Cu",
    "Mid-Layer 2": "In2.Cu",
    "7": "In1.Cu", // sometimes layers get sent over as "7" and "9" ??
    "9": "In2.Cu",
};

// convert radians to nearest 5 degrees
// range between 0 and 360, no negative rotations
const radianPerDegree = (Math.PI * 2) / 360;
const convertRadiansToDegrees = (radians: number): number => {
    return (round(radians / radianPerDegree / 5) * 5 + 360) % 360;
};

// converts Flux numbers (1/1000th of mm) to mm, by multiplying by 1000
const toMm = (num: number | undefined) => (num || 0) * 1000;

// either get the contents of the property, or initialize and return
const getPropOrInit = (inputObj: any, prop: string, initialObj: object) => {
    let rec;
    rec = inputObj[prop];
    if (!rec) {
        rec = initialObj;
        inputObj[prop] = rec;
    }
    return rec;
};

// start at "parentUid=ROOT", and fill in children
// this step is necessary because the children
//		inherit the location offset and rotation of all its ancestors
const buildTree = (pcbLayoutNodes: PcbNodesMap<AnyPcbBakedNode>) => {
    // more than one root node is possible?
    const rootAr: IPcbLayoutNodeWithChildArray[] = [];

    // build the tree under this node
    const buildOneTree = (node: IPcbLayoutNodeWithChildArray) => {
        // TODO: [NodeType] - why is this node undefined?
        if (!node) {
            return;
        }

        // NOTE: We play safe here to fallback childUids to empty, but want to know
        // that childUids is missing, so that we can investigate and take actions from here
        if (!node.childUids) {
            FluxLogger.captureError(new MissingChildUidError(`node ${node.uid} with type ${node.type} in buildTree`));
        }
        const childUids = node.childUids || [];
        if (childUids.length) {
            const nodeChildren: IPcbLayoutNodeWithChildArray[] = getPropOrInit(node, "childAr", []);
            for (const uid of childUids) {
                const childnode = pcbLayoutNodes[uid]!;
                nodeChildren.push(childnode);
                buildOneTree(childnode);
            }
        }
    };

    // search for root node(s)
    for (const uid in pcbLayoutNodes) {
        const layoutNode = pcbLayoutNodes[uid]!;

        if (layoutNode.parentUid === "ROOT") {
            rootAr.push(layoutNode);
            // create a new obj for showing structure
            buildOneTree(layoutNode);
        }
    }
    return rootAr;
};

// creates structures used to track which traces and pads belong to which nets
// used by the board manufacturer to test electrical connections
const createNets = (pcbLayoutNodes: PcbNodesMap<AnyPcbBakedNode>, rootObj: IKicadPcbHeader) => {
    const netAr: IKicadNet[] = [];

    // for now, these values are hard-coded defaults
    // TODO: fill them in with values from Flux properties
    const netClassObj: IKicadNetClass[] = [
        {
            name: "Default",
            description: "This is the default net class.",
            clearance: 0.254,
            trace_width: 0.254,
            via_dia: 0.8,
            via_drill: 0.4,
            uvia_dia: 0.3,
            uvia_drill: 0.1,
            add_net: [],
        },
    ];
    const addNetAr = netClassObj[0]!.add_net;

    // search for net types
    let index = 1;
    for (const uid in pcbLayoutNodes) {
        const layoutNode = pcbLayoutNodes[uid]!;

        if (layoutNode.type === PcbNodeTypes.net) {
            addNetAr.push(layoutNode.name);

            const netObj: IKicadNet = {
                net_number: index,
                name: layoutNode.name,
            };
            netAr.push(netObj);

            // NOTE: We play safe here to fallback childUids to empty, but want to know
            // that childUids is missing, so that we can investigate and take actions from here
            if (!pcbLayoutNodes[layoutNode.uid]!.childUids) {
                FluxLogger.captureError(
                    new MissingChildUidError(
                        `node ${layoutNode.uid} with type ${pcbLayoutNodes[layoutNode.uid]!.type} in createNets`,
                    ),
                );
            }
            // cross reference childUids in this net with the current index
            const childrenUids = pcbLayoutNodes[layoutNode.uid]?.childUids || [];
            if (childrenUids.length) {
                for (const childUid of childrenUids) {
                    // netCrossReference is a global object which connects the children uids to a net number
                    netCrossReference[childUid] = netObj;

                    const childNode = pcbLayoutNodes[childUid];
                    if (!childNode) {
                        // TODO: orphan child node, should not normally happen
                        continue;
                    }
                    if (childNode.type === PcbNodeTypes.routeSegment) {
                        // add the pads at the end of the route to the same net
                        const routeNode = childNode;

                        if (routeNode.startId) {
                            netCrossReference[routeNode.startId] = netObj;
                        }
                        if (routeNode.endId) {
                            netCrossReference[routeNode.endId] = netObj;
                        }
                    }
                }
            }
            index += 1;
        }
    }
    if (netAr.length) {
        rootObj.kicad_pcb.net = netAr;
        rootObj.kicad_pcb.net_class = netClassObj;
        rootObj.kicad_pcb.general.nets = addNetAr.length;
    } else {
        // no nets found
        rootObj.kicad_pcb.general.nets = 0;
    }
};

const getKicadCompatibleExpansionMargin = (expansion: IPcbLayoutSolderMaskExpansionConfig) => {
    if (expansion.bottomExpansion && expansion.topExpansion) {
        return Math.max(expansion.bottomExpansion, expansion.topExpansion);
    } else if (expansion.topExpansion) {
        return expansion.topExpansion;
    } else {
        return expansion.bottomExpansion;
    }
};

// Recursive function to translate a flux JSON node to a Kicad JSON node
// 		node: the current node to convert
//		adjCoord: any current adjustments for X,Y position and rotation
//		currModuleIndex: the array index of the currently active module
const createKicadObject = (
    rootObj: IKicadPcbHeader,
    node: IPcbLayoutNodeWithChildArray,
    adjCoord: adjCoordType,
    currModuleIndex: number,
    fluxLayerNameToKicadLayerNameMap: typeof KicadCopperLayerMapping = KicadCopperLayerMapping,
) => {
    // TODO: Why is this node undefined??
    if (!node) {
        return;
    }
    const {adjX, adjY, adjRotation} = adjCoord;
    const {childAr} = node;
    const moduleAr = getPropOrInit(rootObj.kicad_pcb, "module", []);
    let currModule = moduleAr[currModuleIndex];

    if (node.bakedRules) {
        if (currModuleIndex < 2) {
            // this exists at the top level of Flux, not in any module
            // use one of the global objects
            if (node.bakedRules?.layer === LayerOrientation.bottom) {
                currModule = moduleAr[GLOBAL_BOTTOM_MODULE_INDEX];
            } else {
                currModule = moduleAr[GLOBAL_TOP_MODULE_INDEX];
            }
        }

        if (node.type === PcbNodeTypes.routeSegment) {
            const {
                startPosition,
                endPosition,
                positionRelativeToRootLayout,
                rotationRelativeToRootLayout,
                layer,
                size,
            } = node.bakedRules;

            if (!netCrossReference[node.uid]) {
                // routeSegment MUST ALWAYS be connected to a net
                const errmsg = "no net found for routeSegment!";
                FluxLogger.captureError(new GerberExporterError(errmsg));
                throw new Error(errmsg);
            }

            // if a trace is on the bottom layer, the X axis has to be flipped
            const layerName = fluxLayerNameToKicadLayerNameMap[layer];

            if (!layerName) {
                const errmsg = "ERROR: Sorry, Gerber export currently supports a maximum of 4 layers";
                FluxLogger.captureError(new GerberExporterError(errmsg));
                return;
            }

            const start = new Vector2(startPosition.x, startPosition.y);
            const end = new Vector2(endPosition.x, endPosition.y);

            rotationRelativeToRootLayout && start.rotateAround(ZeroVector2, rotationRelativeToRootLayout.z);
            rotationRelativeToRootLayout && end.rotateAround(ZeroVector2, rotationRelativeToRootLayout.z);

            // rotationRelativeToRootLayout can end up with either the x or the y axis flipped,
            // even if we only flipped around the y axis explicitly, because the inverse world-space
            // transformation used to produce relativeToRootLayout has multiple possible answers
            // for a given orientation (eg y=180,z=45 is equivalent to x=180,z=135). So we check both.
            const normX = normalizeRadians(rotationRelativeToRootLayout?.x ?? 0, true);
            const normY = normalizeRadians(rotationRelativeToRootLayout?.y ?? 0, true);

            const xFlip = Math.abs(normX) > Math.PI / 2;
            const yFlip = Math.abs(normY) > Math.PI / 2;

            if (xFlip) {
                start.setY(-start.y);
                end.setY(-end.y);
            }

            if (yFlip) {
                start.setX(-start.x);
                end.setX(-end.x);
            }

            const newobj: IKicadSegment = {
                start: {
                    x: toMm((positionRelativeToRootLayout?.x ?? 0) + start.x) + xAdjust,
                    y: yAdjust - toMm((positionRelativeToRootLayout?.y ?? 0) + start.y),
                },
                end: {
                    x: toMm((positionRelativeToRootLayout?.x ?? 0) + end.x) + xAdjust,
                    y: yAdjust - toMm((positionRelativeToRootLayout?.y ?? 0) + end.y),
                },
                layer: layerName,
                width: toMm(size.x),
                net: netCrossReference[node.uid]!.net_number,
            };

            const segmentAr = getPropOrInit(rootObj.kicad_pcb, "segment", []);
            segmentAr.push(newobj);
        } else if (node.type === PcbNodeTypes.text) {
            const {fontSize, layer, textAlign, content, position, rotation} = node.bakedRules;
            const rotationDegrees = convertRadiansToDegrees(rotation?.z || 0);

            const rdeg = currModule && currModule.pos ? currModule.pos.rdeg + rotationDegrees : 0;
            const newobj: IKicadFpText = {
                text: content,
                type: "user",
                at: {
                    x: toMm(position.x),
                    y: 0 - toMm(position.y),
                    unlocked: false,
                    // you have to rotate the text yourself
                    // angle: if rdeg is 270 then -90 else rdeg
                    angle: rdeg,
                },
                layer: layer === LayerOrientation.top ? "F.SilkS" : "B.SilkS",
                effects: {
                    font: {
                        size: {
                            width: toMm(fontSize) * 0.8,
                            height: toMm(fontSize) * 0.8,
                        },
                        thickness: toMm(fontSize) * 0.095,
                    },
                },
            };
            // center is implied, otherwise output the align setting
            // 		"center" is actually an illegal attribute, you have to leave it blank
            // TODO: top/bottom justification is not yet supported
            const textAlignstr = textAlign === "center" ? "" : textAlign;

            // for text on the bottom layer, must mirror the text
            let mirrorStr: TextMirror = TextMirror.none;
            if (layer === LayerOrientation.bottom) {
                mirrorStr = TextMirror.mirror;
            }
            newobj.effects.justify = [textAlignstr, mirrorStr] as string[];

            const fp_text = getPropOrInit(currModule, "fp_text", []);
            fp_text.push(newobj);
        } else if (node.type === PcbNodeTypes.line) {
            const {startPosition, endPosition, strokeWidth, layer} = node.bakedRules;

            const newobj: IKicadFpLine = {
                start: {
                    x: toMm(startPosition.x),
                    y: 0 - toMm(startPosition.y),
                },
                end: {
                    x: toMm(endPosition.x),
                    y: 0 - toMm(endPosition.y),
                },
                layer: layer === LayerOrientation.top ? "F.SilkS" : "B.SilkS",
                width: toMm(strokeWidth),
            };

            const fp_line = getPropOrInit(currModule, "fp_line", []);
            fp_line.push(newobj);
        } else if (node.type === PcbNodeTypes.circle) {
            const {size, strokeWidth, position, layer} = node.bakedRules;
            const pos = position; // shortened for convenience

            const newObj: IKicadFpCircle = {
                center: {
                    x: toMm(pos.x),
                    y: 0 - toMm(pos.y),
                },
                end: {
                    x: toMm(pos.x) + toMm(size.x) / 2,
                    y: 0 - toMm(pos.y),
                },
                layer: layer === LayerOrientation.top ? "F.SilkS" : "B.SilkS",
                width: toMm(strokeWidth),
            };
            const fp_circle = getPropOrInit(currModule, "fp_circle", []);
            fp_circle.push(newObj);
        } else if (node.type === PcbNodeTypes.pad) {
            const {size, hole, padShape, layer, position, rotation, solderMaskExpansion, solderPasteMaskExpansion} =
                node.bakedRules;
            const smtTopLayerAr: KicadLayerNames[] = ["F.Cu", "F.Paste", "F.Mask"];
            const smtBottomLayerAr: KicadLayerNames[] = ["B.Cu", "B.Paste", "B.Mask"];
            const throughHoleLayerAr: KicadLayerNames[] = ["*.Cu", "*.Mask"];
            const nonPlatedThroughHoleLayerAr: KicadLayerNames[] = ["*.Cu"];

            let padType: KicadPadType = KicadPadType.thruHole;
            if (hole?.holeType) {
                padType = FluxPadtype2KicadLookup[hole.holeType];
            }

            if (!padType) {
                const message = `ERROR: Pad type ${hole?.holeType} not found`;
                FluxLogger.captureError(new GerberExporterError(message));
                // this should never happen -- as of now, all Flux types are defined
                throw new Error(message);
            }

            let layerAr: KicadLayerNames[] = [];
            if (padType === KicadPadType.thruHole) {
                layerAr = throughHoleLayerAr;
            } else if (padType === KicadPadType.nonPlatedThruHole) {
                layerAr = nonPlatedThroughHoleLayerAr;
            } else if (padType === KicadPadType.surfaceMountDevice) {
                if (layer === LayerOrientation.top) {
                    layerAr = smtTopLayerAr;
                } else {
                    layerAr = smtBottomLayerAr;
                }
            }

            const currRotation = rotation ? rotation.z : 0;
            const radians = currModule && currModule.pos ? currModule.pos.r + currRotation : 0;
            const currPadIndex = currModule && currModule.currPadIndex ? currModule.currPadIndex : 0;

            const padAr = getPropOrInit(currModule, "pad", []);

            let newKicadPad: Partial<IKicadPad> = {};
            // get net information
            const netobj = netCrossReference[node.uid];
            if (netobj) {
                newKicadPad.net = netobj;
            }
            // solder mask and paste expansion layers.
            if (solderMaskExpansion) {
                const exp = getKicadCompatibleExpansionMargin(solderMaskExpansion);
                if (exp) newKicadPad.solder_mask_margin = toMm(exp);
            }
            if (solderPasteMaskExpansion) {
                newKicadPad.solder_paste_margin = toMm(solderPasteMaskExpansion);
            }
            switch (padShape) {
                case "rectangle":
                    newKicadPad = {
                        ...newKicadPad,
                        pad_id: "",
                        pad_type: padType,
                        layers: layerAr,
                        pad_shape: KicadPadShape.rectangle,
                        at: {
                            x: hole?.holePosition ? toMm(position.x) - -toMm(hole.holePosition.x) : toMm(position.x),
                            y: hole?.holePosition
                                ? 0 - toMm(position.y) - toMm(hole.holePosition.y)
                                : 0 - toMm(position.y),
                            unlocked: false,
                            angle: convertRadiansToDegrees(radians),
                        },
                        size: {
                            width: toMm(size.x),
                            height: toMm(size.y),
                        },
                        // TODO: need to implement corner radius
                        //roundrect_rratio: cornerRadius?.topLeft,
                        drill: {
                            // drill hole size in kicad is defined as radius
                            width: hole ? toMm(hole.holeSize.x) : 0,
                            height: hole ? toMm(hole.holeSize.y) : 0,
                            offset: hole?.holePosition
                                ? {
                                      x: -toMm(hole.holePosition.x),
                                      y: toMm(hole.holePosition.y),
                                  }
                                : undefined,
                            oval: hole ? hole.holeSize.x !== hole.holeSize.y : undefined,
                        },
                    };
                    padAr.push(newKicadPad);
                    break;
                case "circular":
                    newKicadPad = {
                        ...newKicadPad,
                        pad_id: "",
                        pad_type: padType,
                        layers: layerAr,
                        // set shape to oval if is oval
                        pad_shape: size.x === size.y ? KicadPadShape.circle : KicadPadShape.oval,
                        at: {
                            x: hole?.holePosition ? toMm(position.x) - -toMm(hole.holePosition.x) : toMm(position.x),
                            y: hole?.holePosition
                                ? 0 - toMm(position.y) - toMm(hole.holePosition.y)
                                : 0 - toMm(position.y),
                            unlocked: false,
                            angle: convertRadiansToDegrees(radians),
                        },
                        size: {
                            width: toMm(size.x),
                            height: toMm(size.y),
                        },
                        drill: {
                            // drill hole size in kicad is defined as radius
                            width: hole ? toMm(hole.holeSize.x) : 0,
                            height: hole ? toMm(hole.holeSize.y) : 0,
                            offset: hole?.holePosition
                                ? {
                                      x: -toMm(hole.holePosition.x),
                                      y: toMm(hole.holePosition.y),
                                  }
                                : undefined,
                            oval: hole ? hole.holeSize.x !== hole.holeSize.y : undefined,
                        },
                    };

                    // for non-plated holes, force pad size to equal hole size
                    //      (this is the behavior in Flux)
                    if (padType === "np_thru_hole") {
                        // force size to equal hole size
                        newKicadPad.size = {
                            width: hole ? toMm(hole.holeSize.x) : 0,
                            height: hole ? toMm(hole.holeSize.y) : 0,
                        };
                    }
                    padAr.push(newKicadPad);
                    break;
                default:
                    const message = `ERROR: Pad shape ${padShape} not found`;
                    FluxLogger.captureError(new GerberExporterError(message));
                    // this should never happen -- as of now, all Flux types are defined
                    throw new Error(message);
            }

            currModule.currPadIndex = currPadIndex + 1;
        } else if (node.type === PcbNodeTypes.via) {
            // since this is a global object, apply any global transformations
            const {size, holeSize, positionRelativeToRootLayout} = node.bakedRules;
            const newObj: IKicadVia = {
                at: {
                    x: toMm(positionRelativeToRootLayout?.x ?? 0) + xAdjust,
                    y: yAdjust - toMm(positionRelativeToRootLayout?.y ?? 0),
                    unlocked: false,
                },
                size: toMm(size.x), // Kicad only supports round vias
                drill: toMm(holeSize.x / 2), // drill hole size in kicad is defined as radius
                layers: ["F.Cu", "B.Cu"],
            };

            // get net information
            const netObj = netCrossReference[node.uid];
            if (netObj) {
                newObj.net = netObj.net_number;
            }

            const viaAr = getPropOrInit(rootObj.kicad_pcb, "via", []);
            viaAr.push(newObj);
        } else if (node.type === PcbNodeTypes.element) {
            const {rotationRelativeToRootLayout, positionRelativeToRootLayout, layer} = node.bakedRules;
            const radians = rotationRelativeToRootLayout?.z ?? 0;

            const newModule = createModuleTemplate();
            newModule.name = node.name;
            newModule.layer = layer === LayerOrientation.top ? "F.Cu" : "B.Cu";
            newModule.currPadIndex = 1; // keep incrementing this every time we see a pad

            let rdeg = convertRadiansToDegrees(radians);
            if (newModule.layer === "B.Cu") {
                // have to re-rotate if this module is on the bottom layer
                rdeg = (360 - rdeg) % 360;
            }
            newModule.pos = {
                x: toMm(positionRelativeToRootLayout?.x ?? 0) + xAdjust,
                y: yAdjust - toMm(positionRelativeToRootLayout?.y ?? 0),
                r: degreesToRadians(rdeg),
                rdeg: rdeg,
            };
            moduleAr.push(newModule);
            // set the index to the new modules
            currModuleIndex = moduleAr.length - 1;
        }
    }
    // get position and rotation of current tree node. Fallback when position or rotation
    // is not presented in bakedRules
    const position = node.bakedRules && "position" in node.bakedRules ? node.bakedRules.position : {x: 0, y: 0};
    const rotation = node.bakedRules && "rotation" in node.bakedRules ? node.bakedRules.rotation : {z: 0};

    if (childAr && childAr.length) {
        for (const childnode of childAr) {
            let currRotation = 0;
            if (rotation && rotation.z) {
                currRotation = rotation.z;
            }
            createKicadObject(
                rootObj,
                childnode,
                {adjX: position.x + adjX, adjY: position.y + adjY, adjRotation: adjRotation + currRotation},
                currModuleIndex,
            );
        }
    }
};

// create the edge cut layer for the board, using the Flux dimensions
const createEdgeCutLayer = (rootObj: IKicadPcbHeader, rootAr: IPcbLayoutNodeWithChildArray[]) => {
    // the root node should be a "layout"
    // you can have more than one root node, but we will just take use the first layout node we see
    for (const rootnode of rootAr) {
        if (rootnode.type === PcbNodeTypes.layout) {
            const layoutNode = rootnode.bakedRules;
            const size = layoutNode?.size || {x: 0, y: 0};
            const position = layoutNode?.position || {x: 0, y: 0};
            const shape = layoutNode?.boardShape as PcbLayoutShape; // ignore values not defined in PcbLayoutShape
            const rotationRadians = layoutNode?.rotation?.z || 0;

            if (size) {
                switch (shape) {
                    case PcbLayoutShape.rectangle:
                        const grLineAr = getPropOrInit(rootObj.kicad_pcb, "gr_line", []) as IKicadGrLine[];
                        const xCoord = toMm(size.x / 2);
                        const yCoord = toMm(size.y / 2);

                        // edge cut layer will be 4 lines defining the outline of this board
                        // the coordinates of the corners are:
                        //      -x,-y to -x,+y
                        //      -x,-y to +x,-y
                        //      +x,-y to +x,+y
                        //      -x,+y to +x,+y

                        // put these coordinates in an array, in groups of 4, to write less code
                        const coordAr = [
                            [-xCoord + xAdjust, -yCoord + yAdjust, -xCoord + xAdjust, yCoord + yAdjust],
                            [-xCoord + xAdjust, -yCoord + yAdjust, xCoord + xAdjust, -yCoord + yAdjust],
                            [xCoord + xAdjust, -yCoord + yAdjust, xCoord + xAdjust, yCoord + yAdjust],
                            [-xCoord + xAdjust, yCoord + yAdjust, xCoord + xAdjust, yCoord + yAdjust],
                        ];
                        for (const coord of coordAr) {
                            const grLineObj: IKicadGrLine = {
                                start: {x: coord[0]!, y: coord[1]!},
                                end: {x: coord[2]!, y: coord[3]!},
                                angle: convertRadiansToDegrees(rotationRadians),
                                layer: "Edge.Cuts",
                                width: 0.05,
                            };
                            grLineAr.push(grLineObj);
                        }
                        break;

                    case PcbLayoutShape.circular:
                        const grCircleAr = getPropOrInit(rootObj.kicad_pcb, "gr_circle", []) as IKicadGrCircle[];
                        const xCenter = toMm(position.x) + xAdjust;
                        const yCenter = toMm(position.y) + yAdjust;
                        const xSize = toMm(size.x / 2); // size here is in diameter, to convert to radius, divide by 2
                        const grCircleObj: IKicadGrCircle = {
                            center: {x: xCenter, y: yCenter},
                            end: {x: xSize + xCenter, y: yCenter},
                            layer: "Edge.Cuts",
                            width: 0.05,
                        };
                        grCircleAr.push(grCircleObj);
                        break;

                    default:
                        break;
                }
            }
            break;
        }
    }
};

function getLayersForExport(layoutNode: PcbBakedNode<PcbNodeTypes.layout>) {
    const stackup = layoutNode.bakedRules?.stackup!;
    const layers = Object.entries(stackup).map(([, layer]) => layer);

    const selection = {
        b_cu: false,
        b_mask: false,
        b_paste: false,
        b_silks: false,

        f_cu: false,
        f_mask: false,
        f_paste: false,
        f_silks: false,
    };

    let numMiddleCopperLayers = 0;
    for (const layer of layers) {
        selection.b_cu = selection.b_cu || (isCopperLayer(layer) && layer.orientation === LayerOrientation.bottom);
        selection.b_mask = selection.b_mask || isSolderMaskLayer(layer, LayerOrientation.bottom);
        selection.b_paste = selection.b_paste || isSolderPasteLayer(layer, LayerOrientation.bottom);
        selection.b_silks = selection.b_silks || isOverlayLayer(layer, LayerOrientation.bottom);

        selection.f_cu = selection.f_cu || (isCopperLayer(layer) && layer.orientation === LayerOrientation.top);
        selection.f_mask = selection.f_mask || isSolderMaskLayer(layer, LayerOrientation.top);
        selection.f_paste = selection.f_paste || isSolderPasteLayer(layer, LayerOrientation.top);
        selection.f_silks = selection.f_silks || isOverlayLayer(layer, LayerOrientation.top);

        if (isMiddleCopperLayer(layer)) numMiddleCopperLayers++;
    }

    const selectedLayers = Object.entries(selection)
        .filter(([, selected]) => selected)
        .map(([layer]) => layer);

    const middleCopperLayers = [...Array(numMiddleCopperLayers).keys()].map((id) => `in${id + 1}_cu`);
    return ["drill", "edge_cuts", ...selectedLayers, ...middleCopperLayers];
}

// takes a Flux object (documentService.snapshot().pcbLayoutNodes, which is all baked nodes)
// as input, and returns a .kicad_pcb file as output
const fluxToKicad = (jsonstr: string) => {
    // set up root object
    const rootObj = createKicadHeader();
    // NOTE: When in jest environment, we might not have childUids configured properly because
    // nodes are mocked and not returned from repository. So let's recompute
    // childUids for testing purposes
    const fluxJson: PcbNodesMap<AnyPcbBakedNode> = areWeTestingWithJest()
        ? resetChildUidsAndRootNode(JSON.parse(jsonstr))
        : JSON.parse(jsonstr);

    // preliminary processing
    createNets(fluxJson, rootObj);
    const rootAr = buildTree(fluxJson);

    // add global objects
    //    In Flux, you can have pads at the "top level" (not associated with any modules)
    //    However, in Kicad, pads need to be associated with a module
    //    We create a global object for the top and bottom layers to hold these floating objects
    const initModuleAr = getPropOrInit(rootObj.kicad_pcb, "module", []);
    const topGlobalModule = createModuleTemplate();
    topGlobalModule.name = "top_global";
    topGlobalModule.layer = "F.Cu";
    topGlobalModule.currPadIndex = 1; // keep incrementing this every time we see a pad
    topGlobalModule.pos = {
        x: xAdjust,
        y: yAdjust,
        r: 0,
        rdeg: 0,
    };
    initModuleAr.push(topGlobalModule);

    const bottomGlobalModule = createModuleTemplate();
    bottomGlobalModule.name = "bottom_global";
    bottomGlobalModule.layer = "B.Cu";
    bottomGlobalModule.currPadIndex = 1; // keep incrementing this every time we see a pad
    bottomGlobalModule.pos = {
        x: xAdjust,
        y: yAdjust,
        r: 0,
        rdeg: 0,
    };
    initModuleAr.push(bottomGlobalModule);

    // First we need to determine the mapping between flux layers

    // start at no offset, and no currModuleIndex (nothing in array yet)
    const currModuleIndex = -1;
    const zeroOffset: adjCoordType = {
        adjX: 0,
        adjY: 0,
        adjRotation: 0,
    };
    for (const currNode of rootAr) {
        createKicadObject(rootObj, currNode, zeroOffset, currModuleIndex);
    }

    // at this point, kicad conversion has happened.
    // post processing
    createEdgeCutLayer(rootObj, rootAr);

    // for modules, convert "pos" to "at"
    const moduleAr = rootObj.kicad_pcb.module || [];
    for (const currModule of moduleAr) {
        const {pos} = currModule;
        if (!pos) {
            // should not ever happen, adding this here to make the compiler happy
            const errmsg = "missing pos property in Kicad module";
            FluxLogger.captureError(new GerberExporterError(errmsg));
            // this should never happen -- as of now, all Flux types are defined
            throw new Error(errmsg);
        }
        currModule.at = {
            x: pos.x,
            y: pos.y,
            unlocked: false,
            angle: pos.rdeg,
        };

        delete currModule.currPadIndex;
        delete currModule.pos;
    }

    // TODO: Work on type of `rootAr` to get rid of this as cast
    // QUESTION: how is this supposed to work with multiple layouts?
    const layoutNode = rootAr.find((node) => node.type === PcbNodeTypes.layout) as PcbBakedNode<PcbNodeTypes.layout>;
    if (!layoutNode) {
        throw new Error("Could not find Layout node");
    }

    return {
        errmsg: "",
        layers: getLayersForExport(layoutNode),
        kicadstr: kicadSerializer(rootObj),
    };
};

export default fluxToKicad;
